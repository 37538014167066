import React from "react";
import Star from "./Star";

const StarRating = ({ rating }) => {
  if (!rating) return null;

  const isDecimal = (num) => num % 1 !== 0;

  const createArray = (size) => {
    const noOfOnStars = Math.trunc(size);
    const starsUsed = isDecimal(size) ? 1 + noOfOnStars : noOfOnStars;
    const arr = [];
    const numOfStars = 5;

    for (var i = 0; i < noOfOnStars; i++) arr[i] = { currentStatus: "on" };
    if (isDecimal(size)) arr[noOfOnStars] = { currentStatus: "half" };
    for (let i = numOfStars - 1; i >= starsUsed; i--)
      arr[i] = { currentStatus: "off" };

    return arr;
  };

  return (
    <table>
      <tbody>
        <tr>
          {createArray(rating).map((star, index) => (
            <td key={index} style={{ border: "0px solid red" }}>
              <Star rating={rating} currentState={star.currentStatus} />
            </td>
          ))}
        </tr>
      </tbody>
    </table>
  );
};

export default StarRating;
