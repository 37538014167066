import _ from "lodash";

const hp = [
  {
    name: "Harry Potter",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "1",
      name: "Gryffindor",
    },
    dateOfBirth: "31-07-1980",
    yearOfBirth: 1980,
    wizard: true,
    ancestry: "half-blood",
    eyeColour: "green",
    hairColour: "black",
    wand: {
      wood: "holly",
      core: "phoenix feather",
      length: 11,
    },
    patronus: "stag",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "Daniel Radcliffe",
    alternate_actors: [],
    alive: true,
    image: "https://hp-api.herokuapp.com/images/harry.jpg",
    _id: "1",
    title: "Harry Potter",
  },
  {
    name: "Hermione Granger",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "1",
      name: "Gryffindor",
    },
    dateOfBirth: "19-09-1979",
    yearOfBirth: 1979,
    wizard: true,
    ancestry: "muggleborn",
    eyeColour: "brown",
    hairColour: "brown",
    wand: {
      wood: "vine",
      core: "dragon heartstring",
      length: null,
    },
    patronus: "otter",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "Emma Watson",
    alternate_actors: [],
    alive: true,
    image: "https://hp-api.herokuapp.com/images/hermione.jpeg",
    _id: "2",
    title: "Hermione Granger",
  },
  {
    name: "Ron Weasley",
    alternate_names: ["Dragomir Despard"],
    species: "human",
    gender: "male",
    house: {
      _id: "1",
      name: "Gryffindor",
    },
    dateOfBirth: "01-03-1980",
    yearOfBirth: 1980,
    wizard: true,
    ancestry: "pure-blood",
    eyeColour: "blue",
    hairColour: "red",
    wand: {
      wood: "willow",
      core: "unicorn tail-hair",
      length: 14,
    },
    patronus: "Jack Russell terrier",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "Rupert Grint",
    alternate_actors: [],
    alive: true,
    image: "https://hp-api.herokuapp.com/images/ron.jpg",
    _id: "3",
    title: "Ron Weasley",
  },
  {
    name: "Draco Malfoy",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "2",
      name: "Slytherin",
    },
    dateOfBirth: "05-06-1980",
    yearOfBirth: 1980,
    wizard: true,
    ancestry: "pure-blood",
    eyeColour: "grey",
    hairColour: "blonde",
    wand: {
      wood: "hawthorn",
      core: "unicorn tail-hair",
      length: 10,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "Tom Felton",
    alternate_actors: [],
    alive: true,
    image: "https://hp-api.herokuapp.com/images/draco.jpg",
    _id: "4",
    title: "Draco Malfoy",
  },
  {
    name: "Minerva McGonagall",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "1",
      name: "Gryffindor",
    },
    dateOfBirth: "04-10-1925",
    yearOfBirth: 1925,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "black",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "tabby cat",
    hogwartsStudent: false,
    hogwartsStaff: true,
    actor: "Dame Maggie Smith",
    alternate_actors: [],
    alive: true,
    image: "https://hp-api.herokuapp.com/images/mcgonagall.jpg",
    _id: "5",
    title: "Minerva McGonagall",
  },
  {
    name: "Cedric Diggory",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "3",
      name: "Hufflepuff",
    },
    dateOfBirth: "",
    yearOfBirth: 1977,
    wizard: true,
    ancestry: "",
    eyeColour: "grey",
    hairColour: "brown",
    wand: {
      wood: "ash",
      core: "unicorn hair",
      length: 12.25,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "Robert Pattinson",
    alternate_actors: [],
    alive: false,
    image: "https://hp-api.herokuapp.com/images/cedric.png",
    _id: "6",
    title: "Cedric Diggory",
  },
  {
    name: "Cho Chang",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "4",
      name: "Ravenclaw",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "brown",
    hairColour: "black",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "swan",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "Katie Leung",
    alternate_actors: [],
    alive: true,
    image: "https://hp-api.herokuapp.com/images/cho.jpg",
    _id: "7",
    title: "Cho Chang",
  },
  {
    name: "Severus Snape",
    alternate_names: ["Half-Blood Prince"],
    species: "human",
    gender: "male",
    house: {
      _id: "2",
      name: "Slytherin",
    },
    dateOfBirth: "09-01-1960",
    yearOfBirth: 1960,
    wizard: true,
    ancestry: "half-blood",
    eyeColour: "black",
    hairColour: "black",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "doe",
    hogwartsStudent: false,
    hogwartsStaff: true,
    actor: "Alan Rickman",
    alternate_actors: [],
    alive: false,
    image: "https://hp-api.herokuapp.com/images/snape.jpg",
    _id: "8",
    title: "Severus Snape",
  },
  {
    name: "Rubeus Hagrid",
    alternate_names: [],
    species: "half-giant",
    gender: "male",
    house: {
      _id: "1",
      name: "Gryffindor",
    },
    dateOfBirth: "06-12-1928",
    yearOfBirth: 1928,
    wizard: true,
    ancestry: "half-blood",
    eyeColour: "black",
    hairColour: "black",
    wand: {
      wood: "oak",
      core: "",
      length: 16,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: true,
    actor: "Robbie Coltrane",
    alternate_actors: [],
    alive: true,
    image: "https://hp-api.herokuapp.com/images/hagrid.png",
    _id: "9",
    title: "Rubeus Hagrid",
  },
  {
    name: "Neville Longbottom",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "1",
      name: "Gryffindor",
    },
    dateOfBirth: "30-07-1980",
    yearOfBirth: 1980,
    wizard: true,
    ancestry: "pure-blood",
    eyeColour: "",
    hairColour: "blonde",
    wand: {
      wood: "cherry",
      core: "unicorn tail-hair",
      length: 13,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "Matthew Lewis",
    alternate_actors: [],
    alive: true,
    image: "https://hp-api.herokuapp.com/images/neville.jpg",
    _id: "10",
    title: "Neville Longbottom",
  },
  {
    name: "Luna Lovegood",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "4",
      name: "Ravenclaw",
    },
    dateOfBirth: "13-02-1981",
    yearOfBirth: 1981,
    wizard: true,
    ancestry: "",
    eyeColour: "grey",
    hairColour: "blonde",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "hare",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "Evanna Lynch",
    alternate_actors: [],
    alive: true,
    image: "https://hp-api.herokuapp.com/images/luna.jpg",
    _id: "11",
    title: "Luna Lovegood",
  },
  {
    name: "Ginny Weasley",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "1",
      name: "Gryffindor",
    },
    dateOfBirth: "11-08-1981",
    yearOfBirth: 1981,
    wizard: true,
    ancestry: "pure-blood",
    eyeColour: "brown",
    hairColour: "red",
    wand: {
      wood: "yew",
      core: "",
      length: null,
    },
    patronus: "horse",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "Bonnie Wright",
    alternate_actors: [],
    alive: true,
    image: "https://hp-api.herokuapp.com/images/ginny.jpg",
    _id: "12",
    title: "Ginny Weasley",
  },
  {
    name: "Sirius Black",
    alternate_names: ["Padfoot", "Snuffles"],
    species: "human",
    gender: "male",
    house: {
      _id: "1",
      name: "Gryffindor",
    },
    dateOfBirth: "03-11-1959",
    yearOfBirth: 1959,
    wizard: true,
    ancestry: "pure-blood",
    eyeColour: "grey",
    hairColour: "black",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "hare",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Gary Oldman",
    alternate_actors: ["James Walters", "Rohan Gotobed"],
    alive: false,
    image: "https://hp-api.herokuapp.com/images/sirius.JPG",
    _id: "13",
    title: "Sirius Black",
  },
  {
    name: "Remus Lupin",
    alternate_names: ["Professor Lupin", "Moony", "Remus John Lupin"],
    species: "werewolf",
    gender: "male",
    house: {
      _id: "1",
      name: "Gryffindor",
    },
    dateOfBirth: "10-03-1960",
    yearOfBirth: 1960,
    wizard: true,
    ancestry: "half-blood",
    eyeColour: "green",
    hairColour: "brown",
    wand: {
      wood: "cypress",
      core: "unicorn tail-hair",
      length: 10.25,
    },
    patronus: "wolf",
    hogwartsStudent: false,
    hogwartsStaff: true,
    actor: "David Thewlis",
    alternate_actors: [],
    alive: false,
    image: "https://hp-api.herokuapp.com/images/lupin.jpg",
    _id: "14",
    title: "Remus Lupin",
  },
  {
    name: "Arthur Weasley",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "1",
      name: "Gryffindor",
    },
    dateOfBirth: "06-02-1950",
    yearOfBirth: 1950,
    wizard: true,
    ancestry: "pure-blood",
    eyeColour: "blue",
    hairColour: "red",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "weasel",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Mark Williams",
    alternate_actors: [],
    alive: true,
    image: "https://hp-api.herokuapp.com/images/arthur.jpg",
    _id: "15",
    title: "Arthur Weasley",
  },
  {
    name: "Bellatrix Lestrange",
    alternate_names: ["Bella"],
    species: "human",
    gender: "female",
    house: {
      _id: "2",
      name: "Slytherin",
    },
    dateOfBirth: "",
    yearOfBirth: 1951,
    wizard: true,
    ancestry: "pure-blood",
    eyeColour: "brown",
    hairColour: "black",
    wand: {
      wood: "walnut",
      core: "dragon heartstring",
      length: 12.75,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Helena Bonham Carter",
    alternate_actors: [],
    alive: false,
    image: "https://hp-api.herokuapp.com/images/bellatrix.jpg",
    _id: "16",
    title: "Bellatrix Lestrange",
  },
  {
    name: "Lord Voldemort",
    alternate_names: ["Tom Marvolo Riddle"],
    species: "human",
    gender: "male",
    house: {
      _id: "2",
      name: "Slytherin",
    },
    dateOfBirth: "31-12-1926",
    yearOfBirth: 1926,
    wizard: true,
    ancestry: "half-blood",
    eyeColour: "red",
    hairColour: "bald",
    wand: {
      wood: "yew",
      core: "phoenix feather",
      length: 13.5,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Ralph Fiennes",
    alternate_actors: [],
    alive: false,
    image: "https://hp-api.herokuapp.com/images/voldemort.jpg",
    _id: "17",
    title: "Lord Voldemort",
  },
  {
    name: "Horace Slughorn",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "2",
      name: "Slytherin",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "pure-blood",
    eyeColour: "green",
    hairColour: "blonde",
    wand: {
      wood: "cedar",
      core: "dragon heartstring",
      length: 10.25,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: true,
    actor: "Jim Broadbent",
    alternate_actors: [],
    alive: true,
    image: "https://hp-api.herokuapp.com/images/slughorn.JPG",
    _id: "18",
    title: "Horace Slughorn",
  },
  {
    name: "Kingsley Shacklebolt",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "pure-blood",
    eyeColour: "brown",
    hairColour: "brown",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "lynx",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "George Harris",
    alternate_actors: [],
    alive: true,
    image: "https://hp-api.herokuapp.com/images/kingsley.jpg",
    _id: "19",
    title: "Kingsley Shacklebolt",
  },
  {
    name: "Dolores Umbridge",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "2",
      name: "Slytherin",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "half-blood",
    eyeColour: "brown",
    hairColour: "grey",
    wand: {
      wood: "birch",
      core: "dragon heartstring",
      length: 8,
    },
    patronus: "persian cat",
    hogwartsStudent: false,
    hogwartsStaff: true,
    actor: "Imelda Staunton",
    alternate_actors: [],
    alive: true,
    image: "https://hp-api.herokuapp.com/images/umbridge.jpg",
    _id: "20",
    title: "Dolores Umbridge",
  },
  {
    name: "Lucius Malfoy",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "2",
      name: "Slytherin",
    },
    dateOfBirth: "",
    yearOfBirth: 1954,
    wizard: true,
    ancestry: "pure-blood",
    eyeColour: "grey",
    hairColour: "blonde",
    wand: {
      wood: "elm",
      core: "dragon heartstring",
      length: 18,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Jason Isaacs",
    alternate_actors: [],
    alive: true,
    image: "https://hp-api.herokuapp.com/images/lucius.jpg",
    _id: "21",
    title: "Lucius Malfoy",
  },
  {
    name: "Vincent Crabbe",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "2",
      name: "Slytherin",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "pure-blood",
    eyeColour: "black",
    hairColour: "black",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "Jamie Waylett",
    alternate_actors: [],
    alive: false,
    image: "https://hp-api.herokuapp.com/images/crabbe.jpg",
    _id: "22",
    title: "Vincent Crabbe",
  },
  {
    name: "Gregory Goyle",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "2",
      name: "Slytherin",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "pure-blood",
    eyeColour: "",
    hairColour: "brown",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "Josh Herdman",
    alternate_actors: [],
    alive: true,
    image: "https://hp-api.herokuapp.com/images/goyle.jpg",
    _id: "23",
    title: "Gregory Goyle",
  },
  {
    name: "Mrs Norris",
    alternate_names: [],
    species: "cat",
    gender: "female",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: false,
    ancestry: "",
    eyeColour: "yellow",
    hairColour: "brown",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: true,
    actor: "Maxime, Alanis and Tommy the cats",
    alternate_actors: [],
    alive: true,
    image: "https://hp-api.herokuapp.com/images/norris.JPG",
    _id: "24",
    title: "Mrs Norris",
  },
  {
    name: "Argus Filch",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: false,
    ancestry: "squib",
    eyeColour: "",
    hairColour: "grey",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: true,
    actor: "David Bradley",
    alternate_actors: [],
    alive: true,
    image: "https://hp-api.herokuapp.com/images/filch.jpg",
    _id: "25",
    title: "Argus Filch",
  },
  {
    name: "Vernon Dursley",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: false,
    ancestry: "muggle",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Richard Griffiths",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "26",
    title: "Vernon Dursley",
  },
  {
    name: "Petunia Dursley",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: false,
    ancestry: "muggle",
    eyeColour: "",
    hairColour: "blonde",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Fiona Shaw",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "27",
    title: "Petunia Dursley",
  },
  {
    name: "Dudley Dursley",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: false,
    ancestry: "muggle",
    eyeColour: "blue",
    hairColour: "blond",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Harry Melling",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "28",
    title: "Dudley Dursley",
  },
  {
    name: "Lily Potter",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "1",
      name: "Gryffindor",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "muggleborn",
    eyeColour: "green",
    hairColour: "",
    wand: {
      wood: "willow",
      core: "",
      length: 10.25,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Geraldine Somerville",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "29",
    title: "Lily Potter",
  },
  {
    name: "James Potter",
    alternate_names: ["Prongs"],
    species: "human",
    gender: "male",
    house: {
      _id: "1",
      name: "Gryffindor",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "black",
    wand: {
      wood: "mahogany",
      core: "",
      length: 11,
    },
    patronus: "stag",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Adrian Rawlins",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "30",
    title: "James Potter",
  },
  {
    name: "Albus Dumbledore",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "1",
      name: "Gryffindor",
    },
    dateOfBirth: "",
    yearOfBirth: 0,
    wizard: true,
    ancestry: "half-blood",
    eyeColour: "blue",
    hairColour: "silver",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: true,
    actor: "Richard Harris",
    alternate_actors: ["Michael Gambon"],
    alive: false,
    image: "",
    _id: "31",
    title: "Albus Dumbledore",
  },
  {
    name: "Madam Pomfrey",
    alternate_names: ["Poppy Pomfrey"],
    species: "human",
    gender: "female",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: true,
    actor: "Gemma Jones",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "32",
    title: "Madam Pomfrey",
  },
  {
    name: "Mrs Figg",
    alternate_names: ["Arabella Doreen Figg"],
    species: "human",
    gender: "female",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: false,
    ancestry: "squib",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Kathryn Hunter",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "33",
    title: "Mrs Figg",
  },
  {
    name: "Marge Dursley",
    alternate_names: [
      "Marjorie Eileen Dursley",
      "Aunt Marge",
      "Auntie Marge",
      "Margie",
    ],
    species: "human",
    gender: "female",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: false,
    ancestry: "muggle",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Pam Ferris",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "34",
    title: "Marge Dursley",
  },
  {
    name: "Yvonne",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: false,
    ancestry: "muggle",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "35",
    title: "Yvonne",
  },
  {
    name: "Piers Polkiss",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: false,
    ancestry: "muggle",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Jason Boyd",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "36",
    title: "Piers Polkiss",
  },
  {
    name: "Dennis",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: false,
    ancestry: "muggle",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Christopher Rithin",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "37",
    title: "Dennis",
  },
  {
    name: "Malcolm",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: false,
    ancestry: "muggle",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Richard Macklin",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "38",
    title: "Malcolm",
  },
  {
    name: "Gordon",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: false,
    ancestry: "muggle",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "39",
    title: "Gordon",
  },
  {
    name: "Miranda Gaushawk",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "40",
    title: "Miranda Gaushawk",
  },
  {
    name: "Bathilda Bagshot",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "41",
    title: "Bathilda Bagshot",
  },
  {
    name: "Adalbert Waffling",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "42",
    title: "Adalbert Waffling",
  },
  {
    name: "Emeric Switch",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "43",
    title: "Emeric Switch",
  },
  {
    name: "Phyllida Spore",
    alternate_names: ["Dame Phyllida Spore"],
    species: "human",
    gender: "female",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "44",
    title: "Phyllida Spore",
  },
  {
    name: "Arsenius Jigger",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "45",
    title: "Arsenius Jigger",
  },
  {
    name: "Newt Scamander",
    alternate_names: ["Newton Artemis Fido Scamander"],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Eddie Redmayne",
    alternate_actors: ["Joshua Shea", "Callum Turner"],
    alive: false,
    image: "",
    _id: "46",
    title: "Newt Scamander",
  },
  {
    name: "Quentin Trimble",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "47",
    title: "Quentin Trimble",
  },
  {
    name: "Tom",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Derek Deadman",
    alternate_actors: ["Jim Tavare"],
    alive: true,
    image: "",
    _id: "48",
    title: "Tom",
  },
  {
    name: "Doris Crockford",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Nina Young",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "49",
    title: "Doris Crockford",
  },
  {
    name: "Quirinus Quirrel",
    alternate_names: ["Professor Quirrel"],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: true,
    actor: "Ian Hart",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "50",
    title: "Quirinus Quirrel",
  },
  {
    name: "Griphook",
    alternate_names: [],
    species: "goblin",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: false,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Verne Troyer",
    alternate_actors: ["Warwick Davis"],
    alive: false,
    image: "",
    _id: "51",
    title: "Griphook",
  },
  {
    name: "Madam Malkin",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "52",
    title: "Madam Malkin",
  },
  {
    name: "Vindictus Viridian",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "53",
    title: "Vindictus Viridian",
  },
  {
    name: "Garrick Ollivander",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "pale, silvery",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "John Hurt",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "54",
    title: "Garrick Ollivander",
  },
  {
    name: "Hedwig",
    alternate_names: [],
    species: "owl",
    gender: "female",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: false,
    ancestry: "",
    eyeColour: "amber",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Gizmo",
    alternate_actors: [
      "Ook",
      "Sprout",
      "Kasper",
      "Swoops",
      "Oh Oh",
      "Elmo",
      "Bandit",
    ],
    alive: false,
    image: "",
    _id: "55",
    title: "Hedwig",
  },
  {
    name: "Molly Weasley",
    alternate_names: ["Molly Prewett", "Mollywabbles"],
    species: "human",
    gender: "female",
    house: {
      _id: "1",
      name: "Gryffindor",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "pure-blood",
    eyeColour: "",
    hairColour: "red",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Julie Walters",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "56",
    title: "Molly Weasley",
  },
  {
    name: "Percy Weasley",
    alternate_names: ["Perce", "Percy Ignatius Weasley", "Weatherby"],
    species: "human",
    gender: "male",
    house: {
      _id: "1",
      name: "Gryffindor",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "pure-blood",
    eyeColour: "",
    hairColour: "red",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "Chris Rankin",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "57",
    title: "Percy Weasley",
  },
  {
    name: "Fred Weasley",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "1",
      name: "Gryffindor",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "pure-blood",
    eyeColour: "",
    hairColour: "red",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "James Phelps",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "58",
    title: "Fred Weasley",
  },
  {
    name: "George Weasley",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "1",
      name: "Gryffindor",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "pure-blood",
    eyeColour: "",
    hairColour: "red",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "Oliver Phelps",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "59",
    title: "George Weasley",
  },
  {
    name: "Lee Jordan",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "1",
      name: "Gryffindor",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "Luke Youngblood",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "60",
    title: "Lee Jordan",
  },
  {
    name: "Bill Weasley",
    alternate_names: ["William Arthur Weasley"],
    species: "human",
    gender: "male",
    house: {
      _id: "1",
      name: "Gryffindor",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "pure-blood",
    eyeColour: "",
    hairColour: "red",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Richard Fish",
    alternate_actors: ["Domhnall Gleeson"],
    alive: true,
    image: "",
    _id: "61",
    title: "Bill Weasley",
  },
  {
    name: "Charlie Weasley",
    alternate_names: ["Charles Weasley"],
    species: "human",
    gender: "male",
    house: {
      _id: "1",
      name: "Gryffindor",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "pure-blood",
    eyeColour: "",
    hairColour: "red",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Alex Crockford",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "62",
    title: "Charlie Weasley",
  },
  {
    name: "Fat Friar",
    alternate_names: [],
    species: "ghost",
    gender: "male",
    house: {
      _id: "3",
      name: "Hufflepuff",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Simon Fisher-Becker",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "63",
    title: "Fat Friar",
  },
  {
    name: "Peeves",
    alternate_names: [],
    species: "poltergeist",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "64",
    title: "Peeves",
  },
  {
    name: "Hannah Abbott",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "3",
      name: "Hufflepuff",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "half-blood",
    eyeColour: "",
    hairColour: "blonde",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "Charlotte Skeoch",
    alternate_actors: ["Louisa Warren"],
    alive: true,
    image: "",
    _id: "65",
    title: "Hannah Abbott",
  },
  {
    name: "Susan Bones",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "3",
      name: "Hufflepuff",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "Eleanor Columbus",
    alternate_actors: ["Emma Jayne-Corboz"],
    alive: true,
    image: "",
    _id: "66",
    title: "Susan Bones",
  },
  {
    name: "Terry Boot",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "4",
      name: "Ravenclaw",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "Kevin Lee Yi",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "67",
    title: "Terry Boot",
  },
  {
    name: "Mandy Brocklehurst",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "4",
      name: "Ravenclaw",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "Christina Petrou",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "68",
    title: "Mandy Brocklehurst",
  },
  {
    name: "Lavender Brown",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "1",
      name: "Gryffindor",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "Kathleen Cauley",
    alternate_actors: ["Jennifer Smith", "Jessie Cave"],
    alive: true,
    image: "",
    _id: "69",
    title: "Lavender Brown",
  },
  {
    name: "Millicent Bulstrode",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "2",
      name: "Slytherin",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "Helen Stuart",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "70",
    title: "Millicent Bulstrode",
  },
  {
    name: "Justin Finch-Fletchley",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "3",
      name: "Hufflepuff",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "muggleborn",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "Edward Randell",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "71",
    title: "Justin Finch-Fletchley",
  },
  {
    name: "Seamus Finnegan",
    alternate_names: ["O'Flaherty"],
    species: "human",
    gender: "male",
    house: {
      _id: "1",
      name: "Gryffindor",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "half-blood",
    eyeColour: "",
    hairColour: "sandy",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "Devon Murray",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "72",
    title: "Seamus Finnegan",
  },
  {
    name: "Morag MacDougal",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "73",
    title: "Morag MacDougal",
  },
  {
    name: "Lily Moon",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "74",
    title: "Lily Moon",
  },
  {
    name: "Theodore Nott",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "2",
      name: "Slytherin",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "pure-blood",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "75",
    title: "Theodore Nott",
  },
  {
    name: "Pansy Parkinson",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "2",
      name: "Slytherin",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "Genevieve Gaunt",
    alternate_actors: ["Lauren Shotton", "Scarlett Byrne"],
    alive: true,
    image: "",
    _id: "76",
    title: "Pansy Parkinson",
  },
  {
    name: "Parvati Patil",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "1",
      name: "Gryffindor",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "Sitara Shah",
    alternate_actors: ["Shefali Chowdhury"],
    alive: true,
    image: "",
    _id: "77",
    title: "Parvati Patil",
  },
  {
    name: "Padma Patil",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "4",
      name: "Ravenclaw",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "Sharon Sandhu",
    alternate_actors: ["Afshan Azad"],
    alive: true,
    image: "",
    _id: "78",
    title: "Padma Patil",
  },
  {
    name: "Sally-Anne Perks",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "79",
    title: "Sally-Anne Perks",
  },
  {
    name: "Lisa Turpin",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "4",
      name: "Ravenclaw",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "80",
    title: "Lisa Turpin",
  },
  {
    name: "Blaise Zabini",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "2",
      name: "Slytherin",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "Louis Cordice",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "81",
    title: "Blaise Zabini",
  },
  {
    name: "Nearly Headless Nick",
    alternate_names: [
      "Sir Nicholas de Mimsy-Porpington",
      "Sir Nicholas",
      "Nick",
    ],
    species: "ghost",
    gender: "male",
    house: {
      _id: "1",
      name: "Gryffindor",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "John Cleese",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "82",
    title: "Nearly Headless Nick",
  },
  {
    name: "Bloody Baron",
    alternate_names: [],
    species: "ghost",
    gender: "male",
    house: {
      _id: "2",
      name: "Slytherin",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Terence Bayler",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "83",
    title: "Bloody Baron",
  },
  {
    name: "Fat Lady",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Elizabeth Spriggs",
    alternate_actors: ["Dawn French"],
    alive: false,
    image: "",
    _id: "84",
    title: "Fat Lady",
  },
  {
    name: "Pomona Sprout",
    alternate_names: ["Professor Sprout"],
    species: "human",
    gender: "female",
    house: {
      _id: "3",
      name: "Hufflepuff",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: true,
    actor: "Miriam Margolyes",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "85",
    title: "Pomona Sprout",
  },
  {
    name: "Cuthbert Binns",
    alternate_names: ["Professor Binns"],
    species: "ghost",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: true,
    actor: "",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "86",
    title: "Cuthbert Binns",
  },
  {
    name: "Emeric the Evil",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "87",
    title: "Emeric the Evil",
  },
  {
    name: "Uric the Oddball",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "88",
    title: "Uric the Oddball",
  },
  {
    name: "Filius Flitwick",
    alternate_names: ["Professor Flitwick"],
    species: "human",
    gender: "male",
    house: {
      _id: "4",
      name: "Ravenclaw",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: true,
    actor: "Warwick Davis",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "89",
    title: "Filius Flitwick",
  },
  {
    name: "Madam Hooch",
    alternate_names: ["Rolanda Hooch"],
    species: "human",
    gender: "female",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "yellow",
    hairColour: "grey",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: true,
    actor: "Zoë Wanamaker",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "90",
    title: "Madam Hooch",
  },
  {
    name: "Oliver Wood",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "Sean Biggerstaff",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "91",
    title: "Oliver Wood",
  },
  {
    name: "Gregory the Smarmy",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "92",
    title: "Gregory the Smarmy",
  },
  {
    name: "Wizard Baruffio",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "93",
    title: "Wizard Baruffio",
  },
  {
    name: "Angelina Johnson",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "1",
      name: "Gryffindor",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "Danielle Tabor",
    alternate_actors: ["Tiana Benjamin"],
    alive: true,
    image: "",
    _id: "94",
    title: "Angelina Johnson",
  },
  {
    name: "Marcus Flint",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "2",
      name: "Slytherin",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "Jamie Yeates",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "95",
    title: "Marcus Flint",
  },
  {
    name: "Alicia Spinet",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "1",
      name: "Gryffindor",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "Leilah Sutherland",
    alternate_actors: ["Rochelle Douglas"],
    alive: true,
    image: "",
    _id: "96",
    title: "Alicia Spinet",
  },
  {
    name: "Katie Bell",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "1",
      name: "Gryffindor",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "Emily Dale",
    alternate_actors: ["Georgina Leonidas"],
    alive: true,
    image: "",
    _id: "97",
    title: "Katie Bell",
  },
  {
    name: "Adrian Pucey",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "2",
      name: "Slytherin",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "Scot Fearn",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "98",
    title: "Adrian Pucey",
  },
  {
    name: "Miles Bletchley",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "2",
      name: "Slytherin",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "David Churchyard",
    alternate_actors: ["Amy Puglia"],
    alive: true,
    image: "",
    _id: "99",
    title: "Miles Bletchley",
  },
  {
    name: "Terrence Higgs",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "2",
      name: "Slytherin",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "Will Theakston",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "100",
    title: "Terrence Higgs",
  },
  {
    name: "Fluffy",
    alternate_names: [],
    species: "three-headed dog",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: false,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "101",
    title: "Fluffy",
  },
  {
    name: "Nicolas Flamel",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Brontis Jodorowsky",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "102",
    title: "Nicolas Flamel",
  },
  {
    name: "Gellert Grindelwald",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "blue",
    hairColour: "blond",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Jamie Campbell Bower",
    alternate_actors: ["Michael Byrne", "Johnny Depp", "Mads Mikkelsen"],
    alive: false,
    image: "",
    _id: "103",
    title: "Gellert Grindelwald",
  },
  {
    name: "Norberta",
    alternate_names: [],
    species: "dragon",
    gender: "female",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: false,
    ancestry: "",
    eyeColour: "orange",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "104",
    title: "Norberta",
  },
  {
    name: "Ronan",
    alternate_names: [],
    species: "centaur",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: false,
    ancestry: "",
    eyeColour: "",
    hairColour: "red",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "105",
    title: "Ronan",
  },
  {
    name: "Bane",
    alternate_names: [],
    species: "centaur",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: false,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Jason Piper",
    alternate_actors: ["Nuno Silva"],
    alive: true,
    image: "",
    _id: "106",
    title: "Bane",
  },
  {
    name: "Firenze",
    alternate_names: ["Professor Firenze"],
    species: "centaur",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: false,
    ancestry: "",
    eyeColour: "blue",
    hairColour: "blond",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: true,
    actor: "Ray Fearon",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "107",
    title: "Firenze",
  },
  {
    name: "Elfrick the Eager",
    alternate_names: [],
    species: "goblin",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: false,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "108",
    title: "Elfrick the Eager",
  },
  {
    name: "Perenelle Flamel",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "109",
    title: "Perenelle Flamel",
  },
  {
    name: "Bertie Bott",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "110",
    title: "Bertie Bott",
  },
  {
    name: "Dobby",
    alternate_names: [],
    species: "house-elf",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: false,
    ancestry: "",
    eyeColour: "green",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Toby Jones",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "111",
    title: "Dobby",
  },
  {
    name: "Mr Mason",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: false,
    ancestry: "muggle",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Jim Norton",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "112",
    title: "Mr Mason",
  },
  {
    name: "Mrs Mason",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: false,
    ancestry: "muggle",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Veronica Clifford",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "113",
    title: "Mrs Mason",
  },
  {
    name: "Perkins",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "white",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "114",
    title: "Perkins",
  },
  {
    name: "Celestina Warbeck",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "1",
      name: "Gryffindor",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "half-blood",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "larch",
      core: "phoenix feather",
      length: 10.5,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "115",
    title: "Celestina Warbeck",
  },
  {
    name: "Gilderoy Lockhart",
    alternate_names: ["Professor Lockhart"],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "half-blood",
    eyeColour: "blue",
    hairColour: "blond",
    wand: {
      wood: "cherry",
      core: "dragon heartstring",
      length: 9,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: true,
    actor: "Kenneth Branagh",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "116",
    title: "Gilderoy Lockhart",
  },
  {
    name: "Mundungus Fletcher",
    alternate_names: ["Dung"],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "ginger",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Andy Linden",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "117",
    title: "Mundungus Fletcher",
  },
  {
    name: "Mr Borgin",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Edward Tudor-Pole",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "118",
    title: "Mr Borgin",
  },
  {
    name: "Mr Granger",
    alternate_names: ["Wendell Wilkins"],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: false,
    ancestry: "muggle",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Tom Knight",
    alternate_actors: ["Ian Kelly"],
    alive: true,
    image: "",
    _id: "119",
    title: "Mr Granger",
  },
  {
    name: "Mrs Granger",
    alternate_names: ["Monica Wilkins"],
    species: "human",
    gender: "female",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: false,
    ancestry: "muggle",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Heather Bleasdale",
    alternate_actors: ["Michelle Fairley"],
    alive: true,
    image: "",
    _id: "120",
    title: "Mrs Granger",
  },
  {
    name: "Dr Filibuster",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "121",
    title: "Dr Filibuster",
  },
  {
    name: "Colin Creevey",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "1",
      name: "Gryffindor",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "muggleborn",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "Hugh Mitchell",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "122",
    title: "Colin Creevey",
  },
  {
    name: "Gladys Gudgeon",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "123",
    title: "Gladys Gudgeon",
  },
  {
    name: "Veronica Smethley",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "124",
    title: "Veronica Smethley",
  },
  {
    name: "Patrick Delaney-Podmore",
    alternate_names: ["Sir Patrick Delaney-Podmore"],
    species: "ghost",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "125",
    title: "Patrick Delaney-Podmore",
  },
  {
    name: "Z. Nettles",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "126",
    title: "Z. Nettles",
  },
  {
    name: "D.J. Prod",
    alternate_names: ["Demetrius J. Prod"],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Chris Wilson",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "127",
    title: "D.J. Prod",
  },
  {
    name: "Elsie Prod",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "128",
    title: "Elsie Prod",
  },
  {
    name: "Moaning Myrtle",
    alternate_names: ["Myrtle Elizabeth Warren"],
    species: "ghost",
    gender: "female",
    house: {
      _id: "4",
      name: "Ravenclaw",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "muggleborn",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Shirley Henderson",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "129",
    title: "Moaning Myrtle",
  },
  {
    name: "Godric Gryffindor",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "130",
    title: "Godric Gryffindor",
  },
  {
    name: "Rowena Ravenclaw",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "131",
    title: "Rowena Ravenclaw",
  },
  {
    name: "Helga Hufflepuff",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "132",
    title: "Helga Hufflepuff",
  },
  {
    name: "Salazar Slytherin",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "133",
    title: "Salazar Slytherin",
  },
  {
    name: "Madame Pince",
    alternate_names: ["Irma Pince"],
    species: "human",
    gender: "female",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: true,
    actor: "Sally Mortemore",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "134",
    title: "Madame Pince",
  },
  {
    name: "Milicent Bullstroude",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "2",
      name: "Slytherin",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "black",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "Helen Stuart",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "135",
    title: "Milicent Bullstroude",
  },
  {
    name: "Ernie Macmillan",
    alternate_names: ["Ernest Macmillan"],
    species: "human",
    gender: "male",
    house: {
      _id: "3",
      name: "Hufflepuff",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "blond",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "boar",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "Louis Doyle",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "136",
    title: "Ernie Macmillan",
  },
  {
    name: "Armando Dippet",
    alternate_names: ["Professor Dippet"],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "white",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Alfred Burke",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "137",
    title: "Armando Dippet",
  },
  {
    name: "Penelope Clearwater",
    alternate_names: ["Penny"],
    species: "human",
    gender: "female",
    house: {
      _id: "4",
      name: "Ravenclaw",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "Gemma Padley",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "138",
    title: "Penelope Clearwater",
  },
  {
    name: "Cornelius Fudge",
    alternate_names: ["Minister Cornelius Oswald Fudge"],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "grey",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Robert Hardy",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "139",
    title: "Cornelius Fudge",
  },
  {
    name: "Aragog",
    alternate_names: [],
    species: "acromantula",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: false,
    ancestry: "",
    eyeColour: "white",
    hairColour: "brown",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Julian Glover",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "140",
    title: "Aragog",
  },
  {
    name: "Mosag",
    alternate_names: [],
    species: "acromantula",
    gender: "female",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: false,
    ancestry: "",
    eyeColour: "black",
    hairColour: "brown",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "141",
    title: "Mosag",
  },
  {
    name: "Stanley Shunpike",
    alternate_names: ["Stan"],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Lee Ingleby",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "142",
    title: "Stanley Shunpike",
  },
  {
    name: "Ernest Prang",
    alternate_names: ["Ernie"],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Jimmy Gardner",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "143",
    title: "Ernest Prang",
  },
  {
    name: "Madam Marsh",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "144",
    title: "Madam Marsh",
  },
  {
    name: "Florean Fortescue",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "145",
    title: "Florean Fortescue",
  },
  {
    name: "Sir Cadogan",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Paul Whitehouse",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "146",
    title: "Sir Cadogan",
  },
  {
    name: "Sybill Trelawney",
    alternate_names: ["Sybill Patricia Trelawney", "Professor Trelawney"],
    species: "human",
    gender: "female",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: true,
    actor: "Emma Thompson",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "147",
    title: "Sybill Trelawney",
  },
  {
    name: "Buckbeak",
    alternate_names: ["Beaky", "Witherwings"],
    species: "hippogriff",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: false,
    ancestry: "",
    eyeColour: "orange",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "148",
    title: "Buckbeak",
  },
  {
    name: "Peter Pettigrew",
    alternate_names: ["Wormtail", "Scabbers", "Wormy"],
    species: "human",
    gender: "male",
    house: {
      _id: "1",
      name: "Gryffindor",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "chestnut",
      core: "dragon heartstring",
      length: 9.25,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Timothy Spall",
    alternate_actors: ["Charles Hughes"],
    alive: false,
    image: "",
    _id: "149",
    title: "Peter Pettigrew",
  },
  {
    name: "Madam Rosmerta",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Julie Christie",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "150",
    title: "Madam Rosmerta",
  },
  {
    name: "Derek",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "151",
    title: "Derek",
  },
  {
    name: "Septima Vector",
    alternate_names: ["Professor Vector"],
    species: "human",
    gender: "female",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: true,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "152",
    title: "Septima Vector",
  },
  {
    name: "Cassius Warrington",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "2",
      name: "Slytherin",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "Ashley Hull",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "153",
    title: "Cassius Warrington",
  },
  {
    name: "Graham Montague",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "2",
      name: "Slytherin",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "154",
    title: "Graham Montague",
  },
  {
    name: "Peregrine Derrick",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "2",
      name: "Slytherin",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "155",
    title: "Peregrine Derrick",
  },
  {
    name: "Lucian Bole",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "2",
      name: "Slytherin",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "156",
    title: "Lucian Bole",
  },
  {
    name: "Walden Macnair",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "2",
      name: "Slytherin",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "black",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Peter Best",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "157",
    title: "Walden Macnair",
  },
  {
    name: "Franc Bryce",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: false,
    ancestry: "muggle",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Eric Sykes",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "158",
    title: "Franc Bryce",
  },
  {
    name: "Dot",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: false,
    ancestry: "muggle",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "159",
    title: "Dot",
  },
  {
    name: "Bertha Jorkins",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "160",
    title: "Bertha Jorkins",
  },
  {
    name: "Barty Crouch",
    alternate_names: ["Bartemius Crouch Senior"],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "pure-blood",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Roger Lloyd-Pack",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "161",
    title: "Barty Crouch",
  },
  {
    name: "Ludo Bagman",
    alternate_names: ["Ludovic Bagman"],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "blue",
    hairColour: "blond",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "162",
    title: "Ludo Bagman",
  },
  {
    name: "Amos Diggory",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "brown",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Jeff Rawle",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "163",
    title: "Amos Diggory",
  },
  {
    name: "Mr Roberts",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: false,
    ancestry: "muggle",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "164",
    title: "Mr Roberts",
  },
  {
    name: "Mr Payne",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: false,
    ancestry: "muggle",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "165",
    title: "Mr Payne",
  },
  {
    name: "Basil",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "166",
    title: "Basil",
  },
  {
    name: "Archie Aymslowe",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "167",
    title: "Archie Aymslowe",
  },
  {
    name: "Cuthbert Mockridge",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "168",
    title: "Cuthbert Mockridge",
  },
  {
    name: "Gilbert Wimple",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "169",
    title: "Gilbert Wimple",
  },
  {
    name: "Arnold Peasegood",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "170",
    title: "Arnold Peasegood",
  },
  {
    name: "Broderick Bode",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "171",
    title: "Broderick Bode",
  },
  {
    name: "Saul Croaker",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "172",
    title: "Saul Croaker",
  },
  {
    name: "Ali Bashir",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "173",
    title: "Ali Bashir",
  },
  {
    name: "Victor Krum",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "dark",
    hairColour: "dark",
    wand: {
      wood: "hornbeam",
      core: "dragon heartstring",
      length: 10.25,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Stanislav Ianevski",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "174",
    title: "Victor Krum",
  },
  {
    name: "Winky",
    alternate_names: [],
    species: "house-elf",
    gender: "female",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: false,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "175",
    title: "Winky",
  },
  {
    name: "Narcissa Malfoy",
    alternate_names: ["Cissy"],
    species: "human",
    gender: "female",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "pure-blood",
    eyeColour: "blue",
    hairColour: "blonde",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Helen McCrory",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "176",
    title: "Narcissa Malfoy",
  },
  {
    name: "Vasily Dimitrov",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "177",
    title: "Vasily Dimitrov",
  },
  {
    name: "Clara Ivanova",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "178",
    title: "Clara Ivanova",
  },
  {
    name: "Lev Zograf",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "179",
    title: "Lev Zograf",
  },
  {
    name: "Alexei Levski",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "180",
    title: "Alexei Levski",
  },
  {
    name: "Pyotr Vulchanov",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "181",
    title: "Pyotr Vulchanov",
  },
  {
    name: "Ivan Volkov",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "182",
    title: "Ivan Volkov",
  },
  {
    name: "Connolly",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "183",
    title: "Connolly",
  },
  {
    name: "Barry Ryan",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "184",
    title: "Barry Ryan",
  },
  {
    name: "Troy",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "185",
    title: "Troy",
  },
  {
    name: "Mullet",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "186",
    title: "Mullet",
  },
  {
    name: "Moran",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "187",
    title: "Moran",
  },
  {
    name: "Quigley",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "188",
    title: "Quigley",
  },
  {
    name: "Aidan Lynch",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "189",
    title: "Aidan Lynch",
  },
  {
    name: "Hassan Mostafa",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "190",
    title: "Hassan Mostafa",
  },
  {
    name: "Dennis Creevey",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "muggleborn",
    eyeColour: "",
    hairColour: "brown",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "191",
    title: "Dennis Creevey",
  },
  {
    name: "Aurora Sinistra",
    alternate_names: ["Professor Sinistra"],
    species: "human",
    gender: "female",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: true,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "192",
    title: "Aurora Sinistra",
  },
  {
    name: "Stewart Ackerley",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "4",
      name: "Ravenclaw",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "193",
    title: "Stewart Ackerley",
  },
  {
    name: "Malcolm Baddock",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "2",
      name: "Slytherin",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "194",
    title: "Malcolm Baddock",
  },
  {
    name: "Eleanor Branstone",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "3",
      name: "Hufflepuff",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "195",
    title: "Eleanor Branstone",
  },
  {
    name: "Owen Cauldwell",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "3",
      name: "Hufflepuff",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "196",
    title: "Owen Cauldwell",
  },
  {
    name: "Emma Dobbs",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "197",
    title: "Emma Dobbs",
  },
  {
    name: "Laura Madley",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "3",
      name: "Hufflepuff",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "198",
    title: "Laura Madley",
  },
  {
    name: "Natalie McDonald",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "1",
      name: "Gryffindor",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "199",
    title: "Natalie McDonald",
  },
  {
    name: "Graham Pritchard",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "2",
      name: "Slytherin",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "200",
    title: "Graham Pritchard",
  },
  {
    name: "Orla Quirke",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "4",
      name: "Ravenclaw",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "201",
    title: "Orla Quirke",
  },
  {
    name: "Kevin Whitby",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "3",
      name: "Hufflepuff",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "202",
    title: "Kevin Whitby",
  },
  {
    name: "Eloise Midgen",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "1",
      name: "Gryffindor",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "Samantha Clinch",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "203",
    title: "Eloise Midgen",
  },
  {
    name: "Alastor Moody",
    alternate_names: ["Professor Moody", "Mad-Eye"],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "pure-blood",
    eyeColour: "dark",
    hairColour: "grey",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: true,
    actor: "Brendan Gleeson",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "204",
    title: "Alastor Moody",
  },
  {
    name: "Madame Maxime",
    alternate_names: ["Olympe Maxime"],
    species: "half-giant",
    gender: "female",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Frances de la Tour",
    alternate_actors: ["Ian Whyte"],
    alive: true,
    image: "",
    _id: "205",
    title: "Madame Maxime",
  },
  {
    name: "Igor Karkaroff",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "blue",
    hairColour: "silver",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Predrag Bjelac",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "206",
    title: "Igor Karkaroff",
  },
  {
    name: "Poliakoff",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "207",
    title: "Poliakoff",
  },
  {
    name: "Fawcett",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "4",
      name: "Ravenclaw",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "Alex Argenti",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "208",
    title: "Fawcett",
  },
  {
    name: "Summers",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "3",
      name: "Hufflepuff",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "209",
    title: "Summers",
  },
  {
    name: "Fleur Delacour",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "blue",
    hairColour: "blonde",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Clémence Poésy",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "210",
    title: "Fleur Delacour",
  },
  {
    name: "Gregorovic",
    alternate_names: ["Mykew Gregorovitch"],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "white",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Rade Šerbedžija",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "211",
    title: "Gregorovic",
  },
  {
    name: "Rita Skeeter",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "blonde",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Miranda Richardson",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "212",
    title: "Rita Skeeter",
  },
  {
    name: "Stebbins",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "4",
      name: "Ravenclaw",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "213",
    title: "Stebbins",
  },
  {
    name: "Wilhelmina Grubbly-Plank",
    alternate_names: ["Professor Grubbly-Plank"],
    species: "human",
    gender: "female",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "grey",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: true,
    actor: "Apple Brook",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "214",
    title: "Wilhelmina Grubbly-Plank",
  },
  {
    name: "Bozo",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Peter O'Farrell",
    alternate_actors: ["Robert Wilfort"],
    alive: true,
    image: "",
    _id: "215",
    title: "Bozo",
  },
  {
    name: "Evan Rosier",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "2",
      name: "Slytherin",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "216",
    title: "Evan Rosier",
  },
  {
    name: "Wilkes",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "2",
      name: "Slytherin",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "217",
    title: "Wilkes",
  },
  {
    name: "Avery",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "2",
      name: "Slytherin",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Richard Rosson",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "218",
    title: "Avery",
  },
  {
    name: "Antonin Dolohov",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "dark",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Arben Bajraktaraj",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "219",
    title: "Antonin Dolohov",
  },
  {
    name: "Travers",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "grey",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Tav MacDougall",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "220",
    title: "Travers",
  },
  {
    name: "Marlene McKinnon",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "221",
    title: "Marlene McKinnon",
  },
  {
    name: "Mulciber",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "2",
      name: "Slytherin",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "222",
    title: "Mulciber",
  },
  {
    name: "Augustus Rookwood",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Richard Trinder",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "223",
    title: "Augustus Rookwood",
  },
  {
    name: "Frank Longbottom",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "pure-blood",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "James Payton",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "224",
    title: "Frank Longbottom",
  },
  {
    name: "Alice Longbottom",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "pure-blood",
    eyeColour: "",
    hairColour: "white",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Lisa Wood ",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "225",
    title: "Alice Longbottom",
  },
  {
    name: "Violet",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "226",
    title: "Violet",
  },
  {
    name: "Apollyon Pringle",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "227",
    title: "Apollyon Pringle",
  },
  {
    name: "Ogg",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "228",
    title: "Ogg",
  },
  {
    name: "Mafalda Hopkirk",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "hazel",
    hairColour: "grey",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Jessica Hynes",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "229",
    title: "Mafalda Hopkirk",
  },
  {
    name: "Nymphadora Tonks",
    alternate_names: ["Dora", "Nymphadora Lupin"],
    species: "human",
    gender: "female",
    house: {
      _id: "3",
      name: "Hufflepuff",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "brown",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Natalia Tena",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "230",
    title: "Nymphadora Tonks",
  },
  {
    name: "Elphias Doge",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "pure-blood",
    eyeColour: "blue",
    hairColour: "silver",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Peter Cartwright",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "231",
    title: "Elphias Doge",
  },
  {
    name: "Dedalus Diggle",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "David Brett",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "232",
    title: "Dedalus Diggle",
  },
  {
    name: "Sturgis Podmore",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "blond",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "233",
    title: "Sturgis Podmore",
  },
  {
    name: "Hestia Jones",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "black",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "234",
    title: "Hestia Jones",
  },
  {
    name: "Ragnok",
    alternate_names: [],
    species: "goblin",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: false,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "235",
    title: "Ragnok",
  },
  {
    name: "Alphard Black",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "2",
      name: "Slytherin",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "pure-blood",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "236",
    title: "Alphard Black",
  },
  {
    name: "Regulus Black",
    alternate_names: ["Regulus Arcturus Black"],
    species: "human",
    gender: "male",
    house: {
      _id: "2",
      name: "Slytherin",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "pure-blood",
    eyeColour: "",
    hairColour: "black",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Tom Moorcroft",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "237",
    title: "Regulus Black",
  },
  {
    name: "Phineas Nigelus Black",
    alternate_names: ["Professor Phineas Nigellus Black"],
    species: "human",
    gender: "male",
    house: {
      _id: "2",
      name: "Slytherin",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "pure-blood",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "John Atterbury",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "238",
    title: "Phineas Nigelus Black",
  },
  {
    name: "Araminta Meliflua Black",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "2",
      name: "Slytherin",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "239",
    title: "Araminta Meliflua Black",
  },
  {
    name: "Elladora Black",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "pure-blood",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "240",
    title: "Elladora Black",
  },
  {
    name: "Andromeda Tonks",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "2",
      name: "Slytherin",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "pure-blood",
    eyeColour: "",
    hairColour: "brown",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "241",
    title: "Andromeda Tonks",
  },
  {
    name: "Ted Tonks",
    alternate_names: ["Edward"],
    species: "human",
    gender: "female",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "muggleborn",
    eyeColour: "",
    hairColour: "blond",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "242",
    title: "Ted Tonks",
  },
  {
    name: "Rodolphus Lestrange",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "2",
      name: "Slytherin",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "pure-blood",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "243",
    title: "Rodolphus Lestrange",
  },
  {
    name: "Rabastan Lestrange",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "2",
      name: "Slytherin",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "pure-blood",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "244",
    title: "Rabastan Lestrange",
  },
  {
    name: "Eric Munch",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "245",
    title: "Eric Munch",
  },
  {
    name: "Bob",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Nicholas Blane",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "246",
    title: "Bob",
  },
  {
    name: "Bengie Fenwick",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "247",
    title: "Bengie Fenwick",
  },
  {
    name: "Edgar Bones",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Cliff Lanning",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "248",
    title: "Edgar Bones",
  },
  {
    name: "Amelia Bones",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "grey",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Sian Thomas",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "249",
    title: "Amelia Bones",
  },
  {
    name: "Caradoc Dearborn",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "250",
    title: "Caradoc Dearborn",
  },
  {
    name: "Gideon Prewett",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "pure-blood",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "251",
    title: "Gideon Prewett",
  },
  {
    name: "Fabian Prewett",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "pure-blood",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "252",
    title: "Fabian Prewett",
  },
  {
    name: "Aberforth Dumbledore",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "1",
      name: "Gryffindor",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "half-blood",
    eyeColour: "blue",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "goat",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Jim McManus",
    alternate_actors: ["Ciarán Hinds"],
    alive: true,
    image: "",
    _id: "253",
    title: "Aberforth Dumbledore",
  },
  {
    name: "Dorcas Meadowes",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "254",
    title: "Dorcas Meadowes",
  },
  {
    name: "Algie Longbottom",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "255",
    title: "Algie Longbottom",
  },
  {
    name: "Anthony Goldstein",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "4",
      name: "Ravenclaw",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "256",
    title: "Anthony Goldstein",
  },
  {
    name: "Stubby Boardman",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "257",
    title: "Stubby Boardman",
  },
  {
    name: "Doris Purkiss",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "258",
    title: "Doris Purkiss",
  },
  {
    name: "Euan Abercrombie",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "1",
      name: "Gryffindor",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "259",
    title: "Euan Abercrombie",
  },
  {
    name: "Rose Zeller",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "3",
      name: "Hufflepuff",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "260",
    title: "Rose Zeller",
  },
  {
    name: "Patricia Stimpson",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "261",
    title: "Patricia Stimpson",
  },
  {
    name: "Kenneth Towler",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "1",
      name: "Gryffindor",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "262",
    title: "Kenneth Towler",
  },
  {
    name: "Vicky Frobisher",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "1",
      name: "Gryffindor",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "263",
    title: "Vicky Frobisher",
  },
  {
    name: "Geoffrey Hooper",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "1",
      name: "Gryffindor",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "264",
    title: "Geoffrey Hooper",
  },
  {
    name: "Cassandra Trelawney",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "265",
    title: "Cassandra Trelawney",
  },
  {
    name: "Michael Corner",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "4",
      name: "Ravenclaw",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "Ryan Nelson",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "266",
    title: "Michael Corner",
  },
  {
    name: "Zacharias Smith",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "4",
      name: "Ravenclaw",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "blond",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "Nick Shirm",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "267",
    title: "Zacharias Smith",
  },
  {
    name: "Barnabas the Barmy",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "268",
    title: "Barnabas the Barmy",
  },
  {
    name: "Karkus",
    alternate_names: [],
    species: "giant",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: false,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "269",
    title: "Karkus",
  },
  {
    name: "Golgomath",
    alternate_names: [],
    species: "giant",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: false,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "270",
    title: "Golgomath",
  },
  {
    name: "Fridwulfa",
    alternate_names: [],
    species: "giant",
    gender: "female",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: false,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "271",
    title: "Fridwulfa",
  },
  {
    name: "Andrew Kirke",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "1",
      name: "Gryffindor",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "272",
    title: "Andrew Kirke",
  },
  {
    name: "Jack Sloper",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "1",
      name: "Gryffindor",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "273",
    title: "Jack Sloper",
  },
  {
    name: "Everard",
    alternate_names: ["Professor Everard"],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "black",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Sam Beazley",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "274",
    title: "Everard",
  },
  {
    name: "Dilys Derwent",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "silver",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "275",
    title: "Dilys Derwent",
  },
  {
    name: "Hippocrates Smethwyck",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "276",
    title: "Hippocrates Smethwyck",
  },
  {
    name: "Augustus Pye",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "277",
    title: "Augustus Pye",
  },
  {
    name: "Urquhart Rackharrow",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "278",
    title: "Urquhart Rackharrow",
  },
  {
    name: "Willy Widdershins",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "279",
    title: "Willy Widdershins",
  },
  {
    name: "Agnes",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "280",
    title: "Agnes",
  },
  {
    name: "Miriam Strout",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "281",
    title: "Miriam Strout",
  },
  {
    name: "Madam Puddifoot",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "black",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "282",
    title: "Madam Puddifoot",
  },
  {
    name: "Summerby",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "3",
      name: "Hufflepuff",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "283",
    title: "Summerby",
  },
  {
    name: "Marietta Edgecombe",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "4",
      name: "Ravenclaw",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "blonde",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "284",
    title: "Marietta Edgecombe",
  },
  {
    name: "Madam Edgecombe",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "285",
    title: "Madam Edgecombe",
  },
  {
    name: "John Dawlish",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "grey",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Richard Leaf",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "286",
    title: "John Dawlish",
  },
  {
    name: "Bradley",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "4",
      name: "Ravenclaw",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "287",
    title: "Bradley",
  },
  {
    name: "Roger Davies",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "4",
      name: "Ravenclaw",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "Henry Lloyd-Hughes",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "288",
    title: "Roger Davies",
  },
  {
    name: "Grawp",
    alternate_names: [],
    species: "giant",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: false,
    ancestry: "",
    eyeColour: "green",
    hairColour: "brown",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Tony Maudsley",
    alternate_actors: ["William Todd-Jones"],
    alive: true,
    image: "",
    _id: "289",
    title: "Grawp",
  },
  {
    name: "Griselda Marchbanks",
    alternate_names: ["Madam Professor Griselda Marchbanks"],
    species: "human",
    gender: "female",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "290",
    title: "Griselda Marchbanks",
  },
  {
    name: "Eddie Carmichael",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "4",
      name: "Ravenclaw",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "291",
    title: "Eddie Carmichael",
  },
  {
    name: "Daphne Greengrass",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "2",
      name: "Slytherin",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "pure-blood",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "292",
    title: "Daphne Greengrass",
  },
  {
    name: "Tofty",
    alternate_names: ["Professor Tofty"],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "brown",
    hairColour: "grey",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "293",
    title: "Tofty",
  },
  {
    name: "Tiberius Ogden",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "294",
    title: "Tiberius Ogden",
  },
  {
    name: "Pierre Bonaccord",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "295",
    title: "Pierre Bonaccord",
  },
  {
    name: "Stubby Boardman",
    alternate_names: ["Sirius Black"],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "296",
    title: "Stubby Boardman",
  },
  {
    name: "Magorian",
    alternate_names: [],
    species: "centaur",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "black",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Michael Wildman",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "297",
    title: "Magorian",
  },
  {
    name: "Emmeline Vance",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Brigitte Millar",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "298",
    title: "Emmeline Vance",
  },
  {
    name: "Herbert Chorley",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: false,
    ancestry: "muggle",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "299",
    title: "Herbert Chorley",
  },
  {
    name: "Rufus Scrimgeour",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "yellowish",
    hairColour: "tawny",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Bill Nighy",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "300",
    title: "Rufus Scrimgeour",
  },
  {
    name: "Corban Yaxley",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "blue",
    hairColour: "blond",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Peter Mullan",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "301",
    title: "Corban Yaxley",
  },
  {
    name: "Fenrir Greyback",
    alternate_names: [],
    species: "werewolf",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "grey",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Dave Legeno",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "302",
    title: "Fenrir Greyback",
  },
  {
    name: "Dirk Cresswell",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "muggleborn",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "303",
    title: "Dirk Cresswell",
  },
  {
    name: "Barnabas Cuffe",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Roger C. Bailey",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "304",
    title: "Barnabas Cuffe",
  },
  {
    name: "Ambrosius Flume",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "bald",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "305",
    title: "Ambrosius Flume",
  },
  {
    name: "Gwenog Jones",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "brown",
    hairColour: "brown",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "306",
    title: "Gwenog Jones",
  },
  {
    name: "Arkie Philpott",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "307",
    title: "Arkie Philpott",
  },
  {
    name: "Verity",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "blonde",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "308",
    title: "Verity",
  },
  {
    name: "Romilda Vane",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "1",
      name: "Gryffindor",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "dark",
    hairColour: "black",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "Anna Shaffer",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "309",
    title: "Romilda Vane",
  },
  {
    name: "Cormac McLaggen",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "1",
      name: "Gryffindor",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "Freddie Stroma",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "310",
    title: "Cormac McLaggen",
  },
  {
    name: "Marcus Belby",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "4",
      name: "Ravenclaw",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "Robert Knox",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "311",
    title: "Marcus Belby",
  },
  {
    name: "Damocles Belby",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "312",
    title: "Damocles Belby",
  },
  {
    name: "Tiberius",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "313",
    title: "Tiberius",
  },
  {
    name: "Bertie Higgs",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "314",
    title: "Bertie Higgs",
  },
  {
    name: "Proudfoot",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "315",
    title: "Proudfoot",
  },
  {
    name: "Savage",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "316",
    title: "Savage",
  },
  {
    name: "Humphrey Belcher",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "317",
    title: "Humphrey Belcher",
  },
  {
    name: "Bob Ogden",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "318",
    title: "Bob Ogden",
  },
  {
    name: "Morfin Gaunt",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "pure-blood",
    eyeColour: "dark",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "319",
    title: "Morfin Gaunt",
  },
  {
    name: "Marvolo Gaunt",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "pure-blood",
    eyeColour: "brown",
    hairColour: "grey",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "320",
    title: "Marvolo Gaunt",
  },
  {
    name: "Merope Gaunt",
    alternate_names: ["Merope Riddle"],
    species: "human",
    gender: "female",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "pure-blood",
    eyeColour: "",
    hairColour: "dull",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "321",
    title: "Merope Gaunt",
  },
  {
    name: "Cecilia",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: false,
    ancestry: "muggle",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "322",
    title: "Cecilia",
  },
  {
    name: "Tom Riddle",
    alternate_names: ["Tom Riddle Senior"],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: false,
    ancestry: "muggle",
    eyeColour: "",
    hairColour: "dark",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "323",
    title: "Tom Riddle",
  },
  {
    name: "Demelza Robins",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "1",
      name: "Gryffindor",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "Katy Huxley-Golden",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "324",
    title: "Demelza Robins",
  },
  {
    name: "Jimmy Peakes",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "1",
      name: "Gryffindor",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "325",
    title: "Jimmy Peakes",
  },
  {
    name: "Ritchie Coote",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "1",
      name: "Gryffindor",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "Ashley Virgil",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "326",
    title: "Ritchie Coote",
  },
  {
    name: "Melinda Bobbin",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "327",
    title: "Melinda Bobbin",
  },
  {
    name: "Leanne",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "3",
      name: "Hufflepuff",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "Isabella Laughland",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "328",
    title: "Leanne",
  },
  {
    name: "Caractacus Burke",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "329",
    title: "Caractacus Burke",
  },
  {
    name: "Billy Stubbs",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: false,
    ancestry: "muggle",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "330",
    title: "Billy Stubbs",
  },
  {
    name: "Amy Benson",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: false,
    ancestry: "muggle",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "331",
    title: "Amy Benson",
  },
  {
    name: "Dennis Bishop",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: false,
    ancestry: "muggle",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "332",
    title: "Dennis Bishop",
  },
  {
    name: "Mrs Cole",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: false,
    ancestry: "muggle",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Amelda Brown",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "333",
    title: "Mrs Cole",
  },
  {
    name: "Eric Whalley",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: false,
    ancestry: "muggle",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "334",
    title: "Eric Whalley",
  },
  {
    name: "Vaisey",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "2",
      name: "Slytherin",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "dark",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "335",
    title: "Vaisey",
  },
  {
    name: "Harper",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "2",
      name: "Slytherin",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "336",
    title: "Harper",
  },
  {
    name: "Urquhart",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "2",
      name: "Slytherin",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "337",
    title: "Urquhart",
  },
  {
    name: "Eldred Worple",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Paul Ritter",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "338",
    title: "Eldred Worple",
  },
  {
    name: "Sangwini",
    alternate_names: [],
    species: "vampire",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "dark",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Charlie Bennison",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "339",
    title: "Sangwini",
  },
  {
    name: "Gawain Robards",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "340",
    title: "Gawain Robards",
  },
  {
    name: "Fergus",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "341",
    title: "Fergus",
  },
  {
    name: "Wilkie Twycross",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "342",
    title: "Wilkie Twycross",
  },
  {
    name: "Cadwallader",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "3",
      name: "Hufflepuff",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "343",
    title: "Cadwallader",
  },
  {
    name: "Galatea Merrythought",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: true,
    actor: "",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "344",
    title: "Galatea Merrythought",
  },
  {
    name: "Hepzibah Smith",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "345",
    title: "Hepzibah Smith",
  },
  {
    name: "Hokey",
    alternate_names: [],
    species: "house-elf",
    gender: "female",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: false,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "346",
    title: "Hokey",
  },
  {
    name: "Octavius Pepper",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: false,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "347",
    title: "Octavius Pepper",
  },
  {
    name: "Bertram Aubrey",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: false,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "348",
    title: "Bertram Aubrey",
  },
  {
    name: "Eileen Prince",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: false,
    ancestry: "pure-blood",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "349",
    title: "Eileen Prince",
  },
  {
    name: "Amycus Carrow",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "2",
      name: "Slytherin",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Ralph Ineson",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "350",
    title: "Amycus Carrow",
  },
  {
    name: "Alecto Carrow",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "2",
      name: "Slytherin",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Suzie Toase",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "351",
    title: "Alecto Carrow",
  },
  {
    name: "Gibbon",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "352",
    title: "Gibbon",
  },
  {
    name: "Tobias Snape",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: false,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "353",
    title: "Tobias Snape",
  },
  {
    name: "Pius Thicknesse",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "black",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Guy Henry",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "354",
    title: "Pius Thicknesse",
  },
  {
    name: "Charity Burbage",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: true,
    actor: "Carolyn Pickles",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "355",
    title: "Charity Burbage",
  },
  {
    name: "Percival Dumbledore",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "356",
    title: "Percival Dumbledore",
  },
  {
    name: "Kendra Dumbledore",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "muggleborn",
    eyeColour: "dark",
    hairColour: "black",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "357",
    title: "Kendra Dumbledore",
  },
  {
    name: "Ariana Dumbledore",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "half-blood",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Hebe Beardsall",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "358",
    title: "Ariana Dumbledore",
  },
  {
    name: "Betty Braithwaite",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "359",
    title: "Betty Braithwaite",
  },
  {
    name: "Apolline Delacour",
    alternate_names: [],
    species: "half-human",
    gender: "female",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "half-veela",
    eyeColour: "",
    hairColour: "blonde",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "360",
    title: "Apolline Delacour",
  },
  {
    name: "Gabrielle Delacour",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "quarter-veela",
    eyeColour: "",
    hairColour: "blonde",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Angelica Mandy",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "361",
    title: "Gabrielle Delacour",
  },
  {
    name: "Monsieur Delacour",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "black",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "362",
    title: "Monsieur Delacour",
  },
  {
    name: "Dragomir Gorgovitch",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "363",
    title: "Dragomir Gorgovitch",
  },
  {
    name: "Xenophilius Lovegood",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "4",
      name: "Ravenclaw",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "white",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Rhys Ifans",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "364",
    title: "Xenophilius Lovegood",
  },
  {
    name: "Muriel",
    alternate_names: ["Aunt Muriel"],
    species: "human",
    gender: "female",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "grey",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Matyelok Gibbs",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "365",
    title: "Muriel",
  },
  {
    name: "Bilius",
    alternate_names: ["Uncle Bilius"],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "366",
    title: "Bilius",
  },
  {
    name: "Thorfinn Rowle",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "blond",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Rod Hunt",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "367",
    title: "Thorfinn Rowle",
  },
  {
    name: "Reg Cattermole",
    alternate_names: ["Reginald Cattermole"],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Steffan Rhodri",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "368",
    title: "Reg Cattermole",
  },
  {
    name: "Albert Runcorn",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "black",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "David O'Hara",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "369",
    title: "Albert Runcorn",
  },
  {
    name: "Arkie Alderton",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "370",
    title: "Arkie Alderton",
  },
  {
    name: "Mary Cattermole",
    alternate_names: ["Mary Elizabeth Cattermole"],
    species: "human",
    gender: "female",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "muggleborn",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "cherry",
      core: "unicorn hair",
      length: 8.75,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Kate Fleetwood",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "371",
    title: "Mary Cattermole",
  },
  {
    name: "Maisie Cattermole",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "372",
    title: "Maisie Cattermole",
  },
  {
    name: "Ellie Cattermole",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "373",
    title: "Ellie Cattermole",
  },
  {
    name: "Alfred Cattermole",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "374",
    title: "Alfred Cattermole",
  },
  {
    name: "Gornuk",
    alternate_names: [],
    species: "goblin",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: false,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "375",
    title: "Gornuk",
  },
  {
    name: "Bowman Wright",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "376",
    title: "Bowman Wright",
  },
  {
    name: "Ivor Dillonsby",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "377",
    title: "Ivor Dillonsby",
  },
  {
    name: "Enid Smeek",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "378",
    title: "Enid Smeek",
  },
  {
    name: "Egbert the Egregious",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "379",
    title: "Egbert the Egregious",
  },
  {
    name: "Godelot",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "380",
    title: "Godelot",
  },
  {
    name: "Hereward",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "381",
    title: "Hereward",
  },
  {
    name: "Loxias",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "382",
    title: "Loxias",
  },
  {
    name: "Barnabas Deverill",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "383",
    title: "Barnabas Deverill",
  },
  {
    name: "Arcus",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "384",
    title: "Arcus",
  },
  {
    name: "Livius",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "385",
    title: "Livius",
  },
  {
    name: "Antioch Peverell",
    alternate_names: ["First Brother"],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "pure-blood",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "386",
    title: "Antioch Peverell",
  },
  {
    name: "Cadmus Peverell",
    alternate_names: ["Second Brother"],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "pure-blood",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "387",
    title: "Cadmus Peverell",
  },
  {
    name: "Ignotus Peverell",
    alternate_names: ["Third Brother"],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "pure-blood",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "388",
    title: "Ignotus Peverell",
  },
  {
    name: "Selwyn",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "pure-blood",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "389",
    title: "Selwyn",
  },
  {
    name: "Scabior",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "2",
      name: "Slytherin",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Nick Moran",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "390",
    title: "Scabior",
  },
  {
    name: "Ragnuk the First",
    alternate_names: ["King Ragnuk the First"],
    species: "goblin",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: false,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "391",
    title: "Ragnuk the First",
  },
  {
    name: "Ted Lupin",
    alternate_names: ["Edward Remus Teddy Lupin"],
    species: "human",
    gender: "male",
    house: {
      _id: "3",
      name: "Hufflepuff",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "half-blood",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Luke Newberry",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "392",
    title: "Ted Lupin",
  },
  {
    name: "Marius",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "393",
    title: "Marius",
  },
  {
    name: "Bogrod",
    alternate_names: [],
    species: "goblin",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: false,
    ancestry: "",
    eyeColour: "black",
    hairColour: "white",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Jon Key",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "394",
    title: "Bogrod",
  },
  {
    name: "The Grey Lady",
    alternate_names: ["Helena Ravenclaw"],
    species: "ghost",
    gender: "female",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Nina Young",
    alternate_actors: ["Kelly Macdonald"],
    alive: false,
    image: "",
    _id: "395",
    title: "The Grey Lady",
  },
  {
    name: "Dexter Fortescue",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: false,
    image: "",
    _id: "396",
    title: "Dexter Fortescue",
  },
  {
    name: "Lily Potter",
    alternate_names: ["Lily Luna Potter"],
    species: "human",
    gender: "female",
    house: {
      _id: "1",
      name: "Gryffindor",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "half-blood",
    eyeColour: "",
    hairColour: "red",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: false,
    hogwartsStaff: false,
    actor: "Daphne de Beistegui",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "397",
    title: "Lily Potter",
  },
  {
    name: "James Potter",
    alternate_names: ["James Sirius Potter"],
    species: "human",
    gender: "male",
    house: {
      _id: "1",
      name: "Gryffindor",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "half-blood",
    eyeColour: "",
    hairColour: "",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "Will Dunn",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "398",
    title: "James Potter",
  },
  {
    name: "Albus Severus Potter",
    alternate_names: ["Al"],
    species: "human",
    gender: "male",
    house: {
      _id: "2",
      name: "Slytherin",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "half-blood",
    eyeColour: "green",
    hairColour: "black",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "Arthur Bowen",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "399",
    title: "Albus Severus Potter",
  },
  {
    name: "Rose Weasley",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "1",
      name: "Gryffindor",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "half-blood",
    eyeColour: "",
    hairColour: "red",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "Helena Barlow",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "400",
    title: "Rose Weasley",
  },
  {
    name: "Hugo Weasley",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "half-blood",
    eyeColour: "",
    hairColour: "brown",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "Ryan Turner",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "401",
    title: "Hugo Weasley",
  },
  {
    name: "Scorpius Malfoy",
    alternate_names: ["Scorpius Hyperion Malfoy"],
    species: "human",
    gender: "male",
    house: {
      _id: "2",
      name: "Slytherin",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "pure-blood",
    eyeColour: "grey",
    hairColour: "blond",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "Bertie Gilbert",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "402",
    title: "Scorpius Malfoy",
  },
  {
    name: "Victoire Weasley",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: {
      _id: "999",
      name: "None",
    },
    dateOfBirth: "",
    yearOfBirth: null,
    wizard: true,
    ancestry: "",
    eyeColour: "",
    hairColour: "blonde",
    wand: {
      wood: "",
      core: "",
      length: null,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "",
    alternate_actors: [],
    alive: true,
    image: "",
    _id: "403",
    title: "Victoire Weasley",
  },
];

export function getHP() {
  // const objCopy = [];
  // hp.forEach((item, i) => {
  //   objCopy[i] = {};
  //   objCopy[i].name = item.name;
  //   objCopy[i].house = { ...item.house };
  //   objCopy[i].actor = item.actor;
  //   objCopy[i]._id = item._id;
  //   objCopy[i].title = item.title;
  // });

  // console.log("objCopy", objCopy);
  return hp;
}
