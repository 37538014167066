import React from "react";
import { Link } from "react-router-dom";

const UserCard = ({ user }) => {
  if (!user) return null;
  //   console.log("user in user card", user);
  const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const to = `/user-profile/${user._id}`;
  const src = user.profileImage
    ? `${REACT_APP_SERVER_URL}/users/${user._id}/profile-image/${user.profileImage}`
    : user.googlePicture;

  return (
    <div className="d-flex">
      <div className="p-1">
        <Link to={to}>
          <img src={src} alt="" style={{ width: "30px" }} />
        </Link>
      </div>
      <div className="pt-3" style={{ fontSize: ".7rem" }}>
        <Link to={to} style={{ textDecoration: "none" }}>
          {user.username}
        </Link>
      </div>
    </div>
  );
};

export default UserCard;
