import React, { useRef, useEffect } from "react";
import { useForm } from "react-hook-form";

const ItemForm = ({ dataItem, dataCategories, contentType, onSubmit }) => {
  console.log("dataItem---------------", dataItem);

  const formFile = useRef();

  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    trigger,
    setValue,
    getValues,
    reset,
  } = useForm({
    defaultValues: {
      title: dataItem ? dataItem.title : "",
      mainContent: dataItem ? dataItem.mainContent : "",

      rate:
        contentType === "movies" && dataItem?.dailyRentalRate
          ? dataItem?.dailyRentalRate
          : "",

      category: dataItem
        ? contentType === "movies"
          ? dataItem.genre?._id
          : dataItem.category?._id
        : "",
      activate: dataItem ? dataItem.activate : false,
      rateRange: dataItem ? dataItem.dailyRentalRate : 0,

      // instagram1: dataItem ? dataItem.instagram[0] : "",
      // instagram2: dataItem ? dataItem.instagram[1] : "",
      // instagram3: dataItem ? dataItem.instagram[2] : "",

      instagram1: dataItem && dataItem.instagram && dataItem.instagram[0],
      instagram2: dataItem && dataItem.instagram && dataItem.instagram[1],
      instagram3: dataItem && dataItem.instagram && dataItem.instagram[2],

      twitter1: dataItem && dataItem.twitter && dataItem.twitter[0],
      twitter2: dataItem && dataItem.twitter && dataItem.twitter[1],
      twitter3: dataItem && dataItem.twitter && dataItem.twitter[2],
    },
  });

  const activateValidation = (e) => {
    e.preventDefault();
    return true;
  };

  const handleRateRangeChange = (e) => {
    setValue("rate", e.target.value, { shouldValidate: true });
  };

  const handleRateChange = (e) => {
    setValue("rateRange", e.target.value, { shouldValidate: false });
  };

  const calcProgressPercent = (rate, inc = 0.5, x = 10) => x * (rate / inc);

  const socialMedia = (name, label) => {
    return (
      <div className="row">
        <div className="col">
          <div className="mb-3">
            <label htmlFor={name} className="form-label fw-bold">
              {label}
            </label>
            <input
              {...register(name)}
              type={name}
              className="form-control"
              id={name}
              aria-describedby="instagram1Help"
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <form
        onSubmit={(e) => {
          if (activateValidation(e)) {
            handleSubmit(onSubmit)(e);
          }
        }}
      >
        <div className="row">
          <div className="col">
            <div className="mb-3">
              <label htmlFor="title" className="form-label fw-bold">
                Title
              </label>
              <input
                {...register("title", { required: "Title is required" })}
                type="title"
                className="form-control"
                id="title"
                aria-describedby="titleHelp"
              />
              <div id="titleHelp" className="form-text">
                {errors.title?.type === "required" && (
                  <p role="alert">{errors.title.message}</p>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="mb-3">
              <label htmlFor="mainContent" className="form-label fw-bold">
                Content
              </label>
              <textarea
                {...register("mainContent", {
                  required: "Content is required",
                })}
                className="form-control"
                id="mainContent"
                rows="15"
              ></textarea>
              <div id="mainContentHelp" className="form-text">
                {errors.mainContent?.type === "required" && (
                  <p role="alert">{errors.mainContent.message}</p>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* IMAGE UPLOAD START */}
        <div className="row">
          <div className="col">
            <div className="mb-3">
              <label htmlFor="formFile" className="form-label fw-bold">
                Image
              </label>
              <input
                {...register("formFile")}
                className="form-control"
                type="file"
                id="formFile"
                name="formFile"
              />
            </div>
          </div>
        </div>
        {/* IMAGE UPLOAD END*/}

        {/* RATING START */}
        {contentType === "movies" && (
          <div className="row">
            <div className="col">
              <div className="mb-3 ">
                <label htmlFor="rate" className="form-label fw-bold">
                  Rating
                </label>

                <input
                  {...register("rate", { required: "Rate is required" })}
                  className="form-control"
                  style={{ visibility: "hidden", display: "none" }}
                  id="rate"
                  name="rate"
                  type="number"
                  step="0.5"
                  min="0"
                  max="5"
                  onChange={(e) => handleRateChange(e)}
                  aria-describedby="rateHelp"
                />
                <div id="rateHelp" className="form-text">
                  {errors.rate?.type === "required" && (
                    <p role="alert">{errors.rate.message}</p>
                  )}
                </div>
                <div className=" " style={{ position: "relative" }}>
                  <div
                    className=" "
                    style={{ position: "absolute", width: "100%", top: "0px" }}
                  >
                    <div
                      className="progress"
                      style={{ height: "1.5rem", borderRadius: "5px 5px 0 0" }}
                    >
                      <div
                        className="progress-bar bg-info"
                        role="progressbar"
                        aria-label="Info example"
                        style={{
                          width: `${calcProgressPercent(getValues("rate"))}%`,
                        }}
                        aria-valuenow="50"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >
                        {getValues("rate")} / 5
                      </div>
                    </div>
                  </div>
                  <div
                    className=" "
                    style={{
                      position: "absolute",
                      width: "100%",
                      top: "18px",
                    }}
                  >
                    <input
                      {...register("rateRange", { required: false })}
                      type="range"
                      className="form-range"
                      min="0"
                      max="5"
                      step="0.5"
                      id="rateRange"
                      name="rateRange"
                      onChange={(e) => handleRateRangeChange(e)}
                    ></input>
                  </div>
                </div>
                <br />
              </div>
            </div>
          </div>
        )}
        {/* RATING END */}

        <div className="row">
          <div className="col">
            <div className="mb-3">
              <label htmlFor="mainContent" className="form-label fw-bold">
                Category
              </label>
              {dataCategories.length ? (
                <select
                  {...register("category", {
                    required: "Category is required",
                  })}
                  name="category"
                  className="form-select form-select mb-3"
                  aria-label=".form-select"
                >
                  <option value="">-Select-</option>
                  {dataCategories.map((cat) => (
                    <option key={cat._id} value={cat._id}>
                      {cat.name}
                    </option>
                  ))}
                </select>
              ) : (
                "loading..."
              )}
              <div id="categoryHelp" className="form-text">
                {errors.category?.type === "required" && (
                  <p role="alert">{errors.category.message}</p>
                )}
              </div>
            </div>
          </div>
        </div>

        {socialMedia("instagram1", "Instagram 1")}
        {socialMedia("instagram2", "Instagram 2")}
        {socialMedia("instagram3", "Instagram 3")}

        {socialMedia("twitter1", "Twitter 1")}
        {socialMedia("twitter2", "Twitter 2")}
        {socialMedia("twitter3", "Twitter 3")}

        <div className="row">
          <div className="col">
            <div className="form-check form-switch  mb-3">
              <input
                {...register("activate")}
                name="activate"
                id="activate"
                className="form-check-input"
                type="checkbox"
                role="switch"
              />
              <label className="form-check-label" htmlFor="activate">
                Activate
              </label>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <div className="mb-3">
              <input type="submit" className="btn btn-primary" value="Save" />
            </div>
          </div>
        </div>
      </form>
    </React.Fragment>
  );
};

export default ItemForm;
