import React from "react";
import { Outlet, Navigate } from "react-router-dom";

const ProtectedRoute = ({ children, isAllowed, redirectPath = "/" }) => {
  if (!isAllowed) {
    sessionStorage.setItem("redirect", window.location.pathname);
    return (
      <Navigate
        to={`${redirectPath}?redirect=${window.location.pathname}`}
        replace
      />
    );
  }
  return children ? children : <Outlet />;
};

export default ProtectedRoute;

// const redirectUrl = sessionStorage.getItem('redirect');
// sessionStorage.setItem('redirectUrl', window.location.pathname);
