import React, { useState, useEffect } from "react";
// import { getHP } from "../../services/fakeHarryPotterService";
import { getHP } from "../../services/harryPotterService";
import Paginaiton from "../common/pagination";
import { paginate } from "../../utils/paginate";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";

const HarryPotter = () => {
  const [hp, setHp] = useState([]);

  const [pageSize] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const loadData = async () => {
    const data = await getHP();
    setHp(data);
  };

  useEffect(() => {
    loadData();
  }, []);

  const hp2 = [];

  useEffect(() => {
    hp.forEach((item, i) => {
      hp2[i] = { ...item };
      hp2[i]._id = String(i + 1); //`${i}_${uuidv4()}`;
      hp2[i].title = hp2[i].name;

      if (hp2[i].house === "Gryffindor") {
        hp2[i].house = { _id: "1", name: "Gryffindor" };
      }

      if (hp2[i].house === "Slytherin") {
        hp2[i].house = { _id: "2", name: "Slytherin" };
      }

      if (hp2[i].house === "Hufflepuff") {
        hp2[i].house = { _id: "3", name: "Hufflepuff" };
      }

      if (hp2[i].house === "Ravenclaw") {
        hp2[i].house = { _id: "4", name: "Ravenclaw" };
      }

      if (hp2[i].house === "") {
        hp2[i].house = { _id: "999", name: "None" };
      }
    });
  }, [hp]);

  return (
    <React.Fragment>
      <div className="container">
        <h1>Harry Potter Characters</h1>

        <div className="row row-cols-1 row-cols-md-3 g-4">
          {hp.length ? (
            <React.Fragment>
              {hp.map((h, i) => (
                <div className="col" key={i}>
                  <div className="card" style={{ width: "18rem" }}>
                    {h.image ? (
                      <img src={h.image} className="card-img-top" alt="..." />
                    ) : (
                      `No image`
                    )}
                    <div className="card-body">
                      <h5 className="card-title">{h.name}</h5>
                      <p className="card-text">
                        <b>{h.name}</b> of <b>{h.house}</b> is played by:{" "}
                        <i>{h.actor}</i>
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </React.Fragment>
          ) : (
            "loading..."
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default HarryPotter;
