const REACT_APP_SERVER_URL = `${process.env.REACT_APP_SERVER_URL}/api/v1`;

export async function getTodos(_id) {
  const URL = `${REACT_APP_SERVER_URL}/todos/${_id}`;
  try {
    const response = await fetch(URL);
    const data = await response.json();
    return data;
  } catch (err) {
    console.log("Error:", err);
  }
  return null;
}

export async function saveTodo(item) {
  const URL = `${REACT_APP_SERVER_URL}/todos`;
  try {
    const response = await fetch(URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(item),
    });
    const data = await response.json();
    return data;
  } catch (err) {
    console.log("Error:", err);
  }
  return null;
}

export async function updateTodo(item) {
  const URL = `${REACT_APP_SERVER_URL}/todos`;
  try {
    const response = await fetch(URL, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(item),
    });
    const data = await response.json();
    return data;
  } catch (err) {
    console.log("Error:", err);
  }
  return null;
}

export async function updateClearTodos(items) {
  const URL = `${REACT_APP_SERVER_URL}/todos/clear`;
  if (items.length) {
    try {
      const response = await fetch(URL, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(items),
      });
      const data = await response.json();
      return data;
    } catch (err) {
      console.log("Error:", err);
    }
  }
  return null;
}

export async function deleteTodo(id) {
  const URL = `${REACT_APP_SERVER_URL}/todos`;
  try {
    const response = await fetch(URL, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ _id: id }),
    });
    const data = await response.json();
    return data;
  } catch (err) {
    console.log("Error:", err);
  }
}
