import React, { useEffect, useState, useRef, useContext } from "react";
import { Link } from "react-router-dom";
import io from "socket.io-client";
import { UserContext } from "../userContext";
import CardCollapse from "../common/cardCollapse";
import Hero from "../hero";
import { getPlayers } from "./gamePlayers";
import Grid from "../common/grid";
import CardGamePlayer from "./cardGamePlayer";
import _ from "lodash";

//FOR LOCAL SERVER ONLY
const URL = "http://localhost:5000";

//FOR REMOTE SERVER
// const URL = "https://www.imassmedia.com";

const Game = () => {
  // NEW STRIKE SETUP
  const [disabledStrikeBtnMe, setDisabledStrikeBtnMe] = useState(false);
  const [disabledStrikeBtnOpp, setDisabledStrikeBtnOpp] = useState(false);

  const strikeMeRef = useRef(null);
  const strikeOppRef = useRef(null);

  const { user, setUser } = useContext(UserContext);
  const [socket, setSocket] = useState(null);

  const [gameUsers, setGameUsers] = useState([]);
  const [playerMe, setPlayerMe] = useState({});
  const [playerOpponent, setPlayerOpponent] = useState({});
  const [opponentActions, setOpponentActions] = useState("");
  const [myActions, setMyActions] = useState("");
  const [gameStatusText, setGameStatusText] = useState("");
  const [gameStartCountdown, setGameStartCountdown] = useState("");

  const [playerAttr, setPlayerAttr] = useState({});
  const [playerList, getPlayerList] = useState([]);

  const [currentScreen, setCurrentScreen] = useState("0");

  const [strikeBtnDisabled, setStrikeBtnDisabled] = useState(false);
  const [spellBtnDisabled, setSpellBtnDisabled] = useState(false);
  const [blockBtnDisabled, setBlockBtnDisabled] = useState(false);
  const [lifeBtnDisabled, setLifeBtnDisabled] = useState(false);

  const [strikeAnimationRunningMe, setStrikeAnimationRunningMe] =
    useState("paused");

  const [strikeAnimationRunningOpp, setStrikeAnimationRunningOpp] =
    useState("paused");

  const [spellAnimationRunningMe, setSpellAnimationRunningMe] =
    useState("paused");

  const [spellAnimationRunningOpp, setSpellAnimationRunningOpp] =
    useState("paused");

  const oppActionsRef = useRef();
  const myActionsRef = useRef();
  const restartBtnRef = useRef();
  const enterBtnRef = useRef();

  const strikeIconMe = (
    <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
  );
  const strikeIconOpp = (
    <i className="fa fa-long-arrow-left" aria-hidden="true"></i>
  );
  const spellIcon = <i className="fa fa-magic" aria-hidden="true"></i>;
  const blockIcon = <i className="fa fa-ban" aria-hidden="true"></i>;
  const lifeIcon = <i className="fa fa-heart" aria-hidden="true"></i>;

  useEffect(() => {
    loadPlayerList();
  }, []);

  useEffect(() => {
    if (currentScreen === "2B") {
      console.log("spellAnimationRunningOpp", spellAnimationRunningOpp);
      const playerBoardOpp = document.getElementById("player-board-opp");

      playerBoardOpp.style.animationPlayState = spellAnimationRunningOpp;
    }
  }, [spellAnimationRunningOpp]);

  useEffect(() => {
    if (currentScreen === "2B") {
      console.log("spellAnimationRunningMe", spellAnimationRunningMe);
      const playerBoardMe = document.getElementById("player-board-me");

      playerBoardMe.style.animationPlayState = spellAnimationRunningMe;
    }
  }, [spellAnimationRunningMe]);

  // useEffect(() => {
  //   console.log("strikeAnimationRunningOpp--", strikeAnimationRunningOpp);
  //   if (currentScreen === "2B") {
  //     const strikeOpp = document.getElementById("strike-child-opp");

  //     // const animationState = getComputedStyle(strikeOpp).animationPlayState;

  //     strikeOpp.style.animationPlayState = strikeAnimationRunningOpp;

  //     strikeOpp.addEventListener("animationend", function () {
  //       setStrikeAnimationRunningOpp("paused");
  //     });

  //     return () => {
  //       strikeOpp.removeEventListener("animationend", function () {
  //         setStrikeAnimationRunningOpp("paused");
  //       });
  //     };
  //   }
  // }, [strikeAnimationRunningOpp]);

  // useEffect(() => {
  //   console.log("strikeAnimationRunningOpp--", strikeAnimationRunningOpp);
  //   if (currentScreen === "2B") {
  //     const strikeMe = document.getElementById("strike-child-me");

  //     const animationState = getComputedStyle(strikeMe).animationPlayState;

  //     // if (animationState === "paused") {
  //     strikeMe.style.animationPlayState = strikeAnimationRunningMe;
  //     // }

  //     strikeMe.addEventListener("animationend", function () {
  //       console.log("Animation completed!");
  //       setStrikeAnimationRunningMe("paused");
  //     });

  //     return () => {
  //       strikeMe.removeEventListener("animationend", function () {
  //         console.log("Animation completed!");
  //         setStrikeAnimationRunningMe("paused");
  //       });
  //     };
  //   }
  // }, [strikeAnimationRunningMe]);

  useEffect(() => {
    setCurrentScreen("1");
  }, [playerList]);

  useEffect(() => {
    if (currentScreen === "1") {
      enterBtnRef.current.disabled = true;

      setLifeBtnDisabled(true);
      setPlayerAttr({});
    }

    if (currentScreen === "2A") {
      setGameStatusText("Waiting for second player...");
      restartBtnRef.current.disabled = true;
    }

    if (currentScreen === "2X") {
      setGameStatusText(`3-2-1...`);
      restartBtnRef.current.disabled = true;
      toggleButtonsDisabled(true);
      // startCountdown();
    }

    // if (currentScreen === "2B") {
    //   setGameStatusText(
    //     `${playerMe.playerName} Vs. ${playerOpponent.playerName}`
    //   );
    //   restartBtnRef.current.disabled = false;
    //   toggleButtonsDisabled(false);
    // }

    if (currentScreen === "2B") {
      restartBtnRef.current.disabled = true;
      toggleButtonsDisabled(true);
      setGameStatusText(
        `${playerMe.playerName} Vs. ${playerOpponent.playerName}`
      );
      let count = 3;
      const myInterval = setInterval(function () {
        setGameStartCountdown(count);
        count--;

        if (count < 0) {
          clearInterval(myInterval);
          setGameStartCountdown("Battle!");

          const myTimeout = setTimeout(() => {
            setGameStartCountdown("");
            restartBtnRef.current.disabled = false;
            toggleButtonsDisabled(false);
          }, 2000);
        }
      }, 1000);
    }

    if (currentScreen === "2C") {
      const winner = gameUsers.find((user) => user.lifeMeter !== 0);
      setGameStatusText(`${winner.playerName} Wins!`);
      toggleButtonsDisabled(true);
    }

    console.log("CURRENT SCREEN", currentScreen);
  }, [currentScreen]);

  useEffect(() => {
    if (currentScreen === "1") {
      if (!_.isEmpty(playerAttr)) enterBtnRef.current.disabled = false;
    }
  }, [playerAttr]);

  useEffect(() => {
    console.log("gameUsers", gameUsers);
    if (gameUsers.length === 0) {
      setCurrentScreen("1");
    }
    if (gameUsers.length === 1) {
      setCurrentScreen("2A");
    }
    if (gameUsers.length === 2) {
      setCurrentScreen("2B");
    }
  }, [gameUsers]);

  useEffect(() => {
    console.log("playerMe", playerMe);
    console.log("playerMe.lifeMeter", playerMe.lifeMeter);
    if (playerMe.lifeMeter < 33) setLifeBtnDisabled(false);
  }, [playerMe]);

  useEffect(() => {
    console.log("playerMe.lifeMeter", playerMe.lifeMeter);
    console.log("playerOpponent.lifeMeter", playerOpponent.lifeMeter);

    if (playerMe.lifeMeter === 0 || playerOpponent.lifeMeter === 0) {
      setCurrentScreen("2C");
    }
  }, [playerMe.lifeMeter, playerOpponent.lifeMeter]);

  useEffect(() => {
    console.log("playerOpponent", playerOpponent);
  }, [playerOpponent]);

  useEffect(() => {
    if (opponentActions !== "") {
      const myTimeout = setTimeout(() => {
        if (!playerOpponent.blocking && !playerOpponent.spelling) {
          oppActionsRef.current.style.visibility = "hidden";
          setOpponentActions("");
        }
      }, 1000);
    }
  }, [opponentActions]);

  useEffect(() => {
    if (myActions !== "") {
      const myTimeout = setTimeout(() => {
        if (!playerMe.blocking && !playerMe.spelling) {
          myActionsRef.current.style.visibility = "hidden";
          setMyActions("");
          setStrikeBtnDisabled(false);
        }
      }, 1000);
    }
  }, [myActions]);

  useEffect(() => {
    if (!socket) return;

    // restartBtnRef.current.disabled = true;

    socket.on("connected", () => {
      const newGameUser = {
        userId: user._id,
        socketId: socket.id,
        username: user.username,
        profileImage: user.profileImage ? user.profileImage : "",
        lifeMeter: 100,
        sender: false,
        receiver: false,
        ...playerAttr,
      };

      console.log("ENTERED GAME@@@@@@@@@@", newGameUser);
      socket.emit("gameUser", newGameUser);
    });

    socket.on("gameUsers", (newGameUsers) => {
      console.log("gamuesssuerss data", newGameUsers);

      updatePlayers(newGameUsers);
      console.log("newGameUsers", newGameUsers);
    });

    socket.on("receive_strike", (data) => {
      //update players from server call
      const [me, opp] = updatePlayers(data);

      const strike = document.createElement("div");

      console.log("receive_strike - strikeMeRef", strikeMeRef);
      console.log("receive_strike - strikeMeRef.current", strikeMeRef.current);

      if (me.sender) {
        strike.className = "strike-me";
        strikeMeRef.current.appendChild(strike);
        setTimeout(() => {
          strike.className += " disappear";
        }, 10);
        setTimeout(() => {
          strikeMeRef.current.removeChild(strike);
          setDisabledStrikeBtnMe(false);
        }, 1000);
      }

      if (me.receiver) {
        strike.className = "strike-opp";
        strikeOppRef.current.appendChild(strike);
        setTimeout(() => {
          strike.className += " disappear";
        }, 10);
        setTimeout(() => {
          strikeOppRef.current.removeChild(strike);
          setDisabledStrikeBtnOpp(false);
        }, 1000);
      }

      if (me.receiver) {
        if (!opp.spelling) {
          setOpponentActions(strikeIconOpp);
        } else {
          setOpponentActions(
            <span>
              {spellIcon}
              {strikeIconOpp}
            </span>
          );
        }
      } else {
        if (!me.spelling) {
          setMyActions(strikeIconMe);
        } else {
          setMyActions(
            <span>
              {spellIcon}
              {strikeIconMe}
            </span>
          );
        }
      }
    });

    // socket.on("receive_strike", (data) => {
    //   //update players from server call
    //   const [me, opp] = updatePlayers(data);

    //   if (me.receiver) setStrikeAnimationRunningMe("running");

    //   if (me.sender) setStrikeAnimationRunningOpp("running");

    //   //show strike/arrow icon on strike for receiver
    //   //clear opp actions on screen for sender
    //   if (me.receiver) {
    //     if (!opp.spelling) {
    //       setOpponentActions(strikeIconOpp);
    //     } else {
    //       setOpponentActions(
    //         <span>
    //           {spellIcon}
    //           {strikeIconOpp}
    //         </span>
    //       );
    //     }
    //   } else {
    //     if (!me.spelling) {
    //       setMyActions(strikeIconMe);
    //     } else {
    //       setMyActions(
    //         <span>
    //           {spellIcon}
    //           {strikeIconMe}
    //         </span>
    //       );
    //     }
    //   }
    // });

    socket.on("receive_blockStart", (data) => {
      //update players from server call
      const [me, opp] = updatePlayers(data);

      //show opp is blocking in opp actions
      if (opp.blocking) setOpponentActions(blockIcon);
      if (me.blocking) setMyActions(blockIcon);

      //disable buttons for 2 seconds when blocking
      if (me.blocking) {
        toggleButtonsDisabled(true);
      }
    });

    socket.on("receive_blockEnd", (data) => {
      //update players from server call
      const [me, opp] = updatePlayers(data);
      setOpponentActions("");
      setMyActions("");
      toggleButtonsDisabled(false);

      if (me.spells === 0) setSpellBtnDisabled(true);
    });

    socket.on("receive_spellStart", (data) => {
      console.log("on receive spell start", data);

      //update players from server call
      const [me, opp] = updatePlayers(data);

      if (me.receiver) setSpellAnimationRunningMe("running");

      if (me.sender) setSpellAnimationRunningOpp("running");

      //show opp is spelling in opp actions
      //disable buttons for 2 seconds when spelling
      if (opp.spelling) {
        setOpponentActions(spellIcon);
        toggleButtonsDisabled(true);
      }
      if (me.spelling) {
        setMyActions(spellIcon);
      }

      if (me.spelling) {
        setSpellBtnDisabled(true);
      }
    });

    socket.on("receive_spellEnd", (data) => {
      //update players from server call
      const [me, opp] = updatePlayers(data);

      console.log("SPELL END me", me);

      if (me.receiver) setSpellAnimationRunningMe("paused");

      if (me.sender) setSpellAnimationRunningOpp("paused");

      setOpponentActions("");
      setMyActions("");
      toggleButtonsDisabled(false);

      if (me.spells === 0) setSpellBtnDisabled(true);
    });

    socket.on("receive_lifeBoost", (data) => {
      //update players from server call
      const [me, opp] = updatePlayers(data);

      if (opp.sender) setOpponentActions(lifeIcon);
      if (me.sender) {
        setMyActions(lifeIcon);
        setLifeBtnDisabled(true);
      }
    });

    socket.on("restart", (data) => {
      updatePlayers(data);
    });

    //CLEAN UP EVENTS
    return () => {
      socket.off("connected");
      socket.off("gameUsers");
    };
  }, [socket]);

  const toggleButtonsDisabled = (bool) => {
    setStrikeBtnDisabled(bool);
    setSpellBtnDisabled(bool);
    setBlockBtnDisabled(bool);
    // setLifeBtnDisabled(bool);
  };

  const GamePlayButtons = ({}) => {
    return (
      <div style={{}} className="d-flex justify-content-center">
        <div className="d-flex justify-content-center border mt-3 rounded-3">
          <div className="p-2">
            {/* <button
              className="btn btn-dark"
              onClick={handleStrike}
              disabled={strikeBtnDisabled}
            >
              <span className="text-danger">{strikeIconMe}</span> Strike
            </button> */}

            <button
              className="btn btn-dark"
              disabled={disabledStrikeBtnMe}
              onClick={handleClickMe}
            >
              <span className="text-danger">{strikeIconMe}</span> Strike
            </button>
          </div>
          <div className="p-2">
            <button
              className="btn btn-dark"
              onClick={handleStartSpell}
              disabled={spellBtnDisabled}
            >
              <span className="text-danger">{spellIcon}</span> Spell{" "}
              <span className="badge text-bg-secondary">
                {playerMe?.spells}
              </span>
            </button>
          </div>
          <div className="p-2">
            <button
              className="btn btn-dark"
              onClick={handleStartBlock}
              disabled={blockBtnDisabled}
            >
              <span className="text-danger">{blockIcon}</span> Block
            </button>
          </div>

          <div className="p-2">
            <button
              className="btn btn-dark"
              onClick={handleLifeBoost}
              disabled={lifeBtnDisabled}
            >
              <span className="text-danger">{lifeIcon}</span> Life{" "}
              <span className="badge text-bg-secondary">
                {playerMe?.lifeBoosts}
              </span>
            </button>
          </div>
        </div>
      </div>
    );
  };

  const updatePlayers = (data) => {
    setGameUsers([...data]);
    let me = {};
    let opp = {};

    me = data.find((item) => item.userId === user._id);
    setPlayerMe(me);

    if (data.length > 1) {
      opp = data.find((item) => item.userId !== user._id);
      setPlayerOpponent(opp);
    }

    return [me, opp];
  };

  const loadPlayerList = async () => {
    const players = await getPlayers();
    getPlayerList(players);
  };

  const handleEnterGame = () => {
    setSocket(io.connect(URL));
  };

  const handleDisconnect = () => {
    socket.disconnect();
    setSocket(null);
    setGameUsers([]);
    setPlayerMe({});
    setPlayerOpponent({});
  };

  const handlePlayerSelect = (player) => {
    setPlayerAttr(player);
  };

  const handleRestart = () => {
    console.log("restart");
    socket.emit("restart");
  };

  const handleStrike = () => {
    const obj = { userId: user._id };
    socket.emit("send_strike", obj);
    setStrikeBtnDisabled(true);
  };

  const handleClickMe = () => {
    console.log("handleClickMe", handleClickMe);

    const obj = { userId: user._id };
    socket.emit("send_strike", obj);
    // setStrikeBtnDisabled(true);
    setDisabledStrikeBtnMe(true);

    // const strike = document.createElement("div");
    // strike.className = "strike-me";
    // strikeMeRef.current.appendChild(strike);
    // setTimeout(() => {
    //   strike.className += " disappear";
    // }, 10);
    // setTimeout(() => {
    //   strikeMeRef.current.removeChild(strike);
    //   setDisabledStrikeBtnMe(false);
    // }, 1000);
  };

  const handleStartBlock = () => {
    const obj = { userId: user._id };
    socket.emit("send_blockStart", obj);
  };

  const handleStartSpell = () => {
    const obj = { userId: user._id };
    socket.emit("send_spellStart", obj);
  };

  const handleLifeBoost = () => {
    const obj = { userId: user._id };
    socket.emit("send_lifeBoost", obj);
  };

  const DisplayMyActions = () => {
    return (
      <span
        ref={myActionsRef}
        style={{ fontWeight: "bold", color: "red", fontSize: "2rem" }}
      >
        {myActions}
      </span>
    );
  };

  const DisplayOpponentActions = () => {
    return (
      <span
        ref={oppActionsRef}
        style={{ fontWeight: "bold", color: "red", fontSize: "2rem" }}
      >
        {opponentActions}
      </span>
    );
  };

  // const PlayerBoard = ({ playerObj }) => {
  //   const player = playerObj?.userId === user._id ? "me" : "opp";
  //   return (
  //     <div className="">
  //       <div className="row">
  //         <div className="col">
  //           <div
  //             className={`d-flex ${
  //               player !== "me" ? " flex-row-reverse" : ""
  //             } `}
  //           >
  //             {playerObj?.username}
  //           </div>
  //         </div>
  //       </div>

  //       <div className="row">
  //         <div className={`col-6 order-${player === "me" ? "1" : "2"}`}>
  //           <div
  //             className={`d-flex flex-row ${
  //               player !== "me" ? " flex-row-reverse" : ""
  //             } `}
  //           >
  //             <div className="m-2">
  //               <img src={playerObj?.image} alt="" width="100px" />
  //             </div>

  //             <div>
  //               <div className={`${player !== "me" ? " text-end" : ""}`}>
  //                 <div style={{ fontSize: "2rem" }}>
  //                   {playerObj?.playerName}
  //                 </div>
  //                 <div>Spells: {playerObj?.spells}</div>
  //                 <div>Strength: {playerObj?.strikeStrength}</div>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //         {/* STRIKE BOX */}
  //         {/* <div className={`col-6 order-${player === "me" ? "2" : "1"}`}>
  //           <div
  //             style={{ width: "100%", border: "1px solid red", height: "100%" }}
  //             className="strike-parent"
  //             id={`strike-parent-${player}`}
  //           >
  //             <div className="strike-child" id={`strike-child-${player}`}></div>
  //           </div>
  //         </div> */}
  //       </div>

  //       <div className="row">
  //         <div className="col">
  //           <div
  //             style={{
  //               height: "50px",
  //             }}
  //           >
  //             {player === "me" && (
  //               <div
  //                 style={{
  //                   border: "0px solid red",
  //                   display: "flex",
  //                   justifyContent: "right",
  //                 }}
  //               >
  //                 <div
  //                   style={{
  //                     width: "50%",
  //                     textAlign: "right",
  //                     border: "0px solid red",
  //                   }}
  //                 >
  //                   <DisplayMyActions />
  //                 </div>
  //               </div>
  //             )}

  //             {player === "opp" && (
  //               <div style={{}}>
  //                 <div style={{ width: "50%", border: "0px solid red" }}>
  //                   <DisplayOpponentActions />
  //                 </div>
  //               </div>
  //             )}
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  const PlayerBoard = React.forwardRef(({ playerObj }, ref) => {
    const player = playerObj?.userId === user._id ? "me" : "opp";

    console.log("ref: ", ref);
    console.log("ref.current: ", ref.current);

    return (
      <div className="">
        <div className="row">
          <div className="col">
            <div
              className={`d-flex ${
                player !== "me" ? " flex-row-reverse" : ""
              } `}
            >
              {playerObj?.username}
            </div>
          </div>
        </div>

        <div className="row">
          <div className={`col-6 order-${player === "me" ? "1" : "2"}`}>
            <div
              className={`d-flex flex-row ${
                player !== "me" ? " flex-row-reverse" : ""
              } `}
            >
              <div className="m-2">
                <img src={playerObj?.image} alt="" width="100px" />
              </div>

              <div>
                <div className={`${player !== "me" ? " text-end" : ""}`}>
                  <div style={{ fontSize: "2rem" }}>
                    {playerObj?.playerName}
                  </div>
                  <div>Spells: {playerObj?.spells}</div>
                  <div>Strength: {playerObj?.strikeStrength}</div>
                </div>
              </div>
            </div>
          </div>
          {/* STRIKE BOX */}
          <div
            id="strike-me-parent"
            ref={ref}
            style={{
              width: "200px",
              height: "200px",
              border: "1px solid yellow",
              position: "relative",
            }}
          />
          {/* <div className={`col-6 order-${player === "me" ? "2" : "1"}`}>
            <div
              style={{ width: "100%", border: "1px solid red", height: "100%" }}
              className="strike-parent"
              id={`strike-parent-${player}`}
            >
              <div className="strike-child" id={`strike-child-${player}`}></div>
            </div>
          </div> */}
        </div>

        <div className="row">
          <div className="col">
            <div
              style={{
                height: "50px",
              }}
            >
              {player === "me" && (
                <div
                  style={{
                    border: "0px solid red",
                    display: "flex",
                    justifyContent: "right",
                  }}
                >
                  <div
                    style={{
                      width: "50%",
                      textAlign: "right",
                      border: "0px solid red",
                    }}
                  >
                    <DisplayMyActions />
                  </div>
                </div>
              )}

              {player === "opp" && (
                <div style={{}}>
                  <div style={{ width: "50%", border: "0px solid red" }}>
                    <DisplayOpponentActions />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  });

  const DisplayProfileImage = ({ userId, profileImage, width }) => {
    return (
      <img
        src={
          profileImage
            ? `${process.env.REACT_APP_SERVER_URL}/users/${userId}/profile-image/${profileImage}`
            : `${process.env.PUBLIC_URL}/images/noprofileimage.jpg`
        }
        alt=""
        style={{ width }}
      />
    );
  };

  const ConnectedUsersModule = (props) => {
    if (props.joined)
      return (
        <React.Fragment>
          <div className={"text-start"}>
            <h5>
              <i className="fa fa-plug" aria-hidden="true"></i> Connected users
            </h5>
            {gameUsers.map((gameUser, i) => (
              <div key={i} className={"text-start"}>
                <Link
                  className="page-link"
                  to={`/user-profile/${gameUser.userId}`}
                >
                  <DisplayProfileImage
                    userId={gameUser.userId}
                    profileImage={gameUser.profileImage}
                    width="30px"
                  />{" "}
                  {gameUser.username}
                </Link>
              </div>
            ))}
          </div>
        </React.Fragment>
      );
  };

  return (
    <React.Fragment>
      <Hero
        title={`G Room`}
        bgClass1="layer2"
        bgClass2="layer3"
        textClass="text-dark"
        description={``}
        main="false"
      ></Hero>

      <div className="container">
        {currentScreen === "1" && (
          <div className="row">
            <div className="col">
              <div className="row">
                <div className="col">
                  <div className="d-grid gap-2">
                    <button
                      ref={enterBtnRef}
                      className="btn btn-outline-dark"
                      onClick={handleEnterGame}
                    >
                      Enter
                    </button>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <div className="text-center fs-3 text-uppercase">
                    <div>Choose your player</div>
                    <div
                      style={{ textShadow: "4px 4px #EEE" }}
                      className="text-center fs-1 text-uppercase fw-bold"
                    >
                      {!_.isEmpty(playerAttr) ? playerAttr.playerName : ``}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <div className="d-grid gap-2">
                    <Grid content={playerList}>
                      {playerList.map((content) => (
                        <CardGamePlayer
                          key={content._id}
                          content={content}
                          handlePlayerSelect={handlePlayerSelect}
                        />
                      ))}
                    </Grid>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {(currentScreen === "2A" ||
          currentScreen === "2B" ||
          currentScreen === "2C" ||
          currentScreen === "2X") && (
          <React.Fragment>
            <div className="row">
              <div className="col">
                <ConnectedUsersModule joined={!!socket} />
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="d-grid gap-2 my-5">
                  <button onClick={handleDisconnect} className="btn btn-dark">
                    Leave Game / Change Player
                  </button>
                  <button
                    ref={restartBtnRef}
                    className="btn btn-dark"
                    onClick={handleRestart}
                  >
                    <i className="fa fa-undo" aria-hidden="true"></i> Restart
                    Game
                  </button>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div
                  style={{ textShadow: "4px 4px #EEE" }}
                  className="text-center fs-2 text-uppercase fw-bold"
                >
                  {gameStartCountdown}
                  <br />
                  {gameStatusText}
                </div>
              </div>
            </div>
          </React.Fragment>
        )}

        <div className="row">
          {/* PLAYER ME START*/}
          {(currentScreen === "2A" ||
            currentScreen === "2B" ||
            currentScreen === "2C" ||
            currentScreen === "2X") && (
            <div className="col-12 col-lg-6 mb-3" id="player-board-me">
              <div
                className="border p-3 rounded-3 bg-dark bg-gradient text-white"
                style={{ height: "100%" }}
              >
                <div
                  className="progress"
                  role="progressbar"
                  aria-label="Danger"
                  aria-valuenow="100"
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  <div
                    className={
                      playerMe?.lifeMeter > 55
                        ? "progress-bar bg-success"
                        : playerMe?.lifeMeter > 30
                        ? "progress-bar bg-warning"
                        : "progress-bar bg-danger"
                    }
                    style={{ width: `${playerMe?.lifeMeter}%` }}
                  >
                    {playerMe?.lifeMeter}
                  </div>
                </div>
                {/* <div
                  id="strike-opp-parent"
                  ref={strikeOppRef}
                  style={{
                    width: "200px",
                    height: "200px",
                    border: "1px solid yellow",
                    position: "relative",
                  }}
                /> */}
                <PlayerBoard ref={strikeOppRef} playerObj={playerMe} />
              </div>
            </div>
          )}
          {/* PLAYER ME END*/}

          {/* PLAYER OPP START*/}
          {(currentScreen === "2B" ||
            currentScreen === "2C" ||
            currentScreen === "2X") && (
            <div className="col-12 col-lg-6 mb-3" id="player-board-opp">
              <div
                className="border p-3 rounded-3 bg-dark bg-gradient text-white"
                style={{ height: "100%" }}
              >
                <div
                  className="progress justify-content-end"
                  role="progressbar"
                  aria-label="Danger"
                  aria-valuenow="100"
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  <div
                    className={
                      playerOpponent?.lifeMeter > 55
                        ? "progress-bar bg-success"
                        : playerOpponent?.lifeMeter > 30
                        ? "progress-bar bg-warning"
                        : "progress-bar bg-danger"
                    }
                    // className="progress-bar bg-success"
                    style={{ width: `${playerOpponent?.lifeMeter}%` }}
                  >
                    {playerOpponent?.lifeMeter}
                  </div>
                </div>
                {/* <div
                  id="strike-me-parent"
                  ref={strikeMeRef}
                  style={{
                    width: "200px",
                    height: "200px",
                    border: "1px solid yellow",
                    position: "relative",
                  }}
                /> */}
                <PlayerBoard ref={strikeMeRef} playerObj={playerOpponent} />
              </div>
            </div>
          )}
        </div>
        {/* PLAYER OPP END*/}

        {(currentScreen === "2B" ||
          currentScreen === "2C" ||
          currentScreen === "2X") && (
          <div className="row">
            <div className="col">
              <GamePlayButtons />
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default Game;
