const REACT_APP_SERVER_URL = `${process.env.REACT_APP_SERVER_URL}`;

export async function loginSuccess(updatedUsername) {
  try {
    const URL = `${REACT_APP_SERVER_URL}/auth/login/success`;
    const response = await fetch(URL, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
        newusername: updatedUsername,
      },
    });
    const data = await response.json();

    if (response.status == 200) {
      return data;
    }
    throw new Error("Auth failed!");
  } catch (err) {
    console.log("Error:", err);
    return null;
  }
}
