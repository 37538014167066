import React, { useEffect, useRef } from "react";
import { Collapse } from "bootstrap";

const CardCollapse = ({ title, id, show, disable = false, children }) => {
  const collapseRef = useRef();
  const collapseBtnRef = useRef();
  const faIcon = useRef();
  const btnShowClass = "fa-chevron-down";
  const btnHideClass = "fa-chevron-up";

  useEffect(() => {
    const myCollapsible = collapseRef.current;
    const bsCollapse = new Collapse(myCollapsible, {
      toggle: show ? true : false,
    });

    myCollapsible.addEventListener("hidden.bs.collapse", (event) => {
      faIcon.current.classList.remove(btnHideClass);
      faIcon.current.classList.add(btnShowClass);
    });

    myCollapsible.addEventListener("shown.bs.collapse", (event) => {
      faIcon.current.classList.add(btnHideClass);
      faIcon.current.classList.remove(btnShowClass);
    });
  }, []);

  const CollapseButton = () => {
    return (
      <button
        className="btn btn-sm btn-light "
        type="button"
        data-bs-toggle="collapse"
        data-bs-target={`#collapseCard-${id}`}
        aria-expanded="false"
        aria-controls={`collapseCard-${id}`}
        ref={collapseBtnRef}
      >
        {!!!show ? (
          <i
            className={`fa ${btnShowClass}`}
            aria-hidden="true"
            ref={faIcon}
          ></i>
        ) : (
          <i
            className={`fa ${btnHideClass}`}
            aria-hidden="true"
            ref={faIcon}
          ></i>
        )}
      </button>
    );
  };

  return (
    <React.Fragment>
      <div className="card overflow-auto border-0">
        <div
          className="card-header"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`#collapseCard-${id}`}
          aria-expanded="false"
          aria-controls={`collapseCard-${id}`}
        >
          <div className="d-flex">
            <div>{title}</div>
            <div className="ms-auto">
              <CollapseButton />
            </div>
          </div>
        </div>
        <div className={`collapse`} id={`collapseCard-${id}`} ref={collapseRef}>
          <div className="card-body">{children}</div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CardCollapse;
