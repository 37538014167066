import React from "react";
import { NavLink } from "react-router-dom";
import DateFormatter from "../components/common/dateFormat";
import UserCard from "../components/common/UserCard";

const CommentBubble = ({ comment: tweet, group }) => {
  const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;

  return (
    <React.Fragment>
      <div className="card mb-3 " style={{ backgroundColor: "#f7f7f7" }}>
        <div className="row g-0" style={{}}>
          <div className="col">
            <div className="p-2" style={{}}>
              {tweet?.tweet}
            </div>
          </div>
        </div>

        <div className="row g-0" style={{}}>
          <div className="col">
            <div className="row">
              <div className="col">
                {tweet?.contentType !== "general" ? (
                  <div className="d-flex mx-2">
                    <div className="" style={{}}>
                      <NavLink
                        className="nav-link fw-light text-uppercase comment-link-text w-100"
                        to={`/items/${tweet?.contentType}`}
                        style={{}}
                      >
                        {tweet?.contentType}
                      </NavLink>
                    </div>
                    <div
                      className="nav-link fw-light text-uppercase comment-link-text"
                      style={{}}
                    >
                      /
                    </div>
                    <div className="" style={{}}>
                      <NavLink
                        className=" nav-link fw-light text-uppercase comment-link-text  w-100"
                        to={`/items/${tweet?.contentType}`}
                        style={{}}
                      >
                        {group?.name}
                      </NavLink>
                    </div>
                    <div
                      className=" nav-link fw-light text-uppercase comment-link-text"
                      style={{}}
                    >
                      /
                    </div>
                    <div className="" style={{}}>
                      <NavLink
                        className=" nav-link fw-light text-uppercase comment-link-text  w-100"
                        to={`/${tweet?.contentType}/${
                          tweet[tweet?.contentType]?._id
                        }`}
                        style={{}}
                      >
                        {tweet[tweet.contentType]?.title}
                      </NavLink>
                    </div>

                    <div className="ms-auto" style={{}}></div>
                  </div>
                ) : (
                  <div className="d-flex mx-2">
                    <div
                      className=" nav-link fw-light text-uppercase comment-link-text  w-100"
                      to={`/items/movies/${tweet?.user?._id}`}
                      style={{}}
                    >
                      {group?.name}
                    </div>
                    <div className="ms-auto" style={{}}></div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="card-footer text-muted">
          <div className="d-flex">
            <div className="" style={{}}>
              <UserCard user={tweet.user} />
            </div>

            <div
              className="ms-auto fw-light comment-link-text"
              style={{ border: "0px solid red", padding: "5px" }}
            >
              <DateFormatter date={tweet?.createdAt} />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CommentBubble;
