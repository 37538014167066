import React from "react";

const Todo = ({ todo, toggleTodoCheckBox, doDeleteTodoItem }) => {
  const handleToggleTodoCheckBox = () => {
    toggleTodoCheckBox(todo);
  };

  const handleDeleteTodoItem = () => {
    doDeleteTodoItem(todo);
  };

  return (
    <div>
      <i
        style={{ cursor: "pointer", color: "#999999" }}
        onClick={handleDeleteTodoItem}
        className="fa fa-times"
        aria-hidden="true"
      ></i>{" "}
      <label>
        <input
          type="checkbox"
          checked={todo.completed}
          onChange={handleToggleTodoCheckBox}
        />
        <span
          style={{ textDecoration: todo.completed ? "line-through" : "none" }}
        >
          &nbsp;{todo.description}
        </span>
      </label>
    </div>
  );
};

export default Todo;
