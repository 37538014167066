const REACT_APP_SERVER_URL = `${process.env.REACT_APP_SERVER_URL}`;

export async function postContact(msgObj) {
  console.log("msgObj:", msgObj);
  const URL = `${REACT_APP_SERVER_URL}/send-mail/contact`;
  //   return msgObj;

  try {
    const response = await fetch(URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(msgObj),
    });
    const data = await response.json();
    return data;
  } catch (err) {
    console.log("Error:", err);
  }
  return null;
}
