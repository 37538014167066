import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getUsers } from "../services/usersService";
import Card from "./common/card";
import Grid from "./common/grid";
import HelmetNavItems from "./common/helmet/helmetNavItems";
import Hero from "./hero";
import DateFormatter from "./common/dateFormat";

const Users = () => {
  const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;

  const [users, setUsers] = useState([]);
  const URL = "/users";

  const loadUsers = async () => {
    const data = await getUsers();

    setUsers(data);
  };

  useEffect(() => {
    loadUsers();
  }, []);

  return (
    <React.Fragment>
      <Hero
        title={`Users`}
        bgClass1="layer2"
        bgClass2="layer3"
        textClass="text-dark"
        description={`Users on the site.`}
        icon={` text-secondary fa fa-users`}
      ></Hero>

      <Grid>
        {users.map((user) => (
          <Card key={user._id}>
            <div className="d-flex justify-content-start">
              <div className="p-2">
                <Link
                  to={`/user-profile/${user._id}`}
                  style={{ textDecoration: "none", fontSize: "0.9rem" }}
                  className="link-dark"
                >
                  <img
                    src={
                      user.profileImage
                        ? `${REACT_APP_SERVER_URL}/users/${user._id}/profile-image/${user.profileImage}`
                        : user.googlePicture
                    }
                    alt="imassmedia logo"
                    width="50"
                    className=""
                  />
                </Link>
              </div>
              <div className="">
                <div className="d-flex flex-column mb-0">
                  <h5 className="p-1 mt-1 mb-1  lh-1">
                    <Link
                      to={`/user-profile/${user._id}`}
                      style={{ textDecoration: "none", fontSize: "0.9rem" }}
                      className="link-dark  fw-bold"
                    >
                      {user.username}
                    </Link>
                  </h5>
                  <li
                    className="d-flex  m-2 fw-light"
                    style={{ fontSize: "0.8rem" }}
                  >
                    <DateFormatter date={user.createdAt} />
                  </li>
                </div>
              </div>
            </div>
          </Card>
        ))}
      </Grid>

      <HelmetNavItems mainTitle="Community" to={`/users`} />
    </React.Fragment>
  );
};

export default Users;
