import React from "react";

const MyIntro = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col col-12 col-lg-2">
          <div className="text-center">
            <img
              src="/images/android-chrome-192x192.png"
              alt=""
              style={{ width: "70px" }}
            />
          </div>
        </div>

        <div className="col col-12 col-lg-10">
          <div className="my-intro-div p-4 text-white">
            Welcome to my personal website! My name is Arlo Hart and I am a
            full-stack web developer. I created this platform to showcase my
            expertise in web development and to fulfill my passion for having my
            own online presence.
            <br />
            <br />
            I am constantly striving to improve and enhance my website, and I
            will regularly update it with bug fixes, aesthetic improvements, and
            new features. I take pride in delivering the best user experience
            possible and welcome any feedback you may have.
            <br />
            <br />
            If you are interested in collaborating with me on a project or
            considering me for a job opportunity, feel free to reach out to me
            through the contact form on my website. I am always open to new
            opportunities and would love to hear from you.
            <br />
            <br />
            Thank you for visiting my website and I hope you enjoy your
            experience here. Please check back for updates and new content, as I
            am continuously working to improve and expand my website.
            <br />
            <br />
            <figcaption className="blockquote-footer">Arlo</figcaption>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyIntro;
