import React, { useEffect, useState } from "react";
import _ from "lodash";

const ListGroupMulti = ({ items, onItemsSelect, itemTypeProperties }) => {
  const [itemCategories, setItemCategories] = useState(items);
  const [itemAll, setItemAll] = useState(() => ({
    _id: "all",
    name: "All",
    selected: true,
  }));

  const handleItemsSelect = (item) => {
    const itemsCopy = [];
    itemCategories.forEach((item, i) => {
      const itemCopy = { ...item };
      itemsCopy[i] = itemCopy;
    });

    if (item._id === "all") {
      itemAll.selected = true;
      itemsCopy.forEach((item) => {
        item.selected = false;
      });
    } else {
      itemAll.selected = false;
      const index = itemsCopy.map((item) => item._id).indexOf(item._id);
      itemsCopy[index].selected = !itemsCopy[index].selected;

      if (!itemsCopy.filter((item) => item.selected).length)
        itemAll.selected = true;
    }

    setItemCategories(itemsCopy);
    onItemsSelect(itemsCopy);
  };

  useEffect(() => {
    setItemCategories(items);
  }, [items]);

  return (
    <React.Fragment>
      <div>
        <span className="fw-light">Filter by:</span>
        <h5>{itemTypeProperties.categoriesTitle}</h5>

        <ul className="list-group" style={{ marginBottom: "5px" }}>
          <li
            className={`fw-light list-group-item list-group-item-action ${
              itemAll.selected && `active`
            }`}
            onClick={() => handleItemsSelect(itemAll)}
            style={{ cursor: "pointer" }}
          >
            {itemAll.name}
          </li>
        </ul>

        <ul className="list-group">
          {itemCategories.map((item) => (
            <li
              key={item._id}
              className={`fw-light list-group-item list-group-item-action ${
                item.selected && `active`
              }`}
              onClick={() => handleItemsSelect(item)}
              style={{ cursor: "pointer" }}
            >
              {item.name}
            </li>
          ))}
        </ul>
      </div>
    </React.Fragment>
  );
};

export default ListGroupMulti;
