import React, { useState, useEffect, useContext, useRef } from "react";
import { getArticleCategories } from "../../services/articleCategoryService";
import { getGenres } from "../../services/genreService";
import { useParams, useNavigate } from "react-router-dom";
import ItemMainForm from "../items/ItemMainForm";
import { UserContext } from "../userContext";
import Hero from "../hero";
import _ from "lodash";
import * as bootstrap from "bootstrap";
import { NavLink } from "react-router-dom";
import Resizer from "react-image-file-resizer";
import DateFormatter from "../common/dateFormat";

import {
  getContentItem,
  saveContent,
  updateContent,
  deleteContentMainImage,
} from "../../services/contentService";

const ItemFormPage = () => {
  const REACT_APP_SERVER_URL = `${process.env.REACT_APP_SERVER_URL}`;
  const { user, setUser } = useContext(UserContext);
  const { id: paramId } = useParams();
  const { item: typeParam } = useParams();
  const [item, setItem] = useState({});
  const [categories, setCategories] = useState([]);
  const [heroValues, setHeroValues] = useState({});
  const [lastEditDate, setLastEditDate] = useState("");
  const [contentTitle, setContentTitle] = useState("");
  const [mainImage, setMainImage] = useState("");
  const [activate, setActivate] = useState(false);
  const [id, setId] = useState("");
  const itemTypeProperties = [];

  const navigate = useNavigate();

  itemTypeProperties["articles"] = {
    getter: getContentItem,
    categoryLabel: "category",
    loader: async (getter) => {
      const item = await getter({ id: id, contentType: typeParam });
      item && setItem(item);
      item && setLastEditDate(item.updatedAt);
      item && setContentTitle(item.title);
      item && setActivate(item.activate);
      item && setMainImage(item.thumbImage);
    },
    getCategories: getArticleCategories,
    updater: updateContent,
    saver: saveContent,
    deleteMainImage: deleteContentMainImage,
  };

  itemTypeProperties["movies"] = {
    getter: getContentItem,
    categoryLabel: "genre",
    loader: async (getter) => {
      const item = await getter({ id: id, contentType: typeParam });
      item && setItem(item);
      item && setLastEditDate(item.updatedAt);
      item && setContentTitle(item.title);
      item && setActivate(item.activate);
      item && setMainImage(item.thumbImage);
    },
    getCategories: getGenres,
    updater: updateContent,
    saver: saveContent,
    deleteMainImage: deleteContentMainImage,
  };

  const itemType = itemTypeProperties[typeParam];
  const loadExternalItems = async () => itemType.loader(itemType.getter);
  const loadExternalCategories = async () => {
    const loadCategories = await itemType.getCategories();
    setCategories(loadCategories);
  };

  const initToast = () => {
    var toastElList = [].slice.call(document.querySelectorAll(".toast"));
    var toastList = toastElList.map(function (toastEl) {
      return new bootstrap.Toast(toastEl);
    });

    toastList.forEach((toast) => toast.show());
  };

  useEffect(() => {
    setId(paramId);
  }, [paramId]);

  useEffect(() => {
    id && loadExternalItems();
    loadExternalCategories();
  }, [id]);

  const processImage = async (files, maxWidth, maxHeight, format) => {
    try {
      const file = files[0];
      const image = await resizeFile(file, maxWidth, maxHeight, format);
      // setCompressed(image);
      return image;
    } catch (err) {
      console.log(err);
      return null;
    }
  };

  const resizeFile = (file, maxWidth, maxHeight, format) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        maxWidth,
        maxHeight,
        format,
        100,
        0,
        (uri) => {
          resolve(uri);
          // console.log("Returned URI", uri);
        },
        "file"
      );
    });

  const onError = (errors, e) =>
    console.log("**************************** Onerrors", errors, e);

  const onSubmit = async (data, e) => {
    let imageData = null;
    let thumbImageData = null;
    let twitterImageData = null;

    if (data.formFile.length) {
      imageData = await processImage(data.formFile, 1280, 720, "png");
      thumbImageData = await processImage(data.formFile, 300, 169, "png");
      twitterImageData = await processImage(data.formFile, 1280, 720, "png");
    }

    const itemx = {
      title: data.title,
      mainContent: data.mainContent,
      imageData: imageData,
      thumbImageData: thumbImageData,
      twitterImageData: twitterImageData,
      mainImage: mainImage ? mainImage : "",
      // mainImage: item.mainImage ? item.mainImage : "",
      activate: data.activate,
      contentType: typeParam,
      instagram: [data.instagram1, data.instagram2, data.instagram3],
      twitter: [data.twitter1, data.twitter2, data.twitter3],
    };

    if (typeParam === "articles") {
      itemx.category = categories.find((c) => c._id === data.category);
    }
    if (typeParam === "movies") {
      itemx.genre = categories.find((c) => c._id === data.category);
      itemx.dailyRentalRate = data.rate;
    }

    if (id) {
      //ADD ID TO DIFFERENTIATE BETWEEN UPDATE AND NEW ENTRY
      itemx._id = id;
      itemx.lastEditorId = user._id;
      const updatedData = await itemType.updater(itemx);

      setLastEditDate(updatedData.doc.updatedAt);
      setContentTitle(data.title);
      setActivate(data.activate);
      imageData && setMainImage(updatedData.newMainImage);
      updatedData.doc._id && initToast();
    } else {
      itemx.creatorId = user._id;
      const savedData = await itemType.saver(itemx);
      setId(savedData?._id);

      navigate(`/item-form-page/${typeParam}/${savedData?._id}`);
    }

    //CLEAR FILE FORM AFTER SUBMIT
    //CURRENTLY DIRECTLY CHANGING THE DOM AND NEED TO FIX
    if (imageData) e.target.formFile.value = "";
  };

  const heroEditValues = {
    title: `<span style="font-weight:600">Edit ${typeParam}:</span> ${contentTitle}`,
    bgClass: "bg-light",
    textClass: "text-dark",
    description: `Edit your content`,
    icon: "",
  };

  const heroCreateValues = {
    title: `<span style="font-weight:600">Create ${typeParam}</span>`,
    bgClass: "bg-light",
    textClass: "text-dark",
    description: `Create your content`,
    icon: "",
  };

  useEffect(() => {
    setHeroValues(item._id ? { ...heroEditValues } : { ...heroCreateValues });
  }, [item, contentTitle]);

  const handleDeleteImage = async () => {
    const data = await itemType.deleteMainImage({
      paramId: paramId,
      contentType: typeParam,
    });
    if (data.success === true) setMainImage("");
  };

  return (
    <React.Fragment>
      <div
        className="top-50 start-50 translate-middle p-3"
        style={{ zIndex: "11", position: "absolute" }}
      >
        <div
          id="liveToast"
          className="toast hide"
          role="alert"
          aria-live="assertive"
          aria-atomic="true"
        >
          <div className="toast-header">
            <strong className="me-auto"></strong>
            <small>{lastEditDate}</small>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="toast"
              aria-label="Close"
            ></button>
          </div>
          <div className="toast-body">Content successfully saved!</div>
        </div>
      </div>

      {!_.isEmpty(heroValues) ? (
        <Hero
          title={heroValues.title}
          bgClass1="layer2"
          bgClass2="layer3"
          textClass={heroValues.textClass}
          description={heroValues.description}
          icon={heroValues.icon}
        ></Hero>
      ) : (
        <div>Loading...</div>
      )}

      <div className="container">
        {id ? (
          item._id ? (
            <React.Fragment>
              <div className="row">
                <div className="col-sm-12 col-lg-4">
                  {mainImage && (
                    <div className="">
                      <div className="d-grid pb-5">
                        <img
                          src={`${REACT_APP_SERVER_URL}/content/${typeParam}/${item._id}/${mainImage}`}
                          style={{ width: "100%", borderRadius: "0" }}
                        />
                        <button
                          className="btn btn-danger btn-sm"
                          style={{ borderRadius: "0" }}
                          onClick={handleDeleteImage}
                        >
                          Delete Image
                        </button>
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-sm-12 col-lg-8">
                  <div className="d-flex flex-row  ">
                    <div className="p-2   ">
                      <h1 className="display-1">
                        <i
                          className="fa fa-podcast"
                          aria-hidden="true"
                          style={{
                            color: activate
                              ? "rgb(32, 201, 151)"
                              : "rgb(173, 181, 189)",
                          }}
                        ></i>
                      </h1>
                    </div>
                    <div className="p-2 ">
                      <div className="mb-4 small">
                        <div>
                          <span className="fw-bold">Creator:</span>{" "}
                          <span>
                            <NavLink
                              to={`/user-profile/${item.creatorId?._id}`}
                              style={{
                                textDecoration: "none",
                              }}
                            >
                              {item.creatorId?.username}
                            </NavLink>
                          </span>
                        </div>
                        <div>
                          <span className="fw-bold">Created Date:</span>{" "}
                          <span>
                            <DateFormatter date={item.createdAt} />
                          </span>
                        </div>
                        {item.createdAt !== item.updatedAt && (
                          <div>
                            <span className="fw-bold">Last Edit Date:</span>{" "}
                            {/* <span>{item.updatedAt}</span> */}
                            <span>
                              <DateFormatter date={lastEditDate} />
                            </span>
                          </div>
                        )}
                        <div>
                          <span>
                            <NavLink
                              to={`/${typeParam}/${item._id}`}
                              target="_blank"
                              style={{
                                fontSize: "1.2rem",
                                textDecoration: "none",
                                fontWeight: "bold",
                              }}
                            >
                              <i
                                className="fa fa-expand"
                                aria-hidden="true"
                              ></i>{" "}
                              View Content
                            </NavLink>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <ItemMainForm
                dataItem={item}
                dataCategories={categories}
                contentType={typeParam}
                onSubmit={onSubmit}
              />
            </React.Fragment>
          ) : (
            <div>Loading...</div>
          )
        ) : (
          <React.Fragment>
            <ItemMainForm
              dataCategories={categories}
              contentType={typeParam}
              onSubmit={onSubmit}
            />
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
};

export default ItemFormPage;
