import React, { useRef, useEffect } from "react";
import { convertStringToHtml } from "../utils/stringToHtml";

const Hero = ({
  title,
  bgClass,
  textClass,
  description,
  icon,
  main,
  children,
}) => {
  const itemContent = useRef();

  useEffect(() => {
    convertStringToHtml(title, itemContent);
  }, [title]);

  return (
    <React.Fragment>
      <div
        className={`container-fluid ${bgClass} ${textClass} hero-image pt-3 pt-lg-1 mb-4`}
        style={{ border: "0px solid green" }}
      >
        <div className="row" style={{ border: "0px solid green" }}>
          <div className="col">
            <div
              className={`d-flex justify-content-center flex-wrap main-title`}
            >
              <div className={`pb-2 ${!icon && "d-none"}`}>
                <i className={icon} aria-hidden="true"></i>
              </div>
              <div className="pb-2">
                <div
                  id="itemContent"
                  ref={itemContent}
                  className="container-fluid text-center"
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="text-center">
              <p className={`mb-4 main-sub-title`} style={{}}>
                {description}
              </p>

              <div className=" mx-auto" style={{ border: "0px solid green" }}>
                {children}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Hero;
