import React, { useState, useEffect } from "react";
import Tweet from "../comments/tweet";
import { useParams, useNavigate } from "react-router-dom";
import { getUser } from "../services/usersService";
import DateFormatter from "./common/dateFormat";
import Hero from "./hero";

const UserProfile = () => {
  const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;

  const { id } = useParams();
  const [user, setUser] = useState({});

  const setData = async () => {
    const data = await getUser(id);
    data?._id && setUser(data);
  };

  useEffect(() => {
    setData();
  }, []);

  useEffect(() => {
    console.log("User", user);
  }, [user]);

  const { _id, username, googlePicture: avatar } = user;

  return (
    <React.Fragment>
      {user._id ? (
        <>
          <Hero
            title={`${username}`}
            bgClass1="layer2"
            bgClass2="layer3"
            textClass="text-dark"
            description={``}
            icon={`fa fa-user text-secondary`}
          ></Hero>

          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-2 text-center">
                <img
                  src={
                    user.profileImage
                      ? `${REACT_APP_SERVER_URL}/users/${user._id}/profile-image/${user.profileImage}`
                      : user.googlePicture
                  }
                  alt=""
                  style={{ width: "100px" }}
                />
              </div>
              <div className="col-12 col-lg-10 text-start">
                <table className="table table-sm table-borderless small">
                  <thead>
                    <tr>
                      <th scope="col"></th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">Username:</th>
                      <td>{username}</td>
                    </tr>

                    <tr>
                      <th scope="row">Role:</th>
                      <td>
                        {user.roles?.map((role, i) => (
                          <div key={i}>{role}</div>
                        ))}
                      </td>
                    </tr>

                    <tr>
                      <th scope="row">Member Since:</th>

                      <td>
                        <DateFormatter date={user.createdAt} />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </>
      ) : (
        "loading..."
      )}
    </React.Fragment>
  );
};

export default UserProfile;
