import React from "react";

const Star = ({ currentState }) => {
  const setStarClass = () => {
    if (currentState === "on") return "fa fa-star";
    if (currentState === "off") return "fa fa-star-o";
    if (currentState === "half") return "fa fa-star-half-o";
  };

  return (
    <i
      style={{
        color: "gold",
        fontSize: "1rem",
        textShadow: "1px 1px 1px #576994",
      }}
      className={`${setStarClass()}`}
      aria-hidden="true"
    ></i>
  );
};

export default Star;
