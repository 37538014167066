const REACT_APP_SERVER_URL = `${process.env.REACT_APP_SERVER_URL}/api/v1`;

export async function getUser(id) {
  const URL = `${REACT_APP_SERVER_URL}/users/${id}`;
  try {
    const response = await fetch(URL);
    const data = await response.json();
    return data;
  } catch (err) {
    console.log("Error:", err);
  }
  return null;
}

export async function getUsers() {
  const URL = `${REACT_APP_SERVER_URL}/users`;
  try {
    const response = await fetch(URL);
    const data = await response.json();
    return data;
  } catch (err) {
    console.log("Error:", err);
  }
  return null;
}

export async function updateUsername(id, newUsername) {
  const username = { _id: id, username: newUsername };

  const URL = `${REACT_APP_SERVER_URL}/users/update-username`;
  try {
    const response = await fetch(URL, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(username),
    });
    const data = await response.json();
    return data;
  } catch (err) {
    console.log("Error:", err);
  }
  return null;
}

export async function deactivateUserAccount(id) {
  console.log("fetch  deactivateUserAccount", id);

  const URL = `${REACT_APP_SERVER_URL}/users-account/deactivate-user-account`;

  try {
    const response = await fetch(URL, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id }),
    });
    const data = await response.json();
    return data;
  } catch (err) {
    console.log("Error:", err);
  }
  return null;
}

export async function requestAdminAccount(id) {
  console.log("fetch  deactivateUserAccount", id);

  const URL = `${REACT_APP_SERVER_URL}/users-account/request-admin-account`;

  try {
    const response = await fetch(URL, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id }),
    });
    const data = await response.json();
    return data;
  } catch (err) {
    console.log("Error:", err);
  }
  return null;
}
