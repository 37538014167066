import React from "react";

const ProfileImage = ({ user, width }) => {
  const REACT_APP_SERVER_URL = `${process.env.REACT_APP_SERVER_URL}`;

  const profileImagePath = user.profileImage
    ? `${REACT_APP_SERVER_URL}/users/${user._id}/profile-image/${user.profileImage}`
    : `${process.env.PUBLIC_URL}/images/noprofileimage.jpg`;

  return (
    <img
      src={`${profileImagePath}`}
      alt=""
      style={{ borderRadius: "", width: width }}
    />
  );
};

export default ProfileImage;
