import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { getNavItemByMainTitle } from "../../navBar/navBarJson";
import _ from "lodash";

const HelmetNavItems = ({ mainTitle, to }) => {
  const [navItem, setNavItem] = useState({});

  const getNavItem = async () => {
    const navItem = await getNavItemByMainTitle(mainTitle, to);
    setNavItem(navItem);
  };

  useEffect(() => {
    getNavItem();
  }, [to]);

  if (_.isEmpty(navItem)) return null;

  return (
    <>
      <Helmet>
        <title>{navItem.title}</title>
        <meta name="og:title" content={navItem.title} data-rh="true" />
        <meta
          name="og:description"
          content={navItem.linkDescription}
          data-rh="true"
        />
      </Helmet>
    </>
  );
};

export default HelmetNavItems;
