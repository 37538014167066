import React from "react";
import { DateTime } from "luxon";

const DateFormatter = ({ date }) => {
  const FormattedDate = ({ date }) => {
    // Format the date as a string in the format "Month Day, Year"
    const formattedDate = DateTime.fromISO(date).toFormat("LLL dd, yyyy");

    return formattedDate;
  };

  const FormattedTime = ({ date }) => {
    // Format the date as a string in the format "Hour:Minute AM/PM"
    const formattedTime = DateTime.fromISO(date).toFormat("h:mma");

    return formattedTime;
  };

  return (
    <div>
      <FormattedDate date={date} /> <FormattedTime date={date} />
    </div>
  );
};

export default DateFormatter;
