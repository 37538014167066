export const hpHouse = [
  { _id: "1", name: "Gryffindor", logo: "" },
  { _id: "2", name: "Slytherin", logo: "" },
  { _id: "3", name: "Hufflepuff", logo: "" },
  { _id: "4", name: "Ravenclaw", logo: "" },
  { _id: "999", name: "None", logo: "" },
];

export function getHarryPotterHouse() {
  return hpHouse;
}
