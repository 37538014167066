const REACT_APP_SERVER_URL = `${process.env.REACT_APP_SERVER_URL}/api/v1`;

export async function getArticleCategories() {
  let items = [];
  const URL = `${REACT_APP_SERVER_URL}/article-categories`;

  try {
    const response = await fetch(URL);
    const data = await response.json();

    items = [...data];
  } catch (err) {
    console.log("Error:", err);
  }

  return items.filter((g) => g);
}
