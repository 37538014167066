import React, { useContext } from "react";
import { UserContext } from "../../components/userContext";

export function DisplayLogInButton({ classNames, width }) {
  const { user, setUser } = useContext(UserContext);
  if (user._id) return null;
  return (
    <button
      type="button"
      className={`btn ${classNames} me-2`}
      data-bs-toggle="modal"
      data-bs-target="#imm-modal-login"
      style={{ width: width ? width : "" }}
    >
      Sign In <i className="fa fa-sign-in" aria-hidden="true"></i>
    </button>
  );
}

export function DisplayLogOutButton({ classNames, width }) {
  const { user, setUser } = useContext(UserContext);
  if (!user._id) return null;
  return (
    <button
      type="button"
      className={`btn ${classNames} me-2`}
      // className="btn btn-sm btn-warning me-2"
      onClick={() =>
        window.open(`${process.env.REACT_APP_SERVER_URL}/auth/logout`, "_self")
      }
      style={{ width: width ? width : "" }}
    >
      Sign Out <i className="fa fa-sign-out" aria-hidden="true"></i>
    </button>
  );
}

export function DisplayFacebookButton({ classNames, width }) {
  const { user, setUser } = useContext(UserContext);
  if (user._id) return null;
  return (
    <button
      className="btn btn-danger me-2"
      style={{
        backgroundColor: "#517cc1",
        border: "1px solid #517cc1",
      }}
      onClick={() =>
        window.open(
          `${process.env.REACT_APP_SERVER_URL}/auth/facebook`,
          "_self"
        )
      }
    >
      <i className="fa fa-facebook-official" aria-hidden="true"></i> Facebook
    </button>
  );
}

export function DisplayGoogleButton({ classNames, width }) {
  const { user, setUser } = useContext(UserContext);
  if (user._id) return null;
  return (
    <button
      className="btn btn-danger me-2"
      onClick={() =>
        window.open(
          `${process.env.REACT_APP_SERVER_URL}/auth/google?site=site1`,
          "_self"
        )
      }
    >
      <i className="fa fa-google" aria-hidden="true"></i> Google
    </button>
  );
}

export function DisplayContactButton({ classNames, width }) {
  return (
    <button
      className={`btn ${classNames}`}
      type="button"
      data-bs-toggle="modal"
      data-bs-target="#imm-modal-contact"
    >
      <i className="fa fa-envelope-o" aria-hidden="true"></i> Contact
    </button>
  );
}
