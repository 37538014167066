import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { NavLink } from "react-router-dom";

const Pagination = ({ itemsCount, pageSize, currentPage, onPageChange }) => {
  const pagesCount = Math.ceil(itemsCount / pageSize);

  if (pagesCount === 1) return null;

  const pages = _.range(1, pagesCount + 1);

  // console.log("itemsCount", itemsCount);
  // console.log("pageSize", pageSize);
  // console.log("currentPage", currentPage);
  // console.log("onPageChange", onPageChange);
  // console.log("pagesCount", pagesCount);
  // console.log("pages", pages);

  return (
    <React.Fragment>
      <nav aria-label="Page navigation">
        <ul className="pagination">
          {pages.map((page) => (
            <li
              key={page}
              className={
                currentPage === page ? "page-item active" : "page-item"
              }
              onClick={() => onPageChange(page)}
            >
              <NavLink className="page-link" to="#">
                {page}
              </NavLink>
            </li>
          ))}
        </ul>
      </nav>
    </React.Fragment>
  );
};

Pagination.propTypes = {
  itemsCount: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default Pagination;
