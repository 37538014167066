import React, { useState, useEffect, useContext, useRef } from "react";
import { UserContext } from "./userContext";
import Hero from "./hero";
import HeroLandingPage from "./heroLandingPage";
import { NavLink, useSearchParams, useParams } from "react-router-dom";
import NavCardItems from "./common/NavCardItems";
import HelmetNavItems from "./common/helmet/helmetNavItems";

import MyIntro from "./myIntro";

import { DisplayLogInButton } from "./common/buttons";

const Home = () => {
  const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;

  const { user, setUser } = useContext(UserContext);
  const [mousePosition, setMousePosition] = useState({ left: 0, top: 0 });
  const [opacityPercent, setOpacityPercent] = useState(0);

  const homeImmLogo = useRef();
  const homeHero = useRef();
  const mainContentOne = useRef();
  const mainSpacerOne = useRef();
  const mainSpacerIntro = useRef();
  const mainSignInInfo = useRef();
  const { mode } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  // useEffect(() => {
  //   console.log("user", user);
  //   console.log("searchParams", searchParams);
  //   console.log(
  //     "sessionStorage.getItem('redirect');",
  //     sessionStorage.getItem("redirect")
  //   );
  // }, [mode]);

  const handleMouseMove = (e) => {
    const { pageX, pageY } = e;

    setMousePosition({ left: pageX, top: pageY });

    const windowWidth = window.innerWidth / 10;
    const leftStart = windowWidth / 2;
    let distanceFromCenterLeft = 0;
    let distanceFromCenterRight = 0;

    if (pageX / 10 >= leftStart) {
      distanceFromCenterLeft = pageX / 10 - leftStart;
      setOpacityPercent((leftStart - distanceFromCenterLeft) / leftStart);
    } else {
      distanceFromCenterRight = leftStart - pageX / 10;
      setOpacityPercent((leftStart - distanceFromCenterRight) / leftStart);
    }
  };

  const handleScroll = (e) => {
    const bs_bg = "bg-light";
    const nav = document.querySelector("nav");
    nav.classList.remove(bs_bg);
    window.addEventListener("scroll", (e) => {
      if (window.pageYOffset > 300) {
        nav.classList.add(bs_bg);
      } else {
        nav.classList.remove(bs_bg);
      }
    });
  };

  const handleScrollIntoView = (section) => {
    section.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <React.Fragment>
      {!user?._id && (
        <section
          className="main-hero"
          ref={homeHero}
          style={{ border: "0px solid red" }}
        >
          <div
            style={{
              border: "0px solid white",
              position: "relative",
              // top: "30%",
            }}
            className=" main-hero-container"
          >
            {mode && (
              <div className="d-flex justify-content-center">
                {mode === "1" && (
                  <div
                    className="alert alert-info text-center alert-login"
                    role="alert"
                  >
                    <span style={{ fontSize: "2rem" }}>
                      <i className="fa fa-hand-paper-o" aria-hidden="true"></i>{" "}
                      You must be logged in to perform that action.
                    </span>{" "}
                    <br />
                    You can log in using your Google or Facebook account.
                    <br />
                    Please use the social media sign-in option provided below.
                    <br />
                  </div>
                )}
                {mode === "2" && (
                  <div
                    className="alert alert-info text-center alert-login"
                    role="alert"
                  >
                    <span style={{ fontSize: "2rem" }}>
                      <i className="fa fa-hand-paper-o" aria-hidden="true"></i>{" "}
                      You must have administrator privileges to perform that
                      action.
                    </span>{" "}
                    <br />
                    If you're interested in creating content on imassmedia.com,
                    please contact me using the contact option provided below.
                    <br />
                  </div>
                )}
              </div>
            )}

            <HeroLandingPage
              title={`<span style="font-weight:300">Interactive <span style="font-weight:bold; color:#62BEC1">imassmedia</span> platform</span>`}
              bgClass=""
              textClass="text-light"
              description={`Use your Google or Facebook account to sign in`}
              icon={``}
              main="true"
            >
              <div className="">
                <div className="" style={{ border: "0px solid red" }}>
                  {user?._id ? (
                    <button
                      type="button"
                      className="btn btn-warning me-2 main-hero-btns"
                      style={{
                        backgroundColor: "#586994",
                      }}
                      onClick={() =>
                        window.open(
                          `${REACT_APP_SERVER_URL}/auth/logout`,
                          "_self"
                        )
                      }
                    >
                      Sign Out{" "}
                      <i className="fa fa-sign-out" aria-hidden="true"></i>
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-dark me-2 main-hero-btns"
                      style={{
                        backgroundColor: "#62BEC1",
                      }}
                      data-bs-toggle="modal"
                      data-bs-target="#imm-modal-login"
                    >
                      Social Media Login{" "}
                      <i className="fa fa-sign-in" aria-hidden="true"></i>
                    </button>
                  )}
                  <button
                    className="btn btn-dark main-hero-btns"
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#imm-modal-contact"
                    style={{
                      backgroundColor: "#586994",
                    }}
                  >
                    <i className="fa fa-envelope-o" aria-hidden="true"></i>{" "}
                    Contact me
                  </button>
                </div>
                <div className="text-center" style={{ marginTop: "" }}>
                  <button
                    className="btn btn-dark"
                    onClick={() => handleScrollIntoView(mainSignInInfo)}
                  >
                    <i className="fa fa-chevron-down" aria-hidden="true"></i>{" "}
                  </button>
                </div>
              </div>
            </HeroLandingPage>
          </div>
        </section>
      )}

      {!!!user._id && (
        <section
          className="hero-image-spacer"
          style={{
            height: "100vh",
            border: "0px solid red",
          }}
          ref={mainSignInInfo}
        >
          <div>
            <div
              ref={mainSpacerIntro}
              style={{
                color: "#000",
                border: "0px solid green",
              }}
              className={`  ${user?._id ? "d-none" : ""}`}
            >
              <div
                style={{
                  border: "0px solid red",
                  paddingTop: "80px",
                  backgroundColor: "#000",
                }}
              ></div>
              <div
                style={{
                  border: "0px solid red",
                }}
                className="home-spacer layer4"
              ></div>
              {/* <Hero
                title={`<i class="fa fa-user-plus" aria-hidden="true" style="color:#000"></i> <span style="font-weight:300; color:#000">Sign in with </span><nobr><span style="font-weight:bold; color:#e63b2c"> Google</span> or <span style="font-weight:bold; color:#517cc1"> Facebook</span><nobr><br />`}
                bgClass1=""
                bgClass2=""
                textClass="text-dark"
                description={`By signing into the website with your Facebook or Google account, you can create a personalized account that enables you to share your movie reviews and articles with the community. Additionally, you can engage in discussions by commenting on other users' posted content and participate in real-time conversations in our chat room.`}
                icon={``}
                main="false"
              ></Hero> */}
              <div className=" home-signin-bg mt-5" style={{ height: "100vh" }}>
                <div className="container">
                  <div className="row">
                    <div className="col-12 col-lg-4">
                      {/* <img src={`images/profile-vector.svg`} width="200px" /> */}
                    </div>
                    <div className="col-12 col-lg-8">
                      <div className="fs-1 fw-light mb-3">
                        Sign in with{" "}
                        <span style={{ fontWeight: "bold", color: "#e63b2c" }}>
                          {" "}
                          Google
                        </span>{" "}
                        or
                        <span style={{ fontWeight: "bold", color: "#517cc1" }}>
                          {" "}
                          Facebook
                        </span>{" "}
                      </div>
                      <div className="fs-5 fw-light mb-5">
                        By signing into the website with your Facebook or Google
                        account, you can create a personalized account that
                        enables you to share your movie reviews and articles
                        with the community. Additionally, you can engage in
                        discussions by commenting on other users' posted content
                        and participate in real-time conversations in our chat
                        room.
                      </div>
                      <DisplayLogInButton classNames={"btn-outline-dark"} />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-lg-4">
                      {/* <img src={`images/text-vector.svg`} width="200px" /> */}
                    </div>

                    <div className="col-12 col-lg-8">
                      <div
                        ref={mainSpacerIntro}
                        style={{ paddingTop: "50px", color: "#000" }}
                        className={`text-center  ${user?._id ? "d-none" : ""}`}
                      ></div>
                      <div>
                        <div className="fs-1 fw-light ">
                          Become a site{" "}
                          <span className="fw-bold fs-1"> administrator</span>{" "}
                        </div>
                        <div className="fs-5 fw-light">
                          As a site administrator, you will have privileges to
                          create and edit content on this platform. To gain
                          access to these privileges, simply submit a request
                          for approval.
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col">
                      <div className="text-center">
                        <button
                          className="btn btn-light"
                          onClick={() => handleScrollIntoView(mainSpacerIntro)}
                        >
                          <i
                            className="fa fa-chevron-down"
                            aria-hidden="true"
                          ></i>{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      <section
        className="hero-image-intro"
        style={{
          height: "100vh",
          border: "0px solid red",
          backgroundColor: "#000",
        }}
        ref={mainSpacerIntro}
      >
        <div className="hero-text">
          {!!!user._id && (
            <div
              style={{
                border: "0px solid red",
                transform: "rotate(180deg)",
              }}
              className="home-spacer layer4"
            ></div>
          )}
          <Hero
            title={`<span style="font-weight:300; color:#fff">Welcome to my </span><span style="font-weight:bold; color:#fff">digital haven.</span>`}
            bgClass1=""
            bgClass2=""
            textClass="text-dark"
            description={``}
            icon={``}
            main="false"
          ></Hero>
          <MyIntro />
        </div>

        <div className="text-center">
          <button
            className="btn btn-dark"
            onClick={() => handleScrollIntoView(mainContentOne)}
          >
            <i className="fa fa-chevron-down" aria-hidden="true"></i>{" "}
          </button>
        </div>
      </section>

      <section
        className="main-content-one"
        id="main-content-one"
        style={{ border: "0px solid green" }}
      >
        <div
          ref={mainSpacerOne}
          style={{ paddingTop: "0px", backgroundColor: "#ffffff" }}
          className={`${user?._id ? "d-none" : ""}`}
        ></div>

        {mode && (
          <div className="d-flex justify-content-center">
            {mode === "2" && (
              <div
                className="alert alert-info text-center alert-login"
                role="alert"
              >
                <span style={{ fontSize: "2rem" }}>
                  <i className="fa fa-hand-paper-o" aria-hidden="true"></i> You
                  must have administrator privileges to perform that action.
                </span>{" "}
                <br />
                If you're interested in creating content on imassmedia.com,
                please contact me using the contact option provided below.
                <br />
              </div>
            )}
          </div>
        )}
      </section>

      <section ref={mainContentOne}>
        <div
          style={{
            border: "0px solid red",
            paddingTop: "0px",
            backgroundColor: "#000",
          }}
        ></div>
        <div
          style={{
            paddingTop: user?._id ? `0px` : `0px`,
            border: "0px solid red",
          }}
          className="home-spacer layer4"
        ></div>

        <Hero
          title={`<span style="font-weight:300; color:#576994">Find your place in my</span>
          <span style="font-weight:bold; color:#576994"> interactive world!</span>`}
          bgClass1=""
          bgClass2=""
          textClass="text-dark"
          description={`Be part of the evolution of this site and share your vision. `}
          icon={``}
          main="false"
        ></Hero>
      </section>

      <HelmetNavItems mainTitle="Home" to="/" />
    </React.Fragment>
  );
};

export default Home;
