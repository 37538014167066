const playerList = [
  {
    playerName: "Harry Potter",
    spells: 3,
    lifeBoosts: 3,
    strikeStrength: 7,
    name: "Harry Potter",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: "Gryffindor",
    dateOfBirth: "31-07-1980",
    yearOfBirth: 1980,
    wizard: true,
    ancestry: "half-blood",
    eyeColour: "green",
    hairColour: "black",
    wand: {
      wood: "holly",
      core: "phoenix feather",
      length: 11,
    },
    patronus: "stag",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "Daniel Radcliffe",
    alternate_actors: [],
    alive: true,
    image: "https://hp-api.herokuapp.com/images/harry.jpg",
    _id: "3edd8d20-25cd-4638-80fa-812193dae47e",
  },
  {
    spells: 5,
    lifeBoosts: 3,
    strikeStrength: 4,
    playerName: "Hermione Granger",
    name: "Hermione Granger",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: "Gryffindor",
    dateOfBirth: "19-09-1979",
    yearOfBirth: 1979,
    wizard: true,
    ancestry: "muggleborn",
    eyeColour: "brown",
    hairColour: "brown",
    wand: {
      wood: "vine",
      core: "dragon heartstring",
      length: null,
    },
    patronus: "otter",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "Emma Watson",
    alternate_actors: [],
    alive: true,
    image: "https://hp-api.herokuapp.com/images/hermione.jpeg",
    _id: "bed8cc6b-76c2-43f4-895d-a0dd960d8e3f",
  },
  {
    spells: 2,
    lifeBoosts: 3,
    strikeStrength: 10,
    playerName: "Draco Malfoy",
    name: "Draco Malfoy",
    alternate_names: [],
    species: "human",
    gender: "male",
    house: "Slytherin",
    dateOfBirth: "05-06-1980",
    yearOfBirth: 1980,
    wizard: true,
    ancestry: "pure-blood",
    eyeColour: "grey",
    hairColour: "blonde",
    wand: {
      wood: "hawthorn",
      core: "unicorn tail-hair",
      length: 10,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "Tom Felton",
    alternate_actors: [],
    alive: true,
    image: "https://hp-api.herokuapp.com/images/draco.jpg",
    _id: "672987dd-eb10-470f-bf5b-f707233d4878",
  },

  {
    spells: 6,
    lifeBoosts: 3,
    strikeStrength: 10,
    playerName: "Queen Cersei",
    name: "Queen Cersei",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: "Slytherin",
    dateOfBirth: "05-06-1980",
    yearOfBirth: 1980,
    wizard: true,
    ancestry: "pure-blood",
    eyeColour: "grey",
    hairColour: "blonde",
    wand: {
      wood: "hawthorn",
      core: "unicorn tail-hair",
      length: 10,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "Tom Felton",
    alternate_actors: [],
    alive: true,
    image: "http://localhost:3000/images/queen.jpg",
    _id: "672987dd-eb10-470f-bf5b-f707233d48x1",
  },
  {
    spells: 6,
    lifeBoosts: 3,
    strikeStrength: 10,
    playerName: "Rose",
    name: "Rose",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: "Slytherin",
    dateOfBirth: "05-06-1980",
    yearOfBirth: 1980,
    wizard: true,
    ancestry: "pure-blood",
    eyeColour: "grey",
    hairColour: "blonde",
    wand: {
      wood: "hawthorn",
      core: "unicorn tail-hair",
      length: 10,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "Tom Felton",
    alternate_actors: [],
    alive: true,
    image: "http://localhost:3000/images/Rose-YSL-Blackpink.jpg",
    _id: "672987dd-eb10-470f-bf5b-f707233d48x2",
  },

  {
    spells: 6,
    lifeBoosts: 3,
    strikeStrength: 10,
    playerName: "Drake",
    name: "Drake",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: "Slytherin",
    dateOfBirth: "05-06-1980",
    yearOfBirth: 1980,
    wizard: true,
    ancestry: "pure-blood",
    eyeColour: "grey",
    hairColour: "blonde",
    wand: {
      wood: "hawthorn",
      core: "unicorn tail-hair",
      length: 10,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "Tom Felton",
    alternate_actors: [],
    alive: true,
    image: "http://localhost:3000/images/drake.jpg",
    _id: "672987dd-eb10-470f-bf5b-f707233d48x3",
  },
  {
    spells: 6,
    strikeStrength: 10,
    lifeBoosts: 3,
    playerName: "Blippi",
    name: "Blippi",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: "Slytherin",
    dateOfBirth: "05-06-1980",
    yearOfBirth: 1980,
    wizard: true,
    ancestry: "pure-blood",
    eyeColour: "grey",
    hairColour: "blonde",
    wand: {
      wood: "hawthorn",
      core: "unicorn tail-hair",
      length: 10,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "Tom Felton",
    alternate_actors: [],
    alive: true,
    image: "http://localhost:3000/images/blippi.jpg",
    _id: "672987dd-eb10-470f-bf5b-f707233d48x6",
  },
  {
    spells: 6,
    lifeBoosts: 3,
    strikeStrength: 10,
    playerName: "Tyrion Lannister",
    name: "Tyrion Lannister",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: "Slytherin",
    dateOfBirth: "05-06-1980",
    yearOfBirth: 1980,
    wizard: true,
    ancestry: "pure-blood",
    eyeColour: "grey",
    hairColour: "blonde",
    wand: {
      wood: "hawthorn",
      core: "unicorn tail-hair",
      length: 10,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "Tom Felton",
    alternate_actors: [],
    alive: true,
    image: "http://localhost:3000/images/TyrionLannister.jpg",
    _id: "672987dd-eb10-470f-bf5b-f707233d48x4",
  },

  {
    spells: 6,
    lifeBoosts: 3,
    strikeStrength: 10,
    playerName: "Ragnar Lothbrok",
    name: "Ragnar Lothbrok",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: "Slytherin",
    dateOfBirth: "05-06-1980",
    yearOfBirth: 1980,
    wizard: true,
    ancestry: "pure-blood",
    eyeColour: "grey",
    hairColour: "blonde",
    wand: {
      wood: "hawthorn",
      core: "unicorn tail-hair",
      length: 10,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "Tom Felton",
    alternate_actors: [],
    alive: true,
    image: "http://localhost:3000/images/RagnarLothbrok.jpg",
    _id: "672987dd-eb10-470f-bf5b-f707233d48x5",
  },

  {
    spells: 6,
    strikeStrength: 10,
    lifeBoosts: 3,
    playerName: "Elmo",
    name: "Elmo",
    alternate_names: [],
    species: "human",
    gender: "female",
    house: "Slytherin",
    dateOfBirth: "05-06-1980",
    yearOfBirth: 1980,
    wizard: true,
    ancestry: "pure-blood",
    eyeColour: "grey",
    hairColour: "blonde",
    wand: {
      wood: "hawthorn",
      core: "unicorn tail-hair",
      length: 10,
    },
    patronus: "",
    hogwartsStudent: true,
    hogwartsStaff: false,
    actor: "Tom Felton",
    alternate_actors: [],
    alive: true,
    image: "http://localhost:3000/images/elmo.jpg",
    _id: "672987dd-eb10-470f-bf5b-f707233d48x8",
  },
];

export async function getPlayers() {
  return playerList;
}
