import React from "react";
import StarRating from "../common/starRating/starRating";
import { Link } from "react-router-dom";
import Card from "../common/card";
import UserCard from "../common/UserCard";
import DateFormatter from "../common/dateFormat";

const CardContent = ({ content, itemType }) => {
  const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;

  const Category = () =>
    itemType === "movies" ? content.genre?.name : content.category?.name;

  const ContentImage = () => {
    return (
      <img
        src={
          content.mainImage
            ? `${REACT_APP_SERVER_URL}/content/${itemType}/${content._id}/${content.thumbImage}`
            : `${process.env.PUBLIC_URL}/images/orientamento-mmm-webpage-1.jpg`
        }
        className="card-img-top"
        style={{ cursor: "pointer" }}
      />
    );
  };

  const ContentHyperLink = ({ children }) => {
    return (
      <Link
        to={`/${itemType}/${content._id}`}
        // to={`/${itemType}/${content.slug}`}
        style={{
          textDecoration: "none",
          height: "110px",
          fontSize: ".8rem",
          letterSpacing: "1px",
          cursor: "pointer",
          color: "#586994",
          fontWeight: "800",
          // textShadow: "1px 1px 1px #576994",
        }}
        className="link-dark  "
      >
        <p className="lh-sm">{children}</p>
      </Link>
    );
  };

  return (
    <div>
      <Card corners="rounded-3">
        <ContentHyperLink>
          <ContentImage />
        </ContentHyperLink>

        <div
          className=""
          style={{ padding: "10px", paddingTop: "5px", paddingBottom: "5px" }}
        >
          <div
            className="d-flex flex-row text-uppercase"
            style={{ height: "60px" }}
          >
            <div className="">
              <ContentHyperLink>{content.title}</ContentHyperLink>
            </div>
          </div>
          <div className="d-flex flex-row">
            <StarRating rating={content.dailyRentalRate} />
          </div>
          <div
            className="d-flex flex-row text-secondary  fw-bold text-uppercase"
            style={{
              border: "0px solid #eee",
              fontSize: "0.6rem",
            }}
          >
            <Category />
          </div>
          <div
            className=" text-secondary  fw-bold text-uppercase"
            style={{ fontSize: "0.6rem" }}
          >
            <DateFormatter date={content.createdAt} />
          </div>
        </div>
      </Card>
    </div>
  );
};
export default CardContent;
