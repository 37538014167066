import React from "react";
import Card from "../common/card";

const CardHarryPotter = ({ content }) => {
  const HPHouseImage = ({ image, house, name }) => {
    return (
      <img
        src={image ? image : `/images/${house}.jpg`}
        alt={name}
        className="card-img-top-hp"
      />
    );
  };

  return (
    <Card>
      <HPHouseImage
        image={content.image}
        house={content?.house?.name}
        name={content.name}
      />
      <div className="">
        <div className="card-body p-2">
          <div className="d-flex flex-row align-items-center">
            {content?.house?.name !== "None" && (
              <div className="p-1 ">
                <img
                  src={`/images/${content?.house?.name}.jpg`}
                  alt={content?.house?.name}
                  width="30px"
                />
              </div>
            )}
            <div className="">
              <h5 className="card-title mb-0" style={{ fontSize: "1rem" }}>
                {content.name}
              </h5>
            </div>
          </div>

          <div className="card-text">
            {content?.house?.name !== "None" ? (
              <div>
                <span>House: </span>
                <span className="fw-light">{content?.house?.name}</span>
              </div>
            ) : (
              ""
            )}

            {content.actor ? (
              <div>
                <span>Played by: </span>
                <span className="fw-light">{content.actor}</span>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </Card>
  );
};
export default CardHarryPotter;
