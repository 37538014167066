import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "./userContext";

import { Link } from "react-router-dom";
// import { DateTime } from "luxon";
import TBSModal from "./common/modalContact";

import { useForm } from "react-hook-form";

import { postContact } from "../services/sendMailService";

import { DisplayContactButton } from "../components/common/buttons";

const Footer = () => {
  const techStackImages = [
    {
      src: "icons8-mongodb-a-cross-platform-document-oriented-database-program-48.png",
      alt: "MongoDB",
    },
    {
      src: "icons8-express-js-100.png",
      alt: "ExpressJS",
    },
    {
      src: "icons8-react-native-96.png",
      alt: "ReactJS",
    },
    {
      src: "icons8-node-js-96.png",
      alt: "NodeJS",
    },
    {
      src: "icons8-bootstrap-96.png",
      alt: "Bootstrap",
    },
    {
      src: "passportjs_logo.svg",
      alt: "PassportJS",
      height: "16px",
    },

    {
      src: "icons8-digitalocean-100.png",
      alt: "DigitalOcean",
    },
    {
      src: "socketio_logo.svg",
      alt: "Socket.io",
    },
    {
      src: "icons8-google-96.png",
      alt: "Google",
    },

    {
      src: "icons8-facebook-96.png",
      alt: "Facebook",
    },

    {
      src: "chatgpt-logo.png",
      alt: "Chat GPT",
    },
  ];

  const { user, setUser } = useContext(UserContext);

  const [showContactSuccessMessage, setShowContactSuccessMessage] =
    useState(false);

  const TechstackImage = ({ src, alt, width = "20px", height }) => {
    return (
      <span style={{ color: "white" }}>
        <img
          src={`/${src}`}
          // src={`${process.env.PUBLIC_URL}/${src}`}
          alt={alt}
          style={{ width: width, height: height, marginLeft: "10px" }}
        />
      </span>
    );
  };

  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    trigger,
    setValue,
    getValues,
    reset,
  } = useForm({});

  const onSubmit = async (data) => {
    setShowContactSuccessMessage(false);

    data.userId = null;
    if (user._id) data.userId = user._id;
    const contact = await postContact(data);

    //CLEAR FORM IF RETURNED NEW RECORD ID
    if (contact._id) {
      reset();
      setShowContactSuccessMessage(true);
    }
  };

  useEffect(() => {
    setShowContactSuccessMessage(false);
  }, [errors.email, errors.messageContent]);

  return (
    <React.Fragment>
      <TBSModal title="Contact">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Email address
              </label>
              <input
                {...register("email", {
                  required: "Please provide an email.",
                })}
                type="email"
                className="form-control form-control-sm"
                id="email"
                placeholder="youremail@example.com"
              />
              <div id="emailHelp" className="form-text">
                {errors.email?.type === "required" && (
                  <p role="alert" className="text-danger">
                    {errors.email.message}
                  </p>
                )}
              </div>
            </div>

            <div className="mb-3">
              <label htmlFor="message" className="form-label">
                Message
              </label>
              <textarea
                {...register("messageContent", {
                  required: "Please include a message.",
                })}
                className="form-control form-control-sm"
                id="message"
                placeholder="Your message..."
                rows="3"
              ></textarea>
              <div id="messageContentHelp" className="form-text">
                {errors.messageContent?.type === "required" && (
                  <p role="alert" className="text-danger">
                    {errors.messageContent.message}
                  </p>
                )}
              </div>
            </div>

            <div className="col-auto">
              <button type="submit" className="btn btn-sm btn-primary mb-3">
                Send
              </button>
            </div>

            <div className="col-auto">
              {showContactSuccessMessage && (
                <div className="alert alert-success" role="alert">
                  Thank you for your message. I will review and get back to you
                  shortly.
                </div>
              )}
            </div>
          </div>
        </form>
      </TBSModal>

      <footer>
        <div
          style={{ paddingTop: "0" }}
          className={`spacer layer-footer`}
        ></div>

        <div
          className="px-3 py-2"
          style={{ border: "0px red solid", backgroundColor: "#000000" }}
        >
          <div className="row">
            <div
              className="col text-start text-muted "
              style={{ border: "0px solid red", fontSize: ".8rem" }}
            >
              <div>
                <Link className="navbar-brand" to="/">
                  <img
                    src={
                      process.env.PUBLIC_URL + "/images/logo_concept1_png.png"
                    }
                    style={{ width: "100px" }}
                  />
                </Link>
              </div>
            </div>
            <div className="col " style={{ border: "0px red solid" }}>
              <div
                style={{ border: "0px blue solid" }}
                className="text-center"
              ></div>
            </div>
            <div className="col ">
              <div className=" text-end">
                <DisplayContactButton classNames={`btn-outline-light btn-sm`} />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col"></div>
            <div className="col"></div>
            <div className="col mt-3">
              <div className="text-end">
                {techStackImages.map((img) => (
                  <TechstackImage
                    key={img.src}
                    src={`images/${img.src}`}
                    alt={img.alt}
                    height={img.height ? img.height : ""}
                  />
                ))}
              </div>
            </div>
          </div>

          <div
            className="row fw-light"
            style={{ color: "#EEEEEE", fontSize: ".7rem" }}
          >
            <div className="col">
              {/* About
              <br />
              Support
              <br />
              Report Bugs
              <br />
              Terms and Conditions
              <br /> */}
              <Link className="navbar-brand" to="/legal/privacypolicy">
                Privacy Policy
              </Link>
            </div>
            <div className="col"></div>
            <div className="col mt-3 text-end">
              All Rights Reserved &copy; imassmedia.com
            </div>
          </div>
        </div>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
