import React, { useState, useEffect } from "react";

function GoogleRSS() {
  const [feedItems, setFeedItems] = useState([]);

  useEffect(() => {
    fetch("https://rss.app/feeds/FgMEIVfzOhFN7Ewi.xml")
      .then((response) => response.text())
      .then((str) => new window.DOMParser().parseFromString(str, "text/xml"))
      .then((data) => {
        const items = data.querySelectorAll("item");
        const feedItems = Array.from(items).map((item) => ({
          title: item.querySelector("title").textContent,
          link: item.querySelector("link").textContent,
          description: item.querySelector("description").textContent,
        }));
        setFeedItems(feedItems);
      });
  }, []);

  return (
    <div>
      {feedItems.map((item) => (
        <div key={item.link}>
          <h2>{item.title}</h2>
          <p>{item.description}</p>
          <a href={item.link}>Read more</a>
        </div>
      ))}
    </div>
  );
}

export default GoogleRSS;
