import React, { useState, useEffect, useContext, useRef } from "react";
import Resizer from "react-image-file-resizer";
import { UserContext } from "../userContext";
import ImagePreview from "./imagePreview";
import _ from "lodash";

const MyFileBuffer = () => {
  const REACT_APP_SERVER_URL = `${process.env.REACT_APP_SERVER_URL}`;
  const { user, setUser } = useContext(UserContext);
  const [compressed, setCompressed] = useState("");
  const [imgPreview, setImgPreview] = useState({});
  const [uploadBtnStatus, setUploadBtnStatus] = useState(0);
  const [mode, setMode] = useState(1);
  const formFile = useRef();
  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        300,
        300,
        "png",
        100,
        0,
        (uri) => {
          resolve(uri);
          // console.log("Returned URI", uri);
        },
        "file"
      );
    });

  const onChange = async (event) => {
    try {
      const file = document.querySelector("#formFile").files[0];
      const image = await resizeFile(file);
      setCompressed(image);
    } catch (err) {
      console.log(err);
    }
  };

  const onDelete = async (event) => {
    setUser(() => {
      const u = { ...user };
      u.profileImage = null;
      setMode(1);
      return { ...u };
    });

    const URL = `${REACT_APP_SERVER_URL}/api/v1/upload-profile-image`;
    try {
      const response = await fetch(URL, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ _id: "123abc" }),
      });
      const data = await response.json();
    } catch (err) {
      console.log("Error:", err);
    }
  };

  const doFetch = async (file) => {
    const URL = `${REACT_APP_SERVER_URL}/api/v1/upload-profile-image`;

    try {
      const response = await fetch(URL, {
        method: "POST",
        headers: {
          //   "Content-Type": "multipart/form-data",
        },
        body: file,
      });
      const data = await response.json();
      setImgPreview(data);
      setMode(2);
      formFile.current.value = null;
      setUploadBtnStatus(0);
      return data;
    } catch (err) {
      console.log("Error:", err);
    }
  };

  const handleUpload = () => {
    const data = new FormData();
    data.append("file", compressed);
    doFetch(data);
  };

  useEffect(() => {
    if (compressed) handleUpload();
  }, [compressed]);

  useEffect(() => {
    if (_.isEmpty(imgPreview)) return;

    setUser(() => {
      const u = { ...user };
      u.profileImage = `${imgPreview.filename}`;
      return { ...u };
    });
  }, [imgPreview]);

  useEffect(() => {
    if (user.profileImage) setMode(2);
  }, []);

  const handleFileInputChange = () => {
    if (formFile.current.value) {
      setUploadBtnStatus(1);
    } else {
      setUploadBtnStatus(0);
    }
  };

  return (
    <React.Fragment>
      {mode === 1 ? (
        <div>
          <h3>Upload an image</h3>
          <div className="App">
            <div className="mb-3">
              <label htmlFor="formFile" className="form-label"></label>
              <input
                className="form-control"
                type="file"
                id="formFile"
                ref={formFile}
                onChange={handleFileInputChange}
              />

              <div className="d-grid gap-2 mb-3 mt-3">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={onChange}
                  disabled={!uploadBtnStatus}
                >
                  Set Profile Image
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <h3>Change or delete image</h3>
          <div className="App">
            <div className="mb-3">
              <label htmlFor="formFile" className="form-label"></label>
              <input
                className="form-control"
                type="file"
                id="formFile"
                ref={formFile}
                onChange={handleFileInputChange}
              />

              <div className="d-grid gap-2  mb-3 mt-3">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={onChange}
                  disabled={!uploadBtnStatus}
                >
                  Set Profile Image
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={onDelete}
                >
                  Delete Profile Image
                </button>
              </div>
            </div>

            <ImagePreview user={user} />
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default MyFileBuffer;
