import React, { useState, useEffect } from "react";
import Todo from "./todo";

const TodoList = ({
  todoList,
  toggleTodoCheckBox,
  doDeleteTodoItem,
  anyItemsChecked,
  handleCompletedTodoDelete,
}) => {
  const [clearedTrue, setClearedTrue] = useState([]);
  const [clearedFalse, setClearedFalse] = useState([]);

  const setItemsByCleared = () => {
    setClearedTrue(todoList.filter((item) => item.cleared));
    setClearedFalse(todoList.filter((item) => !item.cleared));
  };

  useEffect(() => {
    setItemsByCleared();
  }, [todoList]);

  const doCompletedTodo = (e) => {
    handleCompletedTodoDelete(e);
  };

  return (
    <React.Fragment>
      <div className="container mt-5">
        <div className="row">
          <div className="col-12 col-lg-6">
            <div className="card mt-3">
              <h5 className="card-header">Active Items:</h5>
              <div className="card-body">
                <h5 className="card-title"></h5>
                <div className="card-text">
                  {clearedFalse.length
                    ? clearedFalse.map((item) => (
                        <Todo
                          key={item._id || "tempkey"}
                          todo={item}
                          toggleTodoCheckBox={toggleTodoCheckBox}
                          doDeleteTodoItem={doDeleteTodoItem}
                        />
                      ))
                    : "No active items"}
                </div>
              </div>

              <button
                onClick={doCompletedTodo}
                className="btn btn-primary m-3"
                disabled={!anyItemsChecked}
              >
                Move checked items
              </button>
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <div className="card mt-3" style={{ opacity: "0.6" }}>
              <h5 className="card-header">Cleared Items:</h5>
              <div className="card-body">
                <h5 className="card-title"></h5>
                <div className="card-text">
                  {clearedTrue.length
                    ? clearedTrue.map((item) => (
                        <Todo
                          key={item._id || "tempkey"}
                          todo={item}
                          toggleTodoCheckBox={toggleTodoCheckBox}
                          doDeleteTodoItem={doDeleteTodoItem}
                        />
                      ))
                    : "No cleared items"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <h2>Active Items:</h2>

      {clearedFalse.length
        ? clearedFalse.map((item) => (
            <Todo
              key={item._id || "tempkey"}
              todo={item}
              toggleTodoCheckBox={toggleTodoCheckBox}
              doDeleteTodoItem={doDeleteTodoItem}
            />
          ))
        : "No active items"}

      <hr></hr>
      <h2>Cleared Items:</h2>

      {clearedTrue.length
        ? clearedTrue.map((item) => (
            <Todo
              key={item._id || "tempkey"}
              todo={item}
              toggleTodoCheckBox={toggleTodoCheckBox}
              doDeleteTodoItem={doDeleteTodoItem}
            />
          ))
        : "No cleared items"} */}
    </React.Fragment>
  );
};

export default TodoList;
