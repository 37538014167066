import React from "react";

import Table from "../common/table";
import { NavLink } from "react-router-dom";

const ShopItemsTable = ({
  items,
  sortColumn,
  onSort,
  onRatingDown,
  onRatingUp,
  onLike,
  onDelete,
}) => {
  const columns = [
    {
      key: "activate",
      content: (item) => (
        <NavLink
          to={`/item-form-page/articles/${item._id}`}
          style={{ color: item.activate ? "#20c997" : "#adb5bd" }}
          data-bs-toggle="tooltip"
          data-bs-title="Default tooltip"
        >
          <i className="fa fa-podcast" aria-hidden="true"></i>
        </NavLink>
      ),
      isSortable: false,
    },
    {
      path: "title",
      label: "Title",
      content: (item) => (
        <NavLink
          to={`/item-form-page/articles/${item._id}`}
          style={{ textDecoration: "none" }}
        >
          {item.title}
        </NavLink>
      ),
    },
    { path: "category.name", label: "Category" },

    // {
    //   key: "edit",
    //   content: (item) => (
    //     <NavLink to={`/articles-item-form-page/${item._id}`}>
    //       <i className="fa fa-pencil" aria-hidden="true"></i>
    //     </NavLink>
    //   ),
    //   isSortable: false,
    // },

    {
      key: "delete",
      content: (item) => (
        <i
          style={{ cursor: "pointer", color: "#999999" }}
          onClick={() => onDelete(item)}
          className="fa fa-times"
          aria-hidden="true"
        ></i>
      ),
      isSortable: false,
    },

    // {
    //   path: "mainContent",
    //   label: "Content",
    //   content: (item) => item.mainContent,
    // },
  ];

  return (
    <React.Fragment>
      <Table
        columns={columns}
        sortColumn={sortColumn}
        onSort={onSort}
        data={items}
      />
    </React.Fragment>
  );
};

export default ShopItemsTable;
