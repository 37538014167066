import React from "react";
import { DisplayContactButton } from "./../common/buttons";

const PrivacyPolicy = () => {
  return (
    <div className="container">
      <h1>Privacy Policy</h1>
      <p>
        This privacy policy ("Policy") explains how iMassMedia.com ("we," "us,"
        or "our") collects, uses, and shares personal information of users of
        our web app iMassMedia ("App").
      </p>

      <h2>Collection of Personal Information</h2>
      <p>
        We collect the following types of personal information from our users:
      </p>
      <ul>
        <li>
          Google and Facebook profile information: We may access your Google
          and/or Facebook profile information when you use the App, such as your
          name, profile picture, and email address.
        </li>
        <li>
          Activity information: We may collect information about your activity
          on the App, such as the pages you visit, the content you interact
          with, and the time and date of your activity.
        </li>
      </ul>

      <h2>Use of Personal Information</h2>
      <p>
        We use the personal information we collect from our users for the
        following purposes:
      </p>
      <ul>
        <li>
          To provide and improve the App: We use the personal information we
          collect to operate, maintain, and improve the App.
        </li>
        <li>
          To communicate with our users: We may use the personal information we
          collect to communicate with you, such as to send you updates and
          information about the App.
        </li>
      </ul>

      <h2>Sharing of Personal Information</h2>
      <p>
        <b>
          We <u>do not</u> share personal information with third parties.
        </b>
      </p>

      <h2>Security</h2>
      <p>
        We take reasonable steps to protect the personal information we collect
        from users of the App from loss, misuse, and unauthorized access,
        disclosure, alteration, or destruction. However, please note that no
        internet transmission is ever completely secure or error-free. In
        particular, email sent to or from the App may not be secure.
      </p>

      <h2>Changes to this Policy</h2>
      <p>
        We may change this Policy from time to time. If we make changes, we will
        post the new Policy on this page and will indicate at the top of this
        Policy the date it was last revised. Your continued use of the App after
        any changes to this Policy will be subject to the then-current Policy.
      </p>

      <h2>Contact Us</h2>
      <p>
        If you have any questions about this Policy or our privacy practices,
        please <DisplayContactButton classNames={`btn-outline-dark btn-sm`} />{" "}
        us.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
