const REACT_APP_SERVER_URL = `${process.env.REACT_APP_SERVER_URL}/api/v1`;

export async function getTweets() {
  URL = `${REACT_APP_SERVER_URL}/tweets`;
  try {
    const response = await fetch(URL);
    const data = await response.json();
    return data;
  } catch (err) {
    console.log("Error:", err);
  }
  return null;
}

export async function getContentTweets(contentId, tweetType) {
  const URL = `${REACT_APP_SERVER_URL}/tweets/${tweetType}/${contentId}`;

  try {
    const response = await fetch(URL);
    const data = await response.json();
    return data;
  } catch (err) {
    console.log("Error:", err);
  }
  return null;
}

export async function getUserTweets(userId) {
  const URL = `${REACT_APP_SERVER_URL}/tweets/${userId}`;
  try {
    const response = await fetch(URL);
    const data = await response.json();
    return data;
  } catch (err) {
    console.log("Error:", err);
  }
  return null;
}

export async function saveContentTweet(item) {
  const URL = `${REACT_APP_SERVER_URL}/tweets/${item.contentType}/${item.contentId}`;

  try {
    const response = await fetch(URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(item),
    });
    const data = await response.json();
    return data;
  } catch (err) {
    console.log("Error:", err);
  }
  return null;
}

export async function saveTweet(item) {
  const URL = `${REACT_APP_SERVER_URL}/tweets`;
  try {
    const response = await fetch(URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(item),
    });
    const data = await response.json();
    return data;
  } catch (err) {
    console.log("Error:", err);
  }
  return null;
}
