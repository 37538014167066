import React, { useEffect } from "react";
import _ from "lodash";

const ImagePreview = ({ user }) => {
  const REACT_APP_SERVER_URL = `${process.env.REACT_APP_SERVER_URL}`;

  if (_.isEmpty(user.profileImage)) return null;

  return (
    <React.Fragment>
      <span>Current Profile Image:</span>
      <img
        src={`${REACT_APP_SERVER_URL}/users/${user._id}/profile-image/${user.profileImage}`}
      />
    </React.Fragment>
  );
};

export default ImagePreview;
