import React, { useState, useEffect } from "react";

import Card from "../common/card";
import Grid from "../common/grid";

import { Link } from "react-router-dom";

import { getNavItems } from "../navBar/navBarJson";
import CardNav from "./cardNav";

const NavCardItems = () => {
  const [navCardItems, setNavCardItems] = useState([]);

  const buildNavItemsArr = () => {
    const newArr = [];
    const navItems = getNavItems();

    navItems.forEach((item) => {
      const links = [...item.links];
      links.forEach((link) => {
        link.group = item.mainTitle;
        link.index = item.index;
        link.index !== 5 && newArr.push(link);
      });
    });

    setNavCardItems(newArr);
  };

  useEffect(() => {
    buildNavItemsArr();
  }, []);

  return (
    <Grid>
      {navCardItems.map((content) => (
        <CardNav key={content.title}>
          <div className="p-1 ps-2" style={{ backgroundColor: "white" }}>
            <h4
              className="pt-1 mt-1 mb-2  lh-1 fw-bold"
              style={{ fontSize: "1.2rem" }}
            >
              <i
                className={content.icon}
                aria-hidden="true"
                style={{ color: "#576994" }}
              ></i>{" "}
              <Link
                className=""
                to={content.to}
                style={{
                  textDecoration: "none",
                  // textShadow: "1px 1px 1px #576994",
                  color: "#576994",
                }}
              >
                {content.title}
              </Link>
            </h4>
            <span className="fw-light text-dark" style={{ fontSize: ".9rem" }}>
              {content.linkDescription}
            </span>
          </div>
        </CardNav>
      ))}
    </Grid>
  );
};
export default NavCardItems;
