import React, { useState, useEffect, useContext, useRef } from "react";
import Tweet from "../../comments/tweet";
import { UserContext } from "../userContext";

// import { updateUsername } from "../../services/usersService";
import { Link, NavLink } from "react-router-dom";
import Hero from "../hero";
import CardCollapse from "../common/cardCollapse";
import TBSModal from "../common/modalChangeProfilePic";
import TBSModalDeleteAccount from "../common/modalDeleteAccount";
import ImageUploader from "../uploader/imageUploader";
import ProfileImage from "./profileImage";
import DateFormatter from "../common/dateFormat";

import { getContentByUserId } from "../../services/contentService";
import {
  updateUsername,
  deactivateUserAccount,
  requestAdminAccount,
} from "../../services/usersService";
import HelmetNavItems from "../common/helmet/helmetNavItems";
import { DisplayLogOutButton } from "../common/buttons";
// import { getNavItemByMainTitle } from "../navBar/navBarJson";

const Dashboard = () => {
  const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;

  const { user, setUser } = useContext(UserContext);
  const [movies, setMovies] = useState([]);
  const [articles, setArticles] = useState([]);
  const [editUsernameMode, setEditUsernameMode] = useState(false);

  const [isAccountDeleted, setIsAccountDeleted] = useState(false);

  const userNameInput = useRef();
  const userNameLabel = useRef();
  const userNameSaveButton = useRef();
  const userNameCancelButton = useRef();

  const deleteAccountButton = useRef();

  const loadMovies = async () => {
    const movieData = await getContentByUserId({
      id: user._id,
      contentType: "movies",
    });
    setMovies(movieData);
  };

  const loadArticles = async () => {
    const data = await getContentByUserId({
      id: user._id,
      contentType: "articles",
    });
    setArticles(data);
  };

  useEffect(() => {
    user?._id && loadMovies() && loadArticles();

    // console.log("user.roles", user.roles.includes("admin"));
  }, []);

  useEffect(() => {
    if (editUsernameMode) userNameInput.current.value = user.username;
  }, [editUsernameMode]);

  const handleEditUsernameMode = () => {
    setEditUsernameMode(!editUsernameMode);
  };

  useEffect(() => {
    if (isAccountDeleted) {
      deleteAccountButton.current.disabled = true;
      const myTimeout = setTimeout(() => {
        window.open(`${process.env.REACT_APP_SERVER_URL}/auth/logout`, "_self");
      }, 5000);
    }
  }, [isAccountDeleted]);

  const handleSaveNewUserName = async (e) => {
    const data = await updateUsername(user._id, userNameInput.current.value);

    setUser(() => {
      const u = { ...user };
      u.updateUsername = userNameInput.current.value;
      return { ...u };
    });

    // const u = { ...user };
    // u.username = userNameInput.current.value;

    setEditUsernameMode(!editUsernameMode);
  };

  // const ProfileImage = ({user}) => {
  //   const profileImagePath = user.profileImage
  //     ? user.profileImage
  //     : user.avatar;

  //   return (
  //     <img
  //       src={`${profileImagePath}`}
  //       alt=""
  //       style={{ borderRadius: "50%", width: "100px" }}
  //     />
  //   );
  // };

  const DisplayChangeProfilePicButton = () => {
    return (
      <button
        type="button"
        className={`btn btn-sm btn-primary me-2`}
        data-bs-toggle="modal"
        data-bs-target="#imm-modal-profile-pic"
        style={{ width: "150px", borderRadius: "0" }}
      >
        Edit Image
      </button>
    );
  };

  const handleDeactivateUserAccount = async () => {
    //deactivateUserAccount
    console.log("DELETE YOUR ACCOUNT");

    const deletedAccount = await deactivateUserAccount(user._id);

    deletedAccount.username === "Account deleted" && setIsAccountDeleted(true);

    console.log("deletedAccount", deletedAccount);
  };

  const handleRequestAdminAccount = async () => {
    console.log("reaq admin acc");

    const data = await requestAdminAccount(user._id);

    console.log("user.roles:", user.roles);
    console.log("data.roles:", data.roles);
    console.log("data:", data);

    const userCopy = { ...user };
    userCopy.roles = [...data.roles];

    setUser(userCopy);
  };

  return (
    <React.Fragment>
      <TBSModal title="Manage Profile Image">
        <div>
          <ImageUploader />
        </div>
      </TBSModal>

      <TBSModalDeleteAccount title="Delete Your Account">
        <div>
          {isAccountDeleted ? (
            <div>
              Your user account and information on imassmedia.com have been
              successfully deleted. <br />
              <br />
              You are now being logged out.
            </div>
          ) : (
            <div>
              <h4>
                <b>Warning</b>: Account Deletion Ahead!
              </h4>
              <br />
              Please be advised that this action will permanently delete your
              account and all associated information stored on our servers. This
              includes any data that is used to maintain the functionality of
              your account.
              <br />
              <br />
              Please note that this deletion will not clear any comments or
              content that you may have posted on the site such as articles and
              movie ratings. Content will become anonymized and cannot be
              accessed.
              <br />
              <br />
              If you choose to log in again in the future using the same social
              media account, a new imassmedia.com user account will be created.
              You will not have access to your previous anonymized content.
              <br />
              <br />
            </div>
          )}
        </div>
        <div>
          <button
            className="btn btn-sm btn-danger"
            ref={deleteAccountButton}
            onClick={handleDeactivateUserAccount}
          >
            Confirm Account Deletion!
          </button>
        </div>
      </TBSModalDeleteAccount>
      <Hero
        title={`Dashboard`}
        bgClass1="layer2"
        bgClass2=""
        textClass="text-dark"
        description={`This is the place to overview and manage your presence on the site.`}
        icon={` text-secondary fa fa-tachometer`}
      >
        <div>
          <div className="d-grid gap-2 d-sm-flex justify-content-sm-center mb-5"></div>
        </div>
      </Hero>

      {user._id ? (
        <React.Fragment>
          <div className="container mt-5">
            {/* ACCOUNT */}
            <div className="card ">
              <h5 className="card-header text-start ">
                <div className="row">
                  <div className="col">Account</div>
                  <div className="col text-end">
                    <DisplayLogOutButton classNames={`btn-sm btn-warning`} />
                  </div>
                </div>
              </h5>

              <div className="card-body">
                <h5 className="card-title"></h5>
                <p className="card-text"></p>

                <div className="container">
                  <div className="row">
                    <div className="row">
                      <div className="col">
                        <div className="card-title">
                          {/* <h1 className="card-title">{user.username}</h1> */}
                          {editUsernameMode ? (
                            <div>
                              <span>
                                <input
                                  style={{ display: "inline-block" }}
                                  className="form-control form-control-md w-25 me-2"
                                  name="userNameInput"
                                  id="userNameInput"
                                  ref={userNameInput}
                                />
                              </span>
                              <span>
                                <button
                                  className="btn btn-primary me-1"
                                  name="userNameSaveButton"
                                  id="userNameSaveButton"
                                  ref={userNameSaveButton}
                                  onClick={(e) => handleSaveNewUserName(e)}
                                >
                                  Save
                                </button>
                                <button
                                  className="btn btn-danger"
                                  name="userNameCancelButton"
                                  id="userNameCancelButton"
                                  ref={userNameCancelButton}
                                  onClick={() =>
                                    setEditUsernameMode(!editUsernameMode)
                                  }
                                >
                                  Cancel
                                </button>
                              </span>
                            </div>
                          ) : (
                            <div
                              onClick={() => handleEditUsernameMode()}
                              ref={userNameLabel}
                              style={{
                                cursor: "pointer",
                              }}
                            >
                              <span className="fs-3">
                                <i
                                  className="fa fa-pencil text-secondary"
                                  aria-hidden="true"
                                ></i>{" "}
                                {user.username}{" "}
                                {user.roles.map((role, i) => (
                                  <span
                                    key={i}
                                    className="badge text-bg-secondary"
                                  >
                                    {role === "admin" && (
                                      <i
                                        className="fa fa-star text-warning"
                                        aria-hidden="true"
                                      ></i>
                                    )}{" "}
                                    {role}
                                  </span>
                                ))}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col col-lg-3 mb-5">
                      <p className="card-text"></p>

                      <div
                        style={{
                          border: "0px solid black",
                          width: "150px",
                          transform: "rotate(-10deg)",
                          boxShadow:
                            "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                        }}
                      >
                        <div className="">
                          <ProfileImage user={user} width="150px" />
                        </div>
                        <div>
                          <DisplayChangeProfilePicButton />
                        </div>
                      </div>
                    </div>

                    <div className="col col-lg-9">
                      <div>
                        <Link
                          to={`/user-profile/${user._id}`}
                          style={{ textDecoration: "none" }}
                        >
                          View Profile
                        </Link>
                      </div>
                      <div>
                        Joined <DateFormatter date={user.memberSince} /> with{" "}
                        {user.platform === "Google" ? (
                          <i className="fa fa-google" aria-hidden="true"></i>
                        ) : (
                          <i
                            className="fa fa-facebook-official"
                            aria-hidden="true"
                          ></i>
                        )}{" "}
                        {user.platform}
                        {user.roles && !user.roles.includes("admin") && (
                          <div>
                            {user.roles.includes("pending-admin-request") && (
                              <button
                                type="button"
                                className={`btn btn-sm btn-info me-2`}
                                disabled={true}
                              >
                                Admin Request Pending...
                              </button>
                            )}

                            {!user.roles.includes("pending-admin-request") &&
                              !user.roles.includes("admin") && (
                                <button
                                  type="button"
                                  className={`btn btn-sm btn-info me-2`}
                                  onClick={handleRequestAdminAccount}
                                >
                                  Request Admin account
                                </button>
                              )}

                            <button
                              type="button"
                              className={`btn btn-sm btn-danger`}
                              data-bs-toggle="modal"
                              data-bs-target="#imm-modal-delete-account"
                            >
                              Delete Your Account
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {user.roles.includes("admin") && (
              <>
                {/* MOVIES */}
                <CardCollapse title="Your Movies" id="movies">
                  <h5 className="card-title"></h5>
                  <p className="card-text"></p>
                  <div className="container">
                    <div className="row">
                      <div className="col-3">
                        <h5 className="card-title"></h5>
                        <p className="card-text"></p>
                      </div>

                      <div className="col-9">
                        {/* <MyContent user={user} /> */}
                        Number of items posted: {movies?.length}
                        {movies?.map((movie) => (
                          <div key={movie._id}>
                            <NavLink
                              to={`/movie/${movie._id}`}
                              style={{ textDecoration: "none" }}
                            >
                              {movie.title}
                            </NavLink>{" "}
                            |{" "}
                            <NavLink
                              to={`/movies-item-form-page/${movie._id}`}
                              style={{ textDecoration: "none" }}
                            >
                              edit
                            </NavLink>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </CardCollapse>
                {/* ARTICLES */}
                <CardCollapse
                  title="Your Articles"
                  btnText="Show/Hide Articles"
                  id="articles"
                >
                  <h5 className="card-title"></h5>
                  <p className="card-text"></p>
                  <div className="container">
                    <div className="row">
                      <div className="col-3">
                        <h5 className="card-title"></h5>
                        <p className="card-text"></p>
                      </div>

                      <div className="col-9">
                        Number of items posted: {articles?.length}
                        {articles?.map((article) => (
                          <div key={article._id}>
                            <NavLink
                              to={`/article/${article._id}`}
                              style={{ textDecoration: "none" }}
                            >
                              {article.title}
                            </NavLink>{" "}
                            |{" "}
                            <NavLink
                              to={`/articles-item-form-page/${article._id}`}
                              style={{ textDecoration: "none" }}
                            >
                              edit
                            </NavLink>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </CardCollapse>
              </>
            )}
            {/* TWEETS */}
            <CardCollapse
              title="Your Tweets"
              btnText="Show/Hide Tweets"
              id="tweets"
            >
              <h5 className="card-title"></h5>
              <p className="card-text"></p>
              <div className="container">
                <div className="row">
                  <div className="col-3">
                    <h5 className="card-title"></h5>
                    <p className="card-text"></p>
                  </div>
                  <div className="col-9">
                    <Tweet userId={user._id} />
                  </div>
                </div>
              </div>
            </CardCollapse>
          </div>

          {/* <h2>User Name: {user.username}</h2>
          <h2>Platform: {user.platform}</h2>

          <h2>
            Role:{" "}
            {user.roles.map((role, i) => (
              <div key={i}>{role}</div>
            ))}
          </h2>
          <h2>
            Permissions:{" "}
            {user.permissions.map((permission, i) => (
              <div key={i}>{permission}</div>
            ))}
          </h2> */}
        </React.Fragment>
      ) : (
        "Loading..."
      )}
      <br />
      {/* <Tweet user={user} URL={`/tweets/${user._id}`} /> */}
      {/* <div>
        <h1>Movies:</h1>
        Number of items posted: {movies?.length}
        {movies?.map((movie) => (
          <div key={movie._id}>
            <b>{movie.title}</b> Edit | View{" "}
          </div>
        ))}
      </div> */}

      <HelmetNavItems mainTitle="Dashboard" to={`/dashboard`} />
    </React.Fragment>
  );
};

export default Dashboard;
