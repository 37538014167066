import React, { useState, useRef, useEffect } from "react";

const FileUploader = () => {
  const img1 = useRef();
  const canvas1 = useRef();

  const [originalFile, setOriginalFile] = useState({
    fileSize: 0,
  });
  const [compressedFile, setCompressedFile] = useState({ fileSize: 0 });

  //FUNCTION TO CALCULATE FILE SIZE FROM BASE64 IMAGE STRING
  const calcBase64FileSizeInKb = (b64String) => {
    const strLength = b64String.length - "data:image/png;base64,".length;
    var sizeInBytes = 4 * Math.ceil(strLength / 3) * 0.5624896334383812;
    return sizeInBytes / 1000;
  };

  const decodeFileBase64 = (base64String) => {
    console.log("decodeFileBase64", base64String);
    console.log("compressedFile.base64String", compressedFile.base64String);

    return decodeURIComponent(
      atob(`data:image/png;base64,${base64String}`)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    // console.log(
    //   "decodeURIComponent(base64String)",
    //   decodeURIComponent(base64String)
    // );

    // return decodeURIComponent(base64String);
  };

  const TEST = () => {
    const decodeBase64 = decodeFileBase64(
      compressedFile.base64String?.substring(
        compressedFile.base64String?.indexOf(",") + 1
      )
    );

    console.log("decodeBase64", decodeBase64);

    // const decodeBase64 = decodeFileBase64(compressedFile.base64String);
  };

  //PROCESS SELECTED IMAGE FILE ON BUTTON CLICK
  const process = () => {
    const file = document.querySelector("#formFile").files[0];

    //EXIT FUNCTION IF NO FILE SELECTED
    if (!file) return;

    //READ SELECTED FILE
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = function (event) {
      //SET STATE: ORIGINAL FILE
      setOriginalFile({
        base64String: reader.result,
        fileSize: calcBase64FileSizeInKb(reader.result),
      });

      const MAX_WIDTH = 200;
      const scaleSize = MAX_WIDTH / event.target.width;

      img1.current.width = MAX_WIDTH;
      img1.current.src = event.target.result;

      img1.current.onload = function (e) {
        const scaleSize = MAX_WIDTH / e.target.width;
        canvas1.current.width = MAX_WIDTH;
        canvas1.current.height = e.target.height * scaleSize;

        //DRAW CANVAS WITH SELECTED IMAGE WITH NEW DIMENSIONS
        const ctx = canvas1.current.getContext("2d");
        ctx.drawImage(
          e.target,
          0,
          0,
          canvas1.current.width,
          canvas1.current.height
        );

        //GET NEW COMPRESSED/RESIZED IMAGE IN BASE64
        const srcEncoded = canvas1.current.toDataURL(e.target, "image/jpeg");

        //SET CANVAS TO NEW BASE64 IMAGE
        canvas1.current.src = srcEncoded;
        console.log("srcEncoded", srcEncoded);
        console.log("reader.result", reader.result);

        //SET STATE: NEW COMPRESSED FILE
        setCompressedFile({
          base64String: srcEncoded,
          fileSize: calcBase64FileSizeInKb(srcEncoded),
        });
      };
    };
  };

  return (
    <React.Fragment>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1>Upload File</h1>
            <div className="mb-3">
              <label htmlFor="formFile" className="form-label"></label>
              <input className="form-control" type="file" id="formFile" />

              <button
                type="button"
                className="btn btn-primary"
                onClick={process}
              >
                Process
              </button>
              <br />
              <br />
              <button type="button" className="btn btn-primary" onClick={TEST}>
                TEST
              </button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-6">
            <div className="row">
              <div className="col">
                <div>
                  <img ref={img1} id="img1" name="img1" src="" alt="" />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                {originalFile.fileSize > 0 && (
                  <div>
                    Original File Size:
                    <span id="originalFileSize">
                      {" "}
                      {originalFile.fileSize}kb
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <div className="row">
              <div className="col">
                <div>
                  <canvas
                    ref={canvas1}
                    id="canvas1"
                    height="0"
                    style={{ display: "block" }}
                  ></canvas>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                {compressedFile.fileSize > 0 && (
                  <div>
                    Compressed File Size:
                    <span id="compressedFileSize">
                      {" "}
                      {compressedFile.fileSize}kb
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default FileUploader;
