export async function getHP() {
  // console.log("HARRY POTTER SERVICE");

  const URL = "https://hp-api.herokuapp.com/api/characters";
  try {
    const response = await fetch(URL);
    const data = await response.json();
    // console.log("HARRY POTTER SERVICE data", data);

    return data;
  } catch (err) {
    console.log("Error:", err);
  }
  return null;
}
