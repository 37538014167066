import React, { useEffect } from "react";
import { Tooltip } from "bootstrap";
// import Like from "../common/like";
// import StarRating from "../common/starRating";
// import EditRating from "../common/editRating";
import Table from "../common/table";
import { NavLink } from "react-router-dom";

const MoviesTable = ({
  items,
  sortColumn,
  onSort,
  onRatingDown,
  onRatingUp,
  onLike,
  onDelete,
}) => {
  const columns = [
    {
      key: "activate",
      content: (item) => (
        <NavLink
          to={`/item-form-page/movies/${item._id}`}
          style={{ color: item.activate ? "#20c997" : "#adb5bd" }}
          data-bs-toggle="tooltip"
          data-bs-title="Default tooltip"
        >
          <i className="fa fa-podcast" aria-hidden="true"></i>
        </NavLink>
      ),
      isSortable: false,
    },
    {
      path: "title",
      label: "Title",
      content: (item) => (
        <NavLink
          to={`/item-form-page/movies/${item._id}`}
          style={{ textDecoration: "none" }}
        >
          {item.title}
        </NavLink>
      ),
    },

    // {
    //   path: "titlex",
    //   label: "Title",
    //   content: (item) => (
    //     <NavLink
    //       to={`/item-form-page/movie/${item._id}`}
    //       style={{ textDecoration: "none" }}
    //     >
    //       t-edit
    //     </NavLink>
    //   ),
    // },

    // {
    //   key: "edit",
    //   content: (item) => (
    //     <NavLink to={`/movies-item-form-page/${item._id}`}>
    //       <i className="fa fa-pencil" aria-hidden="true"></i>
    //     </NavLink>
    //   ),
    //   isSortable: false,
    // },
    {
      key: "delete",
      content: (item) => (
        <i
          style={{ cursor: "pointer", color: "#999999" }}
          onClick={() => onDelete(item)}
          className="fa fa-times"
          aria-hidden="true"
        ></i>
      ),
      isSortable: false,
    },
    // {
    //   key: "like",
    //   content: (item) => (
    //     <Like onClick={() => onLike(item)} liked={item.liked} />
    //   ),
    //   isSortable: false,
    // },
    { path: "genre.name", label: "Category" },
    // { path: "numberInStock", label: "Stock" },

    // {
    //   key: "starRating",
    //   content: (movie) => <StarRating rating={movie.dailyRentalRate} />,
    //   columnWidth: "130px",
    //   isSortable: false,
    // },
    // { path: "dailyRentalRate", label: "Rate" },
  ];

  useEffect(() => {
    const tooltipTriggerList = document.querySelectorAll(
      '[data-bs-toggle="tooltip"]'
    );
    const tooltipList = [...tooltipTriggerList].map(
      (tooltipTriggerEl) => new Tooltip(tooltipTriggerEl)
    );
  }, []);

  return (
    <React.Fragment>
      <Table
        columns={columns}
        sortColumn={sortColumn}
        onSort={onSort}
        data={items}
      />
    </React.Fragment>
  );
};

export default MoviesTable;
