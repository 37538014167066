import React, { useState, useEffect } from "react";
import Resizer from "react-image-file-resizer";

const ImageResizer = () => {
  const [compressed, setCompressed] = useState("");

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        300,
        300,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
          console.log("Returned URI", uri);
        },
        "base64"
      );
    });

  const onChange = async (event) => {
    try {
      const file = event.target.files[0];
      const image = await resizeFile(file);
      console.log("image", image);
      setCompressed(image);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <React.Fragment>
      <h1>Resizer</h1>
      <div className="App">
        <input type="file" onChange={onChange} />
        <img src={compressed} alt="" />
      </div>
    </React.Fragment>
  );
};

export default ImageResizer;
