import React, { useState, useEffect } from "react";
import _ from "lodash";
import Hero from "../components/hero";
import Tweet from "./tweet";
import { getTweets } from "../services/tweetService";
import { getArticleCategories } from "../services/articleCategoryService";
import { getGenres } from "../services/genreService";
import HelmetNavItems from "../components/common/helmet/helmetNavItems";

const CommentList = () => {
  const [allArticleCategories, setAllArticleCategories] = useState([]);
  const [allMovieGenres, setAllMovieGenres] = useState([]);
  const [allGeneral, setGeneral] = useState([]);
  const [currentGroup, setCurrentGroup] = useState({ contentType: "All" });

  const loadData = async () => {
    const tweetData = await getTweets();
    const articleCategoryData = await getArticleCategories();
    const movieGenreData = await getGenres();

    // console.log("tweetData", tweetData);
    // console.log("articleCategoryData", articleCategoryData);
    // console.log("movieGenreData", movieGenreData);

    let general = [];
    let movieGenreIds = [];
    let movieGenreFinal = [];
    let articleCategoryIds = [];
    let articleCategoryFinal = [];

    tweetData.forEach((item) => {
      if (item.contentType === "general") general.push(item);
      if (item.contentType === "movies")
        movieGenreIds.push(item?.movies?.genre);
      if (item.contentType === "articles")
        articleCategoryIds.push(item?.articles?.category);
    });

    // console.log("general", general);
    // console.log("movieGenreIds", movieGenreIds);
    // console.log("articleCategoryIds", articleCategoryIds);

    let movieGenreIdsUniq = _.uniq(movieGenreIds);
    let articleCategoryIdsUniq = _.uniq(articleCategoryIds);

    articleCategoryData.forEach((catItem, i) => {
      const x = _.findIndex(
        articleCategoryIdsUniq,
        (item) => item === catItem._id
      );

      catItem.count = 0;
      catItem.contentType = "articles";
      articleCategoryIds.forEach((catId) => {
        catItem._id === catId && catItem.count++;
      });

      if (x !== -1) articleCategoryFinal.push(catItem);
    });

    movieGenreData.forEach((genreItem, i) => {
      const x = _.findIndex(movieGenreIdsUniq, (item) => {
        return item === genreItem._id;
      });

      genreItem.count = 0;
      genreItem.contentType = "movies";
      movieGenreIds.forEach((genreId) => {
        genreItem._id === genreId && genreItem.count++;
      });

      if (x !== -1) movieGenreFinal.push(genreItem);
    });

    setAllArticleCategories([...articleCategoryFinal]);
    setAllMovieGenres([...movieGenreFinal]);
    setGeneral([...general]);
  };

  const contentTypeCount = (arr) => {
    return arr.reduce(function (acc, obj) {
      return acc + obj.count;
    }, 0);
  };

  const handleGroupClick = (item) => setCurrentGroup({ ...item });
  return (
    <React.Fragment>
      <Hero
        title={`Comment Board`}
        bgClass1="layer2"
        bgClass2="layer3"
        textClass="text-dark"
        description={`See all comments on the site.  You may filter comments by where comment was made.`}
        icon={``}
      ></Hero>

      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-4">
            {/* category list */}

            <ul className="list-group pb-4" style={{ border: "0px solid red" }}>
              <li className="list-group-item d-flex justify-content-between align-items-start">
                <div className="ms-2 me-auto rounded-top" style={{}}>
                  <div style={{ fontSize: "0.9rem" }}>
                    Filter:{" "}
                    {currentGroup.contentType.charAt(0).toUpperCase() +
                      currentGroup.contentType.slice(1)}{" "}
                    {currentGroup.name ? ` / ${currentGroup.name}` : ``}
                  </div>
                </div>
              </li>

              <li className="list-group-item d-flex justify-content-between align-items-start">
                <div
                  className="ms-2 me-auto rounded-top"
                  style={{
                    // border: "1px solid red",
                    width: "100%",
                    backgroundColor: "#EEE",
                  }}
                >
                  <div
                    style={{
                      //   borderBottom: "3px solid #999",
                      paddingBottom: "5px",
                      paddingTop: "5px",
                      //   backgroundColor: "#EEE",
                    }}
                  >
                    <a
                      href="#"
                      className="list-group-item list-group-item-action border border-0"
                      onClick={() => handleGroupClick({ contentType: "All" })}
                    >
                      <span className="fw-bold m-3">All</span>

                      <span className="badge bg-primary rounded-pill">
                        {contentTypeCount(allArticleCategories) +
                          contentTypeCount(allMovieGenres) +
                          allGeneral.length}
                      </span>
                    </a>
                  </div>
                </div>
              </li>

              <li className="list-group-item d-flex justify-content-between align-items-start">
                <div
                  className="ms-2 me-auto rounded-top"
                  style={{
                    // border: "1px solid red",
                    width: "100%",
                    backgroundColor: "#EEE",
                  }}
                >
                  <div
                    style={{
                      //   borderBottom: "3px solid #999",
                      paddingBottom: "5px",
                      paddingTop: "5px",
                      //   backgroundColor: "#EEE",
                    }}
                  >
                    <a
                      href="#"
                      className="list-group-item list-group-item-action border border-0"
                      onClick={() =>
                        handleGroupClick({ contentType: "general" })
                      }
                    >
                      <span className="fw-bold m-3">General</span>

                      <span className="badge bg-primary rounded-pill">
                        {allGeneral.length}
                      </span>
                    </a>
                  </div>
                </div>
              </li>

              <li className="list-group-item d-flex justify-content-between align-items-start">
                <div
                  className="ms-2 me-auto rounded-top"
                  style={{
                    border: "0px solid red",
                    width: "100%",
                    backgroundColor: "#EEE",
                  }}
                >
                  <div
                    style={{
                      //   borderBottom: "3px solid #999",
                      paddingBottom: "5px",
                      paddingTop: "5px",
                      //   backgroundColor: "#EEE",
                    }}
                  >
                    <a
                      href="#"
                      className="list-group-item list-group-item-action border border-0"
                      onClick={() =>
                        handleGroupClick({ contentType: "articles" })
                      }
                    >
                      <span className="fw-bold m-3">Articles</span>

                      <span className="badge bg-primary rounded-pill">
                        {contentTypeCount(allArticleCategories)}
                      </span>
                    </a>
                  </div>

                  <ul className="list-group list-group-flush">
                    {allArticleCategories.map((item) => (
                      <li
                        className="list-group-item d-flex justify-content-between align-items-start"
                        style={{}}
                        onClick={() => handleGroupClick(item)}
                        key={item._id}
                      >
                        <a
                          href="#"
                          className="list-group-item list-group-item-action border border-0"
                        >
                          <div className="ms-2 me-auto flex-fill " style={{}}>
                            <div className="">
                              <span className="m-3 small">{item.name}</span>
                              <span
                                className="badge bg-primary rounded-pill"
                                style={{}}
                              >
                                {item.count}
                              </span>
                            </div>
                          </div>
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </li>

              <li className="list-group-item d-flex justify-content-between align-items-start">
                <div
                  className="ms-2 me-auto  rounded-top"
                  style={{
                    border: "0px solid red",
                    width: "100%",
                    backgroundColor: "#EEE",
                  }}
                >
                  <div
                    style={{
                      //   borderBottom: "3px solid #999",
                      paddingBottom: "5px",
                      paddingTop: "5px",
                      //   backgroundColor: "#EEE",
                    }}
                  >
                    <a
                      href="#"
                      className="list-group-item list-group-item-action border border-0"
                      onClick={() =>
                        handleGroupClick({ contentType: "movies" })
                      }
                    >
                      <span className="fw-bold m-3">Movies</span>

                      <span className="badge bg-primary rounded-pill">
                        {contentTypeCount(allMovieGenres)}
                      </span>
                    </a>
                  </div>

                  <ul className="list-group list-group-flush">
                    {allMovieGenres.map((item) => (
                      <li
                        className="list-group-item d-flex justify-content-between align-items-start"
                        style={{}}
                        onClick={() => handleGroupClick(item)}
                        key={item._id}
                      >
                        <a
                          href="#"
                          className="list-group-item list-group-item-action border border-0"
                        >
                          <div className="ms-2 me-auto flex-fill " style={{}}>
                            <div className="">
                              <span className="m-3 small">{item.name}</span>
                              <span className="badge bg-primary rounded-pill">
                                {item.count}
                              </span>
                            </div>
                          </div>
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </li>
            </ul>

            {/* category list end */}
          </div>
          <div className="col-12 col-lg-8">
            <Tweet currentGroup={currentGroup} />
          </div>
        </div>
      </div>

      <HelmetNavItems mainTitle="Community" to={`/comments`} />
    </React.Fragment>
  );
};

export default CommentList;
