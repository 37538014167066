import React from "react";
import Input from "../common/formInput";
import { useForm } from "react-hook-form";

const SearchBar = ({ searchQuery, handleSeachBoxChange }) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  return (
    <React.Fragment>
      <Input
        type="text"
        inputName="search"
        placeholder="Search..."
        label=""
        currentValue={searchQuery}
        {...register("search", {
          required: true,
          onChange: (e) => handleSeachBoxChange(e.target.value),
          onBlur: (e) => {},
        })}
      />
    </React.Fragment>
  );
};

export default SearchBar;
