import React, { useState, useEffect } from "react";

const Input = React.forwardRef(
  (
    { type, inputName, placeholder, onChange, label, currentValue, ...rest },
    ref
  ) => {
    const [val, setVal] = useState("");

    useEffect(() => {
      setVal(currentValue);
    }, [currentValue]);

    return (
      <div className="form-group">
        <div className="input-group input-group-sm mb-3">
          <span className="input-group-text" id="inputGroup-sizing-sm">
            <i className="fa fa-search" aria-hidden="true"></i>
          </span>
          <label htmlFor={inputName}>{label}</label>
          <React.Fragment>
            <input
              type={type}
              className="form-control"
              id={inputName}
              name={inputName}
              placeholder={placeholder}
              onChange={onChange}
              value={val}
              ref={ref}
              aria-label="Sizing example input"
              aria-describedby="inputGroup-sizing-sm"
              {...rest}
            />
          </React.Fragment>
        </div>
      </div>
    );
  }
);

export default Input;
