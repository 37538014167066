const REACT_APP_SERVER_URL = `${process.env.REACT_APP_SERVER_URL}/api/v1`;

export async function getContent(item) {
  const URL = `${REACT_APP_SERVER_URL}/content/${item}`;
  try {
    const response = await fetch(URL);
    const data = await response.json();
    return data;
  } catch (err) {
    console.log("Error:", err);
  }
  return null;
}

export async function getContentByUserId(item) {
  const { id, contentType } = item;
  const URL = `${REACT_APP_SERVER_URL}/content/${contentType}/user/${id}`;

  try {
    const response = await fetch(URL);
    const data = await response.json();
    return data;
  } catch (err) {
    console.log("Error:", err);
  }
  return null;
}

export async function getContentAdmin(item) {
  const URL = `${REACT_APP_SERVER_URL}/content/${item}/admin`;
  try {
    const response = await fetch(URL);
    const data = await response.json();
    return data;
  } catch (err) {
    console.log("Error:", err);
  }
  return null;
}

export async function getContentItem(item) {
  const URL = `${REACT_APP_SERVER_URL}/content/${item.contentType}/${item.id}`;
  try {
    const response = await fetch(URL);
    console.log("item", item);
    console.log(
      "data y",
      `${REACT_APP_SERVER_URL}/content/${item.contentType}/${item.id}`
    );
    const data = await response.json();
    console.log("data x", data);
    return data;
  } catch (err) {
    console.log("Error:", err);
  }
  return null;
}

export async function saveContent(item) {
  const URL = `${REACT_APP_SERVER_URL}/content/${item.contentType}`;
  const formData = new FormData();

  formData.append("title", item.title);
  formData.append("data", JSON.stringify(item));
  formData.append("file", item.imageData);
  formData.append("file", item.thumbImageData);
  formData.append("file", item.twitterImageData);

  try {
    const response = await fetch(URL, {
      method: "POST",
      body: formData,
    });
    const data = await response.json();
    return data;
  } catch (err) {
    console.log("Error:", err);
  }
  return null;
}

export async function updateContent(item) {
  const URL = `${REACT_APP_SERVER_URL}/content`;
  const formData = new FormData();

  formData.append("title", item.title);
  formData.append("data", JSON.stringify(item));
  formData.append("file", item.imageData);
  formData.append("file", item.thumbImageData);
  formData.append("file", item.twitterImageData);

  try {
    const response = await fetch(URL, {
      method: "PUT",
      body: formData,
    });
    const data = await response.json();
    return data;
  } catch (err) {
    console.log("Error:", err);
  }
  return null;
}

export async function deleteContent(item) {
  const URL = `${REACT_APP_SERVER_URL}/content`;
  try {
    const response = await fetch(URL, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(item),
    });
    const data = await response.json();
    return data;
  } catch (err) {
    console.log("Error:", err);
  }
  return null;
}

export async function deleteContentMainImage(item) {
  const id = item.paramId;
  const contentType = item.contentType;
  const URL = `${REACT_APP_SERVER_URL}/content/delete-main-image`;

  try {
    const response = await fetch(URL, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(item),
    });
    const data = await response.json();
    return data;
  } catch (err) {
    console.log("Error:", err);
  }
  return null;
}
