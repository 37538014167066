import React from "react";

const Grid = ({ children }) => {
  return (
    <div className="container  px-4 py-5" id="custom-cards">
      <div className="row row-cols-2 row-cols-md-3 row-cols-lg-4 row-cols-xxl-5 align-items-stretch g-4  py-5">
        {children.map((item) => (
          <div key={item.key} className="col" style={{ padding: "5px" }}>
            {item}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Grid;
