import React, { useState, useEffect, useRef, useContext } from "react";
// import { Link, NavLink, useNavigate } from "react-router-dom";
import { UserContext } from "../components/userContext";
import CommentBubble from "./commentBubble";
import {
  getTweets,
  getContentTweets,
  getUserTweets,
  saveTweet,
  saveContentTweet,
} from "../services/tweetService";

import { getArticleCategories } from "../services/articleCategoryService";
import { getGenres } from "../services/genreService";

const Tweet = ({ contentType, currentGroup, contentId, userId }) => {
  const { user, setUser } = useContext(UserContext);
  const [backendData, setBackendData] = useState([]);
  const [backendDataCopy, setBackendDataCopy] = useState([]);

  const [articleCategories, setArticleCategories] = useState([]);
  const [movieGenres, setMovieGenres] = useState([]);

  const tweetInput = useRef();

  const loadData = async () => {
    const data = await getTweets();
    data?.length && setBackendData(data);
  };

  const loadTweetDataByContentTypeAndId = async () => {
    const data = await getContentTweets(contentId, contentType);
    data?.length && setBackendData(data);
  };

  const loadTweetDataByUserId = async () => {
    const data = await getUserTweets(userId);
    data?.length && setBackendData(data);
  };

  const loadGroups = async () => {
    const articleGroups = await getArticleCategories();
    const movieGroups = await getGenres();

    setArticleCategories(articleGroups);
    setMovieGenres(movieGroups);
  };

  useEffect(() => {
    loadGroups();

    if (userId) {
      loadTweetDataByUserId();
    } else if (contentId) {
      loadTweetDataByContentTypeAndId();
    } else {
      loadData();
    }
  }, []);

  useEffect(() => {
    !backendDataCopy.length && setBackendDataCopy([...backendData]);
  }, [backendData]);

  useEffect(() => {
    if (currentGroup?.contentType === "All") {
      setBackendData([...backendDataCopy]);
    } else {
      const copyData = [...backendDataCopy].filter((item) => {
        const groupType =
          currentGroup?.contentType === "articles" ? "category" : "genre";

        if (currentGroup?._id) {
          return (
            item.contentType === currentGroup?.contentType &&
            item[item.contentType][groupType] === currentGroup?._id
          );
        }

        return item.contentType === currentGroup?.contentType;
      });

      setBackendData([...copyData]);
    }
  }, [currentGroup]);

  const getGroup = (tweet) => {
    let movieGroup = {};
    let articleGroup = {};

    if (tweet.contentType === "movies") {
      movieGroup = movieGenres.find((item) => item._id === tweet.movies?.genre);
      return movieGroup;
    }

    if (tweet.contentType === "articles") {
      articleGroup = articleCategories.find(
        (item) => item._id === tweet.articles?.category
      );
      return articleGroup;
    }

    return { name: "General Content" };
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const tweetData = {};
    tweetData.tweet = e.target["tweetInput"].value;
    tweetData.user = user._id;
    if (contentId) {
      tweetData.contentId = contentId;
      tweetData.contentType = contentType;
    }

    let newTweet = {};
    if (contentId) {
      newTweet = await saveContentTweet(tweetData);

      if (newTweet?._id) {
        if (backendData.length) {
          const contentProperties = {
            ...backendData[0][backendData[0].contentType],
          };

          const userProperties = {
            _id: user._id,
            username: user.username,
            googleId: user.socialId,
            googlePicture: user.avatar,
          };

          newTweet[backendData[0].contentType] = contentProperties;
          newTweet.user = userProperties;
          setBackendData([...backendData, newTweet]);
        } else {
          loadTweetDataByContentTypeAndId();
        }
      }
    } else if (userId) {
      tweetData.contentType = "general";
      newTweet = await saveTweet(tweetData);
      newTweet?._id && loadTweetDataByUserId();
    } else {
      tweetData.contentType = "general";
      newTweet = await saveTweet(tweetData);
      newTweet?._id && loadData();
    }
    tweetInput.current.value = ""; //CLEAR INPUT FIELD
  };

  return (
    <React.Fragment>
      {user?._id ? (
        <div className="mb-5">
          <form onSubmit={(e) => handleSubmit(e)}>
            <div className="mb-3">
              <textarea
                ref={tweetInput}
                name={"tweetInput"}
                className="form-control"
                id={"tweetInput"}
                placeholder={"Your Tweet..."}
                rows="3"
              ></textarea>
            </div>

            <div className="d-grid gap-2">
              <input className="btn btn-primary" type="submit" value={"Post"} />
            </div>
          </form>
        </div>
      ) : (
        <div
          className="alert alert-primary d-flex align-items-center"
          role="alert"
        >
          <div>
            <button
              type="button"
              className={`btn btn-sm btn-outline-dark me-2`}
              data-bs-toggle="modal"
              data-bs-target="#imm-modal-login"
            >
              Sign In <i className="fa fa-sign-in" aria-hidden="true"></i>
            </button>{" "}
            to post your own Tweets
          </div>
        </div>
      )}

      <div className=" mb-3 rounded-3" style={{ border: "0px solid #ff0000" }}>
        {backendData.length
          ? backendData.map((tweet, index) => (
              <CommentBubble
                comment={tweet}
                key={index}
                group={getGroup(tweet)}
              />
            ))
          : "No Tweets..."}
      </div>
    </React.Fragment>
  );
};

export default Tweet;

//6349b374e4410d4738a3ca1b
