import React from "react";
import Card from "../common/card";

const CardGamePlayer = ({ content, handlePlayerSelect }) => {
  // console.log("content---", content);
  // console.log("handlePlayerSelect", handlePlayerSelect);

  return (
    <Card>
      <div
        onClick={() => handlePlayerSelect(content)}
        role="button"
        className="btn btn-dark"
        style={{ height: "100%" }}
      >
        <div className="card-body p-2">
          <div className="d-flex flex-row align-items-center">
            {content?.house?.name !== "None" && (
              <div className="p-1 ">
                <img
                  src={`${content.image}`}
                  alt={content?.house?.name}
                  width="100px"
                />
              </div>
            )}
            <div className="">
              <h5 className="card-title mb-0" style={{ fontSize: "1rem" }}>
                {content.name}
              </h5>
            </div>
          </div>

          <div className="card-text">
            <div>
              <span>Spells: </span>
              <span className="fw-light">{content?.spells}</span>
            </div>

            <div>
              <span>Strength: </span>
              <span className="fw-light">{content?.strikeStrength}</span>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};
export default CardGamePlayer;
