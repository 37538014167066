import React, { useEffect, useState, useRef, useContext } from "react";
import { Link } from "react-router-dom";

import io from "socket.io-client";
import { DateTime } from "luxon";
import { UserContext } from "../userContext";
import CardCollapse from "../common/cardCollapse";
import HelmetNavItems from "../common/helmet/helmetNavItems";
// import ProfileImage from "../dashboard/profileImage";
import Hero from "../hero";
import { getAiBots } from "./aiBots";
import Grid from "../common/grid";

//FOR LOCAL SERVER ONLY
// const URL = "http://localhost:5000";

//FOR REMOTE SERVER
// const URL = "https://www.imassmedia.com";

const URL = process.env.REACT_APP_SOCKETIO_URL;

let chatBotId = "";

const ChatAppGPT = () => {
  const { user, setUser } = useContext(UserContext);
  const [socket, setSocket] = useState(null);
  const [outMessage, setOutMessage] = useState("");
  const [chatUsers, setChatUsers] = useState([]);
  // const [inMessages, setInMessages] = useState([]);
  const [allMessages, setAllMessages] = useState([]);
  const [isGPTError, setIsGPTError] = useState(false);
  const messageInput = useRef(null);
  const [aiBots, setAiBots] = useState([]);

  useEffect(() => {
    initChat();
  }, []);

  useEffect(() => {
    if (!socket) return;

    socket.on("connected", () => {
      const newChatUser = {
        userId: user._id,
        socketId: socket.id,
        username: user.username,
        profileImage: user.profileImage ? user.profileImage : "",
      };
      const aiBot = aiBots.find((aiBot) => aiBot.socketId === chatBotId);

      socket.emit("chatGPTUser", [newChatUser, aiBot]);
    });

    socket.on("chatGPTUsers", (newChatUsers) => {
      setChatUsers(newChatUsers);
    });

    socket.on("receive_gpt_message", (data) => {
      data.sentTime = DateTime.now().toISO();
      if (data.statusCode !== 200) setIsGPTError(true);
      setAllMessages((prev) => [data, ...prev]);
    });

    //CLEAN UP EVENTS
    return () => {
      socket.off("connected");
      socket.off("chatGPTUsers");
    };
  }, [socket]);

  useEffect(() => {}, [allMessages]);

  useEffect(() => {
    if (isGPTError === true) {
      const timeout = setTimeout(() => {
        setIsGPTError(false);
      }, 5000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [isGPTError]);

  const initChat = async () => {
    const data = await getAiBots();
    setAiBots(data);
  };

  const sendMessage = () => {
    if (!outMessage.trim().length) return null;
    const dts = DateTime.now().toISO();
    const message = {
      username: user.username,
      profileImage: user.profileImage,
      userId: user._id,
      message: outMessage,
      socketId: socket.id,
      sentTime: dts,
      chatBotId,
    };

    socket.emit("send_gpt_message", message);
    setAllMessages((prev) => [message, ...prev]);
    setOutMessage("");
    messageInput.current.value = "";
  };

  const handleSendMessage = () => sendMessage();

  const handleEnterKeyUp = (event) => {
    if (event.key !== "Enter") return null;
    sendMessage();
  };

  const handleDisconnect = () => {
    socket.disconnect();
    setSocket(null);
    setChatUsers([]);
    setAllMessages([]);
  };

  const handleEnterChat = (botId) => {
    chatBotId = botId;
    setSocket(io.connect(URL));
  };

  const SendButton = (props) => {
    return (
      <button
        onClick={props.handleSendMessage}
        disabled={!props.outMessage.trim() || isGPTError}
        className="btn btn-primary"
      >
        Send{" "}
        {isGPTError && (
          <span className="small">(Please wait a few seconds...)</span>
        )}
      </button>
    );
  };

  const JoinButton = (props) => {
    return (
      <button
        className="btn btn-outline-success"
        onClick={() => props.handleEnterChat(props.bot.socketId)}
        style={{ width: "100%", height: "100%" }}
      >
        <h4>{props.bot.username}</h4>

        <img
          style={{ width: "100px" }}
          src={`${process.env.PUBLIC_URL}/images/${props.bot.socketId}.jpg`}
        />
      </button>
    );
  };

  const DisconnectButton = (props) => {
    return (
      <button onClick={props.handleDisconnect} className="btn btn-sm btn-light">
        Leave Chat
      </button>
    );
  };

  const ChatControls = (props) => {
    // return null

    if (!props.joined) return null;
    return (
      <React.Fragment>
        <div className="d-grid gap-2">
          <SendButton
            outMessage={props.outMessage}
            handleSendMessage={props.handleSendMessage}
          />
          <DisconnectButton handleDisconnect={handleDisconnect} />
        </div>
      </React.Fragment>
    );
  };

  const ConnectedUsersModule = (props) => {
    // return null
    // const URL = `${REACT_APP_CLIENT_URL}/user-profile/${user._id}`;

    if (props.joined)
      return (
        <React.Fragment>
          <div className={"text-start"}>
            <h5>
              <i className="fa fa-plug" aria-hidden="true"></i> Connected users
            </h5>
            {chatUsers.map((chatUser, i) => (
              <div key={i} className={"text-start"}>
                <Link
                  className="page-link"
                  to={`/user-profile/${chatUser.userId}`}
                >
                  <DisplayProfileImage
                    userId={chatUser.userId}
                    profileImage={chatUser.profileImage}
                    width="30px"
                  />{" "}
                  {chatUser.username}
                </Link>
              </div>
            ))}
          </div>
        </React.Fragment>
      );
  };

  const SelectAiBot = (props) => {
    if (props.joined) return null;
    return (
      <React.Fragment>
        <div className="d-grid gap-2">
          <Grid content={aiBots}>
            {aiBots.map((bot) => (
              <JoinButton
                key={bot.socketId}
                handleEnterChat={props.handleEnterChat}
                bot={bot}
              />
            ))}
          </Grid>
        </div>
      </React.Fragment>
    );
  };

  const DisplayAvatar = () => {
    if (!user?._id) return null;
    return (
      <Link className="" to="/user-profile">
        <img
          src={user.avatar}
          alt=""
          style={{ borderRadius: "50%", width: "50px" }}
        />
      </Link>
    );
  };

  const DisplayProfileImage = ({ userId, profileImage, width }) => {
    // return null;

    const chatUserObj = chatUsers.find(
      (chatUser) => chatUser.userId === userId
    );

    const userProfileImage = profileImage
      ? `${process.env.REACT_APP_SERVER_URL}/users/${userId}/profile-image/${profileImage}`
      : `${process.env.PUBLIC_URL}/images/noprofileimage.jpg`;

    const aiProfileImage = `${process.env.PUBLIC_URL}/images/${chatBotId}.jpg`;

    const finalProfileImage = chatUserObj?.trainingPrompt
      ? aiProfileImage
      : userProfileImage;

    return <img src={finalProfileImage} alt="" style={{ width }} />;
  };

  const SentMessageBubble = (props) => {
    return (
      <>
        <div
          className="d-flex flex-row pb-3 flex-row-reverse"
          style={{
            border: "0px solid #eeeeee",
            borderBottom: "1px dashed #eeeeee",
          }}
        >
          <div className="p-0 flex-grow-1">
            <div className="d-flex flex-row justify-content-start">
              <div className="p-2">
                <h5 className="card-title text-start">
                  <i
                    className="fa fa-arrow-right text-info"
                    aria-hidden="true"
                  ></i>{" "}
                  {props.username}
                </h5>
              </div>
            </div>
            <div className="d-flex flex-row justify-content-start">
              <div className="p-0 flex-grow-1">
                <p className="card-text alert alert-success text-start mb-0">
                  {props.message}
                </p>
              </div>
            </div>
            <div className="d-flex flex-row justify-content-start">
              <div className="p-0">
                <small className="text-muted">{props.datetime}</small>
              </div>
            </div>
          </div>
          <div className="p-3">
            <DisplayProfileImage
              userId={props.userId}
              profileImage={props.profileImage}
              width="50px"
            />
          </div>
        </div>
      </>
    );
  };

  const ReceivedMessageBubble = (props) => {
    return (
      <>
        <div
          className="d-flex flex-row pb-3 justify-content-end"
          style={{
            border: "0px solid #eeeeee",
            borderBottom: "1px dashed #eeeeee",
          }}
        >
          <div className="p-0 flex-grow-1">
            <div className="d-flex flex-row justify-content-end">
              <div className="p-2">
                <h5 className="card-title text-end">{props.username}</h5>
              </div>
            </div>
            <div className="d-flex flex-row justify-content-end">
              <div className="p-0 flex-grow-1">
                <p className="card-text alert alert-primary text-end mb-0">
                  {props.message}
                </p>
              </div>
            </div>
            <div className="d-flex flex-row justify-content-end">
              <div className="p-0">
                <small className="text-muted">{props.datetime}</small>
              </div>
            </div>
          </div>
          <div className="p-3">
            <DisplayProfileImage
              userId={props.userId}
              profileImage={props.profileImage}
              width="50px"
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <React.Fragment>
      <Hero
        title={`Private Chat with AI`}
        bgClass1="layer2"
        bgClass2="layer3"
        textClass="text-dark"
        description={``}
        main="false"
      ></Hero>

      <div className="container">
        <div className="row mb-3">
          <div className="col">
            <div style={{ border: "0px solid #eeeeee" }}></div>
          </div>
        </div>

        <div className="row mb-3">
          <div className="col">
            <div className="mb-5">
              <SelectAiBot
                joined={!!socket}
                handleEnterChat={handleEnterChat}
                outMessage={outMessage}
                handleSendMessage={handleSendMessage}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-lg-6">
            <div className="row mb-3">
              <div className="col">
                {!!socket && (
                  <CardCollapse
                    title="Connected users"
                    btnText="Show/Hide"
                    id="connectedUsers"
                    show={"show"}
                  >
                    <ConnectedUsersModule joined={!!socket} />
                  </CardCollapse>
                )}
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <div className="mb-2">
              {!!socket ? (
                <textarea
                  placeholder="Message..."
                  ref={messageInput}
                  className="form-control"
                  id="outMessage"
                  rows="3"
                  onChange={(e) => setOutMessage(e.target.value)}
                  onKeyUp={handleEnterKeyUp}
                ></textarea>
              ) : (
                ""
              )}
            </div>
            <div className="mb-5">
              <ChatControls
                joined={!!socket}
                handleEnterChat={handleEnterChat}
                outMessage={outMessage}
                handleSendMessage={handleSendMessage}
              />
            </div>

            <div className="">
              {allMessages.map((m, i) => (
                <div
                  key={i}
                  className=" "
                  style={{
                    border: "0px solid red",
                  }}
                >
                  <div className=""></div>
                  <div
                    className=""
                    style={{
                      border: "0px solid blue",
                    }}
                  >
                    <div>
                      {m.socketId === socket?.id ? (
                        <React.Fragment>
                          <SentMessageBubble
                            socketId={m.socketId}
                            username={m.username}
                            userId={m.userId}
                            message={m.message}
                            profileImage={m.profileImage}
                            datetime={DateTime.fromISO(
                              m.sentTime
                            ).toLocaleString(
                              DateTime.DATETIME_MED_WITH_WEEKDAY
                            )}
                          />
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <ReceivedMessageBubble
                            socketid={m.socketId}
                            username={m.username}
                            userId={m.userId}
                            message={m.message}
                            profileImage={m.profileImage}
                            datetime={DateTime.fromISO(
                              m.sentTime
                            ).toLocaleString(
                              DateTime.DATETIME_MED_WITH_WEEKDAY
                            )}
                          />
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                  <div className=""></div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <HelmetNavItems mainTitle="Community" to={`/chat-app`} />
    </React.Fragment>
  );
};

export default ChatAppGPT;
