import React from "react";

const Card = ({ children, corners = "rounded-0" }) => {
  return (
    <React.Fragment>
      <div
        className={` card card-cover h-100 overflow-hidden  ${corners} shadow--sm home border-0 shadow`}
        style={{ border: "1px solid #eaeaea" }}
      >
        <div className="d-flex flex-column h-100  text-shadow-1">
          {children}
        </div>
      </div>
    </React.Fragment>
  );
};
export default Card;
