import React from "react";

import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  WhatsappShareButton,
  WhatsappIcon,
  RedditShareButton,
  RedditIcon,
} from "react-share";

const ShareIcons = ({
  url,
  title,
  category = null,
  size = "32",
  round = true,
  borderRadius = 0,
}) => {
  const twitterHashtags = [category, "imassmedia"];
  const facebookHashtag = `#${category}`;

  const ShareFacebook = () => {
    return (
      <FacebookShareButton url={url} quote={title} hashtag={facebookHashtag}>
        <FacebookIcon size={size} round={round} borderRadius={borderRadius} />
      </FacebookShareButton>
    );
  };

  const ShareTwitter = () => {
    return (
      <TwitterShareButton
        url={url}
        title={title}
        // via={"myvia"}
        hashtags={twitterHashtags}
        // related={"related"}
      >
        <TwitterIcon size={size} round={round} borderRadius={borderRadius} />
      </TwitterShareButton>
    );
  };

  const ShareLinkedin = () => {
    const summary = `Content found on imassmedia.com`;
    const source = `imassmedia.com`;
    return (
      <LinkedinShareButton
        url={url}
        title={title}
        summary={summary}
        source={source}
      >
        <LinkedinIcon size={size} round={round} borderRadius={borderRadius} />
      </LinkedinShareButton>
    );
  };

  const ShareWhatsapp = () => {
    return (
      <WhatsappShareButton url={url} title={title} separator={"\n\n"}>
        <WhatsappIcon size={size} round={round} borderRadius={borderRadius} />
      </WhatsappShareButton>
    );
  };

  const ShareReddit = () => {
    return (
      <RedditShareButton url={url} title={title}>
        <RedditIcon size={size} round={round} borderRadius={borderRadius} />
      </RedditShareButton>
    );
  };

  return (
    <React.Fragment>
      <div className="d-flex flex-row mt-4 mb-0">
        <div className="p-1">
          <i className="fa fa-share-alt" aria-hidden="true"></i> Share
        </div>
      </div>
      <div className="d-flex flex-row mb-3">
        <div className="p-1">
          <ShareFacebook />
        </div>
        <div className="p-1">
          <ShareTwitter />
        </div>
        <div className="p-1">
          <ShareLinkedin />
        </div>
        <div className="p-1">
          <ShareWhatsapp />
        </div>
        <div className="p-1">
          <ShareReddit />
        </div>
      </div>
    </React.Fragment>
  );
};

export default ShareIcons;
