import React from "react";

const TableHeader = ({ sortColumn: sc, onSort, columns }) => {
  const sortColumn = { ...sc };

  const raiseSort = (path) => {
    if (sortColumn.path === path)
      sortColumn.order = sortColumn.order === "asc" ? "desc" : "asc";
    else {
      sortColumn.path = path;
      sortColumn.order = "asc";
    }

    onSort(sortColumn);
  };

  const renderWidth = (column) => column.columnWidth || "";
  const isSortable = (column) => column.isSortable === undefined || false;

  const renderSortIcon = (column) => {
    if (sortColumn.path !== column.path) return null;
    if (sortColumn.order === "asc")
      return <i className="fa fa-sort-asc" aria-hidden="true"></i>;
    return <i className="fa fa-sort-desc" aria-hidden="true"></i>;
  };

  const renderPointer = { cursor: "pointer", border: "0px solid red" };

  return (
    <thead>
      <tr>
        {columns.map((column) => (
          <th
            style={renderPointer}
            key={column.path || column.key}
            onClick={() => isSortable(column) && raiseSort(column.path)}
            width={renderWidth(column)}
          >
            <nobr>
              {column.label} {renderSortIcon(column)}
            </nobr>
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default TableHeader;
