import React, { useState, useEffect, useContext } from "react";
import { ScreenContext } from "../screenContext";
import { NavLink } from "react-router-dom";
import { getGenres } from "../../services/genreService";
import { getArticleCategories } from "../../services/articleCategoryService";
import { getHP } from "../../services/fakeHarryPotterService";
import { getHarryPotterHouse } from "../../services/fakeHarryPotterHouseService";
import Paginaiton from "../common/pagination3";
import { paginate } from "../../utils/paginate";
import MoviesTable from "../movies/moviesTable";
import _ from "lodash";
import SearchBar from "../common/searchBar";
import ListGroupMulti from "../common/listGroupMulti";
import ArticlesTable from "../articles/articlesTable";
import Grid from "../common/grid";
import CardHarryPotter from "../harryPotter/cardHarryPotter";
import Hero from "../hero";
import CardContent from "./cardContent";
import CardCollapse from "../common/cardCollapse";
import NavCardItems from "../common/NavCardItems";

import {
  getContent,
  getContentAdmin,
  deleteContent,
} from "../../services/contentService";
import HelmetNavItems from "../common/helmet/helmetNavItems";

const ItemList = (props) => {
  const { pageYOffset, screenWidth, hamburgerMode } = useContext(ScreenContext);

  const [itemsState, setItems] = useState([]);
  const [moviesState, setMovies] = useState([]);
  const [articlesState, setArticles] = useState([]);
  const [hpState, setHP] = useState([]);

  const [searchQuery, setSearchQuery] = useState("");
  const [categories, setCategories] = useState([]);
  const [pageSize] = useState(18);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentCategory, setCurrentCategory] = useState({});
  const [currentCategories, setCurrentCategories] = useState([]);
  const [sortColumn, setSortColumn] = useState({ path: "_id", order: "asc" });
  const [itemTypeProperties, setItemTypeProperties] = useState({
    listTitle: "",
    categoriesTitle: "",
  });

  const movieTypeProperties = {
    listTitle: `<span><span style="font-weight: 700">Movie reviews</span> posted by the <span style="font-weight: 700">community</span>`,
    categoriesTitle: "Movie Genres",
  };

  const articleTypeProperties = {
    listTitle: `User <span style="font-weight: 700">Articles</span>`,
    categoriesTitle: "Article Categories",
  };

  const hpTypeProperties = {
    listTitle: "Harry Potter Characters",
    listTitle: `<span style="font-weight: 600">Harry Potter</span> characters`,
    categoriesTitle: "Hogwarts Houses",
  };

  const itemsSettingsArr = [];

  itemsSettingsArr["movies"] = {
    getter: props.authority === "admin" ? getContentAdmin : getContent,
    deleter: deleteContent,
    loader: async (getter) => {
      const items = await getter(props.itemType);
      setMovies(items);
      setItems(items);
    },
    itemState: moviesState,
    getCategories: getGenres,
    itemTypeProperties: () => setItemTypeProperties(movieTypeProperties),
  };

  itemsSettingsArr["articles"] = {
    getter: props.authority === "admin" ? getContentAdmin : getContent,
    deleter: deleteContent,
    loader: async (getter) => {
      const items = await getter(props.itemType);
      setArticles(items);
      setItems(items);
    },
    itemState: articlesState,
    getCategories: getArticleCategories,
    itemTypeProperties: () => setItemTypeProperties(articleTypeProperties),
  };

  itemsSettingsArr["hp"] = {
    getter: getHP,
    deleter: null,
    loader: async (getter) => {
      const items = await getter;
      setHP(items);
      setItems(items);
    },
    itemState: hpState,
    getCategories: getHarryPotterHouse,
    itemTypeProperties: () => setItemTypeProperties(hpTypeProperties),
  };

  const itemType = itemsSettingsArr[props.itemType];

  const loadExternalItems = async () => {
    itemType.itemTypeProperties();

    if (itemType.itemState.length) {
      setItems(itemType.itemState);
    } else {
      itemType.loader(itemType.getter);
    }
  };

  const loadExternalCategories = async () => {
    const categories = await itemType.getCategories();
    const categoriesWithSelection = [];
    for (let category of categories) {
      category.selected = false;
      categoriesWithSelection.push(category);
    }
    setCurrentCategories(categoriesWithSelection);
  };

  useEffect(() => {
    loadExternalCategories();
    loadExternalItems();
  }, [props.itemType]);

  const handleDelete = async (item) => {
    const originalItems = [...itemsState];
    const items = itemsState.filter((i) => i._id !== item._id);

    setItems(items);
    try {
      itemType.deleter({ id: item._id, contentType: props.itemType });
      setCurrentPage(1);
    } catch (err) {
      setItems(originalItems);
    }
  };

  const handleLike = (item) => {
    const items = [...itemsState];
    const index = items.indexOf(item);
    items[index] = { ...item };
    items[index].liked = !items[index].liked;
    setItems(items);
  };

  const handleRatingDown = (item) => {
    const items = [...itemsState];
    const index = items.indexOf(item);
    items[index] = { ...item };

    if (items[index].dailyRentalRate >= 0.5)
      items[index].dailyRentalRate = items[index].dailyRentalRate - 0.5;
    setItems(items);
  };

  const handleRatingUp = (item) => {
    const items = [...itemsState];
    const index = items.indexOf(item);
    items[index] = { ...item };

    if (items[index].dailyRentalRate <= 4.5)
      items[index].dailyRentalRate = items[index].dailyRentalRate + 0.5;
    setItems(items);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSort = (sortColumn) => {
    setSortColumn(sortColumn);
  };

  const getPagedData = () => {
    let filtered = itemsState;

    let filteredByGenre = itemsState.filter((item) => {
      let flag = 0;
      currentCategories.forEach((cat) => {
        if (props.itemType === "movies") {
          if (cat?._id === item.genre?._id && cat?.selected) {
            flag = 1;
          }
        }
        if (props.itemType === "articles") {
          if (cat?._id === item.category?._id && cat?.selected) {
            flag = 1;
          }
        }
        if (props.itemType === "hp") {
          if (cat?._id === item.house?._id && cat?.selected) {
            flag = 1;
          }
        }
      });
      return !!flag;
    });

    let flag = 0;
    currentCategories.forEach((cat) => {
      if (cat.selected) flag = 1;
    });

    if (filteredByGenre.length) filtered = filteredByGenre;
    else if (!filteredByGenre.length && flag) filtered = [];
    else filtered = itemsState;

    if (searchQuery) {
      filtered = itemsState.filter((item) =>
        _.includes(item.title.toLowerCase(), searchQuery.toLowerCase())
      );
    }

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);
    const items = paginate(sorted, currentPage, pageSize);
    return { filtered, items };
  };

  const { filtered, items: pagedItems } = getPagedData();

  const handleSeachBoxChange = (query) => {
    //reset category on change
    setSearchQuery(query);
    setCurrentCategory({});
    setCurrentPage(1);

    const filtered = itemsState.filter((item) => _.includes(item.title, query));
  };

  const handleItemsSelect = (items) => {
    setCurrentCategories(items);
    setCurrentPage(1);
  };

  return (
    <React.Fragment>
      <Hero
        title={itemTypeProperties.listTitle}
        bgClass1="layer2"
        bgClass2="layer3"
        textClass="text-dark"
        description=""
        main="false"
        // icon={
        //   props.itemType === "movies"
        //     ? `fa fa-film`
        //     : props.itemType === "articles"
        //     ? `fa fa-newspaper-o`
        //     : `fa fa-magic`
        // }
      >
        <div>
          <div className="d-grid gap-2 d-sm-flex justify-content-sm-center mb-5"></div>
        </div>
      </Hero>
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-3">
            {hamburgerMode !== null && (
              <CardCollapse
                title={`Filter`}
                btnText="Show/Hide Articles"
                id="articles"
                show={!hamburgerMode}
                disable={!hamburgerMode}
              >
                {currentCategories.length ? (
                  <React.Fragment>
                    <ListGroupMulti
                      items={currentCategories}
                      onItemsSelect={handleItemsSelect}
                      itemTypeProperties={itemTypeProperties}
                    />
                  </React.Fragment>
                ) : (
                  "Loading..."
                )}
              </CardCollapse>
            )}
          </div>
          <div className="col-12 col-lg-9">
            {props.authority === "admin" && (
              <NavLink
                className="btn btn-primary"
                to={`/item-form-page/${props.itemType}`}
              >
                New {props.itemType.slice(0, props.itemType.length - 1)}
              </NavLink>
            )}
            <div style={{ marginTop: "10px" }}>
              <SearchBar
                searchQuery={searchQuery}
                handleSeachBoxChange={handleSeachBoxChange}
              />
            </div>
            <div
              style={{ marginTop: "10px", fontSize: ".8rem" }}
              className="text-secondary fw-bold text-uppercase"
            >
              {filtered.length === 0
                ? "There are no items!"
                : `Showing ${filtered.length} items.`}
            </div>
            {props.itemType === "movies" && (
              <React.Fragment>
                {props.authority === "admin" && (
                  <MoviesTable
                    items={pagedItems}
                    onDelete={handleDelete}
                    onLike={handleLike}
                    onRatingDown={handleRatingDown}
                    onRatingUp={handleRatingUp}
                    onSort={handleSort}
                    sortColumn={sortColumn}
                  />
                )}

                {props.authority === "none" && (
                  <Grid content={pagedItems}>
                    {pagedItems.map((content) => (
                      <CardContent
                        key={`${content._id}`}
                        content={content}
                        itemType={props.itemType}
                      />
                    ))}
                  </Grid>
                )}
              </React.Fragment>
            )}

            {props.itemType === "articles" && (
              <React.Fragment>
                {props.authority === "admin" && (
                  <ArticlesTable
                    items={pagedItems}
                    onDelete={handleDelete}
                    onSort={handleSort}
                    sortColumn={sortColumn}
                  />
                )}
                {props.authority === "none" && (
                  <Grid content={pagedItems}>
                    {pagedItems.map((content) => (
                      <CardContent
                        key={content._id}
                        content={content}
                        itemType={props.itemType}
                      />
                    ))}
                  </Grid>
                )}
              </React.Fragment>
            )}

            {props.itemType === "hp" && (
              <React.Fragment>
                {props.authority === "none" && (
                  <Grid content={pagedItems}>
                    {pagedItems.map((content) => (
                      <CardHarryPotter key={content._id} content={content} />
                    ))}
                  </Grid>
                )}
              </React.Fragment>
            )}

            <Paginaiton
              itemsCount={filtered.length}
              pageSize={pageSize}
              onPageChange={handlePageChange}
              currentPage={currentPage}
            />
          </div>
        </div>
        <div className="row">
          <div className="col"></div>
        </div>
      </div>

      {props.authority === "admin" ? (
        <HelmetNavItems
          mainTitle="Admin"
          to={`/items-admin/${props.itemType}`}
        />
      ) : (
        <HelmetNavItems mainTitle="Content" to={`/items/${props.itemType}`} />
      )}
    </React.Fragment>
  );
};

export default ItemList;
