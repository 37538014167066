// import logo from "./logo.svg";
import "./App.css";
import React, { useEffect, useState } from "react";
import { useNavigate, Navigate, Route, Routes } from "react-router-dom";

import { HelmetProvider } from "react-helmet-async";

import { UserContext } from "./components/userContext";
import { ScreenContext } from "./components/screenContext";
import _ from "lodash";
import debounce from "lodash/debounce";
import ProtectedRoute from "./components/protectedRoute";
import NavBar from "./components/navBar/NavBar2";
import Footer from "./components/footer";
import NotFound from "./components/notFound";
import Dashboard from "./components/dashboard/dashboard";
import Home from "./components/home";
import Users from "./components/users";
import UserProfile from "./components/userProfile";
import TodoApp from "./components/todoList/todoApp";
import Tweet from "./comments/tweet";
import CommentList from "./comments/commentList";
import ChatApp from "./components/chatApp/chatApp";
import ItemList from "./components/items/itemList";
import ItemFormPage from "./components/items/itemFormPage";
import HarryPotter from "./components/harryPotter/harryPotter";
import Item from "./components/items/item";

import ItemTest from "./components/items/itemTest";

import FileUploader from "./components/uploader/old/fileUploader";
import ImageResizer from "./components/uploader/old/resizer";
import ImageUploader from "./components/uploader/imageUploader";
import PrivacyPolicy from "./components/legal/privacyPolicy";
import { loginSuccess } from "./services/authService";
import NavCardItems from "./components/common/NavCardItems";

import Game from "./components/game/game";
import ChatGPTPrompt from "./components/ChatGPT/chatGPT";
import ChatAppGPT from "./components/ChatGPT/chatAppGPT";

import GoogleRSS from "./components/rss/googlerss";

const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;

function App() {
  const [user, setUser] = useState({ initialState: true });
  const [pageYOffset, setPageYOffset] = useState(0);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [mediaQueryMode, setMediaQueryMode] = useState("");
  const [hamburgerMode, setHamburgerMode] = useState(null);

  let updatedUsername = "";

  const mobileScreenSizes = ["xs", "sm", "md"];
  const nonMobileScreenSizes = ["lg", "xl"];

  const redirect = sessionStorage.getItem("redirect");
  const navigate = useNavigate();

  useEffect(() => {
    getUser();
    // if (!user?._id) window.addEventListener("scroll", handleScroll);

    //DEBOUNCE WINDOW RESIZE TO REDUCE IMPACT ON PERFORMANCE
    const handleResize = debounce(() => {
      setScreenWidth(window.innerWidth);
    }, 100);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getMediaQueryMode = (screenWidth) => {
    if (screenWidth < 576) return "xs";
    else if (screenWidth >= 576 && screenWidth < 768) return "sm";
    else if (screenWidth >= 768 && screenWidth < 992) return "md";
    else if (screenWidth >= 992 && screenWidth < 1200) return "lg";
    else if (screenWidth >= 1200 && screenWidth < 1400) return "xl";
    else return "xxl";
  };

  useEffect(() => {
    setMediaQueryMode(getMediaQueryMode(screenWidth));
    // console.log("RESIZE screenWidth:", screenWidth);
  }, [screenWidth]);

  useEffect(() => {
    mediaQueryMode &&
      setHamburgerMode(mobileScreenSizes.includes(mediaQueryMode));
  }, [mediaQueryMode]);

  useEffect(() => {
    if (user.updateUsername) {
      updatedUsername = user.updateUsername;
      const userCopy = { ...user };
      userCopy.updatedUsername = undefined;
      setUser(userCopy);
      getUser();
    }

    //CLEAR SESSION REDIRECT AFTER REDIRECTING
    if (redirect) {
      navigate(redirect);
      sessionStorage.removeItem("redirect");
    }
    console.log("LOGGED IN AS:", user);
  }, [user]);

  const ScrollToTop = () => {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    return null;
  };

  const getUser = async () => {
    setUser({ initialState: false });
    const data = await loginSuccess(updatedUsername);
    data?.failed !== "true" && handleLogin(data);
  };

  function handleScroll() {
    setPageYOffset(window.pageYOffset);
  }

  const handleLogin = (resObject) => {
    const userProps = {};
    userProps.initialState = false;
    userProps._id = resObject.user?._id;
    userProps.username = resObject.user?.username;
    userProps.platformName = resObject.user?.platformName;
    userProps.platform = resObject.user?.googleId ? "Google" : "Facebook";
    userProps.socialId = resObject.user?.googleId || resObject.user?.facebookId;
    userProps.avatar =
      resObject.user?.googlePicture || resObject.user?.facebookPicture;

    userProps.profileImage = resObject.user?.profileImage;

    userProps.createdAt = resObject.user?.createdAt;
    userProps.roles = [...resObject.user?.roles];
    userProps.permissions = [...resObject.user?.permissions];
    userProps.memberSince = resObject.user?.createdAt;

    setUser(userProps);
  };

  return (
    <HelmetProvider>
      <React.Fragment>
        <ScrollToTop />
        <UserContext.Provider value={{ user, setUser }}>
          <ScreenContext.Provider
            value={{ pageYOffset, screenWidth, mediaQueryMode, hamburgerMode }}
          >
            <NavBar />

            <main className="container"></main>

            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/prompt/:mode" element={<Home />} />
              <Route path="/game" element={<Game />} />
              <Route path="/file-uploader" element={<FileUploader />} />
              <Route path="/image-resizer" element={<ImageResizer />} />
              <Route path="/image-uploader" element={<ImageUploader />} />
              <Route path="/harry-potter" element={<HarryPotter />} />

              {/* CURRENTLY ONLY USING SOCIAL MEDIA LOGIN/REGISTER */}
              {/* <Route
            element={<ProtectedRoute redirectPath="/" isAllowed={!user._id} />}
          >
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
          </Route> */}

              <Route
                element={
                  <ProtectedRoute
                    redirectPath="/prompt/2"
                    isAllowed={!!user._id && user.roles.includes("admin")}
                  />
                }
              >
                <Route
                  path="/items-admin/movies"
                  element={<ItemList itemType="movies" authority="admin" />}
                />
                <Route
                  path="/items-admin/articles"
                  element={<ItemList itemType="articles" authority="admin" />}
                />

                <Route
                  path="/item-form-page/:item"
                  element={<ItemFormPage authority="admin" />}
                />

                <Route
                  path="/item-form-page/:item/:id"
                  element={<ItemFormPage authority="admin" />}
                />
              </Route>

              <Route
                element={
                  <ProtectedRoute
                    redirectPath="/prompt/1"
                    isAllowed={!!user._id}
                  />
                }
              >
                <Route path="/dashboard" element={<Dashboard />} />
              </Route>

              <Route
                element={
                  <ProtectedRoute
                    redirectPath="/prompt/1"
                    isAllowed={!!user._id}
                  />
                }
              >
                <Route path="/users" element={<Users />} />
                <Route path="/todo-app" element={<TodoApp />} />
                <Route path="/chat-app" element={<ChatApp />} />
                <Route path="/chat-app-gpt" element={<ChatAppGPT />} />
              </Route>

              <Route path="/tweets" element={<Tweet />} />
              <Route path="/comments" element={<CommentList />} />
              <Route path="/user-profile/:id" element={<UserProfile />} />

              <Route
                path="/items/movies"
                element={<ItemList itemType="movies" authority="none" />}
              />

              <Route
                path="/items/articles"
                element={<ItemList itemType="articles" authority="none" />}
              />

              <Route
                path="/items/hp"
                element={<ItemList itemType="hp" authority="none" />}
              />

              <Route path="/legal/privacypolicy" element={<PrivacyPolicy />} />

              <Route path="/googlerss" element={<GoogleRSS />} />

              {/* <Route path="/chatgpt" element={<ChatGPTPrompt />} /> */}

              <Route path="/:item/:id" element={<Item />} />

              <Route path="/:item/slug/:id" element={<Item />} />
              {/* <Route path="/movie/:id" element={<Movie />} />
          <Route path="/article/:id" element={<Article />} /> */}
              {/* <Route path="/articles/test/:id" element={<ItemTest />} />
              <Route path="/api/v1/articles/test/:id" element={<ItemTest />} /> */}

              <Route path="/share/:item/:id" element={<Item />} />

              <Route path="*" element={<NotFound />} />
              {/* <Route path="/" element={<Navigate to="/movies" replace />} /> */}
            </Routes>
            <NavCardItems />
            <Footer />
          </ScreenContext.Provider>
        </UserContext.Provider>
      </React.Fragment>
    </HelmetProvider>
  );
}

export default App;
