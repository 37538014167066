import React from "react";

const CardNav = ({ children, corners = "rounded-0" }) => {
  return (
    <React.Fragment>
      <div className="d-flex flex-column border" style={{ height: "100%" }}>
        <div className="" style={{ height: "100%" }}>
          {children}
        </div>
        <div className="p-1 bg-light"></div>
      </div>
    </React.Fragment>
  );
};
export default CardNav;
