const REACT_APP_SERVER_URL = `${process.env.REACT_APP_SERVER_URL}/api/v1`;

export const categories = [
  { _id: "5b21ca3eeb7f6fbccd471818", name: "Actionx" },
  { _id: "5b21ca3eeb7f6fbccd471814", name: "Comedy" },
  { _id: "5b21ca3eeb7f6fbccd471820", name: "Thriller" },
];

export async function getGenres() {
  let items = [];
  const URL = `${REACT_APP_SERVER_URL}/genres`;

  try {
    const response = await fetch(URL);
    const data = await response.json();

    items = [...data];
  } catch (err) {
    console.log("Error:", err);
  }

  return items.filter((g) => g);
}
