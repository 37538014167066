import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Link } from "react-router-dom";

const Pagination = ({ itemsCount, pageSize, currentPage, onPageChange }) => {
  const pagesCount = Math.ceil(itemsCount / pageSize);
  const [remainingPages, setRemainingPages] = useState(pagesCount);
  const [centerPages, setCenterPages] = useState([]);
  const pages = _.range(1, pagesCount + 1);
  const pageCountLeftRight = 5;

  const buildPageList = () => {
    let x = [];

    //IF CURRENT PAGE LESS THAN 5
    //SHOW FIRST 5 PAGES (1-5) AND LAST PAGE
    if (currentPage < pageCountLeftRight) {
      x = _.dropRight([...pages], pages.length - pageCountLeftRight);
      x = _.concat(x, "...", pages.length);
    }

    //IF CURRENT PAGE GREATER THAN PAGE COUNT MINUS 5
    //SHOW FIRST PAGE AND LAST 5 PAGES
    if (currentPage > pagesCount - pageCountLeftRight) {
      x = _.drop([...pages], pages.length - pageCountLeftRight);
      x = _.concat(pages[0], "...", x);
    }

    //IF CURRENT PAGE GREATER OR EQ TO 5
    //AND CURRENT PAGE LESS OR EQ TO PAGE COUNT MINUS 5
    //SHOW FIRST PAGE, CURRENT PAGE-1, CURRENT PAGE, CURRENT PAGE+1
    if (
      currentPage >= pageCountLeftRight &&
      currentPage <= pagesCount - pageCountLeftRight
    ) {
      x = [currentPage - 1, currentPage, currentPage + 1];
      x = _.concat(pages[0], "...", x, "...", pages.length);
    }

    //IF 7 OR LESS PAGES THEN JUST LIST THOSE PAGES
    if (pagesCount <= 7) {
      x = [...pages];
    }

    setCenterPages([...x]);
  };

  useEffect(() => {
    setRemainingPages(pages.length - currentPage);
    buildPageList();
  }, [currentPage, pagesCount]);

  useEffect(() => {
    buildPageList();
  }, [remainingPages]);

  if (pagesCount <= 1) return null;

  return (
    <React.Fragment>
      <nav aria-label="Page navigation">
        <ul className="pagination">
          {/* LEFT NAV */}
          <li
            key={"previous"}
            className={"page-item"}
            onClick={() => onPageChange(currentPage > 1 ? currentPage - 1 : 1)}
          >
            <Link className="page-link" to="#">
              <span aria-hidden="true">&laquo;</span>
            </Link>
          </li>

          {centerPages.map((page, i) => {
            // DISPLAY PAGE NUMBERS
            if (page !== "...") {
              return (
                <li
                  key={i}
                  className={
                    currentPage === page ? "page-item active" : "page-item"
                  }
                  onClick={() => onPageChange(page)}
                >
                  <Link className="page-link" to="#">
                    {page}
                  </Link>
                </li>
              );
            } else {
              // DISPLAY ELIPSES
              return (
                <li
                  key={i}
                  className={``}
                  style={{ border: "0px solid black" }}
                >
                  <div
                    style={{
                      border: "0px solid red",
                      height: "100%",
                      position: "relative",
                    }}
                    className="px-3"
                  >
                    <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
                  </div>
                </li>
              );
            }
          })}

          {/* SHOW DROPDOWN IF 8+ PAGES */}
          {pages.length > 7 && (
            <li className={"page-item"}>
              <div className="btn-group" role="group">
                <button
                  type="button"
                  className="page-link dropdown-toggle rounded-0"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  All
                </button>
                <ul className="dropdown-menu">
                  {pages.map((page) => (
                    <li
                      key={`all-${page}`}
                      className={"page-item"}
                      onClick={() => onPageChange(page)}
                    >
                      <a
                        className={`dropdown-item ${
                          currentPage === page && "active"
                        }`}
                        href="#"
                      >
                        Page {page}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </li>
          )}
          {/* RIGHT NAV */}
          <li
            key={"next"}
            className={"page-item"}
            onClick={() =>
              onPageChange(
                currentPage < pages.length ? currentPage + 1 : pages.length
              )
            }
          >
            <Link className="page-link" to="#">
              <span aria-hidden="true">&raquo;</span>
            </Link>
          </li>
        </ul>
      </nav>
    </React.Fragment>
  );
};

Pagination.propTypes = {
  itemsCount: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default Pagination;
