import React, { useState, useEffect, useContext, useRef } from "react";
import { useParams } from "react-router-dom";
import Tweet from "../../comments/tweet";
import { UserContext } from "../userContext";
import _ from "lodash";
import Hero from "../hero";
import UserCard from "../common/UserCard";
import DateFormatter from "../common/dateFormat";
import ShareIcons from "../common/shareIcons/share";
import { getContentItem } from "../../services/contentService";
import { Helmet } from "react-helmet-async";
import NavCardItems from "../common/NavCardItems";

const Item = () => {
  const REACT_APP_SERVER_URL = `${process.env.REACT_APP_SERVER_URL}`;
  const { id } = useParams();
  const { item: typeParam } = useParams();
  const [item, setItem] = useState({});
  const { user, setUser } = useContext(UserContext);
  const [itemState, setItemState] = useState({});
  const itemContent = useRef();
  const itemTypeProperties = [];

  itemTypeProperties["articles"] = {
    getter: getContentItem,
    categoryLabel: "category",
    loader: async (getter) => {
      const item = await getter({ id: id, contentType: typeParam });
      setItemState(item);
    },
  };

  itemTypeProperties["movies"] = {
    getter: getContentItem,
    categoryLabel: "genre",
    loader: async (getter) => {
      const item = await getter({ id: id, contentType: typeParam });
      setItemState(item);
    },
  };

  const ContentDates = ({ posted, edited }) => {
    return (
      <React.Fragment>
        <div className="" style={{ fontSize: ".8rem" }}>
          <div className="fw-light text-uppercase">
            <span>
              <span>Posted: </span>
              <DateFormatter date={posted} />
            </span>
          </div>
          {posted !== edited && (
            <div className="fw-light text-uppercase">
              <span>
                <span>Edited: </span>
                <DateFormatter date={edited} />
              </span>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  };

  const itemType = itemTypeProperties[typeParam];
  const setCurrentItem = async () => itemType.loader(itemType.getter);

  const convertStringToHtml = (str) => {
    str = str.replace(/\n/g, "<br />");
    const txt = new DOMParser().parseFromString(
      `<div id="xbody">${str}</div>`,
      "text/html"
    );
    itemContent.current.innerHTML = txt.getElementById("xbody").outerHTML;
  };

  useEffect(() => {
    console.log("XXXXXXXXXXXXXXXXXXXXXXCCCCCCCCCCCCCCCCCCCCCCCCCCCCC");
  }, []);

  useEffect(() => {
    setCurrentItem();
  }, []);

  useEffect(
    () => itemState._id && convertStringToHtml(itemState.mainContent),
    [itemState]
  );

  return (
    <React.Fragment>
      {itemState._id ? (
        <React.Fragment>
          <Hero
            title={itemState.title}
            bgClass1="layer2"
            bgClass2="layer3"
            textClass="text-dark"
            description={
              typeParam === "article"
                ? itemState.category?._id &&
                  `Articles: ${itemState.category?.name}`
                : typeParam === "movie"
                ? itemState.genre?._id && `Movies: ${itemState.genre?.name}`
                : ""
            }
            main="false"
          ></Hero>

          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-3">
                <div></div>
                <div>
                  {typeParam === "article" && (
                    <p>
                      {itemState.category._id &&
                        `Articles: ${itemState.category?.name}`}
                    </p>
                  )}
                  {typeParam === "movie" && (
                    <p>
                      {itemState.genre._id &&
                        `Movies: ${itemState.genre?.name}`}
                    </p>
                  )}
                </div>
                <div>
                  <span>
                    <UserCard user={itemState.creatorId} />
                  </span>
                </div>
                <ContentDates
                  posted={itemState.createdAt}
                  edited={itemState.updatedAt}
                />

                <div className="d-flex flex-row justify-content-start">
                  <div>
                    {/* <img
                      src={`/images/social-media-share-buttons.jpg`}
                      alt=""
                      width="200px"
                    /> */}
                    <ShareIcons
                      url={`${window.location.origin}/share/${typeParam}/${id}`}
                      title={itemState.title}
                      category={itemState[itemType.categoryLabel]?.name}
                    />
                  </div>
                </div>
              </div>
              <div className="col-12  col-lg-9">
                <div className="row">
                  <div className="col">
                    {itemState.mainImage && (
                      <img
                        src={`${REACT_APP_SERVER_URL}/content/${typeParam}/${itemState._id}/${itemState.mainImage}`}
                        style={{
                          float: "left",
                          padding: "0 15px 10px 0",
                        }}
                        className="img-fluid content-main-image"
                      />
                    )}
                    <div
                      style={{ padding: "0" }}
                      id="itemContent"
                      ref={itemContent}
                      className="container mb-5"
                    ></div>
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    {/* <Tweet
                      user={user}
                      URL={`/tweets/${user._id}`}
                      contentId={id}
                      contentType={typeParam}
                    /> */}

                    <Tweet contentId={id} contentType={typeParam} />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col"></div>
            </div>
          </div>
        </React.Fragment>
      ) : (
        "Loading..."
      )}

      <Helmet>
        <title>{itemState.title}</title>

        <meta name="og:title" content={itemState.title} data-rh="true" />
        <meta
          name="og:description"
          content="Content posted on imassmedia.com"
          data-rh="true"
        />
        <meta
          name="og:image"
          content={`${REACT_APP_SERVER_URL}/content/${typeParam}s/${itemState._id}/${itemState.mainImage}`}
        />
      </Helmet>
    </React.Fragment>
  );
};

export default Item;
