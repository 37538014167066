const navBarData = [
  {
    index: 5,
    mainTitle: "Home",
    mainIcon: "fa fa-home",
    links: [
      {
        title: "Home Page",
        to: "/",
        linkDescription: "Main page of the site",
        icon: "fa fa-home",
      },
    ],
    additionalInfo: ``,
    authenticate: false,
  },
  {
    index: 0,
    mainTitle: "Content",
    mainIcon: "fa fa-th",
    links: [
      {
        title: "Movies",
        to: "/items/movies",
        linkDescription: "Movies posted by users",
        icon: "fa fa-film",
      },

      // {
      //   title: "Reviews",
      //   to: "/items/reviews",
      //   linkDescription: "Reviews posted by users",
      //   icon: "fa fa-film",
      // },
      {
        title: "Articles",
        to: "/items/articles",
        linkDescription: "Articles posted by users",
        icon: "fa fa-newspaper-o",
      },
      {
        title: "Harry Potter",
        to: "/items/hp",
        linkDescription: "Harry Potter Characters",
        icon: "fa fa-magic",
      },
    ],
    additionalInfo: ``,
    authenticate: false,
  },

  {
    index: 1,
    mainTitle: "Community",
    mainIcon: "fa fa-users",
    links: [
      {
        title: "Chat",
        to: "/chat-app",
        linkDescription: "Chat with other users",
        icon: "fa fa-comments-o",
      },
      {
        title: "Discussions",
        to: "/comments",
        linkDescription: "View and Post",
        icon: "fa fa-comments-o",
      },
      {
        title: "Users",
        to: "/users",
        linkDescription: "Check out the users",
        icon: "fa fa-users",
      },
    ],
    additionalInfo: ``,
    authenticate: false,
  },

  {
    index: 2,
    mainTitle: "Tools",
    mainIcon: "fa fa-briefcase",
    links: [
      {
        title: "ToDo List",
        to: "/todo-app",
        linkDescription: "Your private To-do List",
        icon: "fa fa-briefcase",
      },
    ],
    additionalInfo: `More tools to come!`,
    authenticate: true,
  },

  {
    index: 3,
    mainTitle: "Admin",
    mainIcon: "fa fa-keyboard-o",
    links: [
      {
        title: "Movies Admin",
        to: "/items-admin/movies",
        linkDescription: "Manage your movies",
        icon: "fa fa-film",
      },
      {
        title: "Articles Admin",
        to: "/items-admin/articles",
        linkDescription: "Manage your articles",
        icon: "fa fa-newspaper-o",
      },
    ],
    additionalInfo: ``,
    authenticate: true,
    admin: true,
  },

  {
    index: 4,
    mainTitle: "Dashboard",
    mainIcon: "fa fa-tachometer",
    links: [
      {
        title: "My Dashboard",
        to: "/dashboard",
        linkDescription: "Manage your account",
        icon: "fa fa-tachometer",
      },
    ],
    additionalInfo: ``,
    authenticate: true,
  },
  // {
  //   index: 7,
  //   mainTitle: "Test",
  //   mainIcon: "fa fa-tachometer",
  //   links: [
  //     {
  //       title: "My Test",
  //       to: "/test/63b88e41f5a0cb48a3ad9454",
  //       linkDescription: "For Testing",
  //       icon: "fa fa-tachometer",
  //     },
  //   ],
  //   additionalInfo: ``,
  //   authenticate: false,
  // },

  //   {
  //     index: 6,
  //     mainTitle: "Contact",
  //     mainIcon: "fa fa-envelope",
  //     links: [
  //       {
  //         title: "Contact",
  //         to: "/dashboard",
  //         linkDescription: "Contact Arlo @ iMassMedia",
  //       },
  //     ],
  //     additionalInfo: `I'd love to hear from you.  Feel free to reach out to me @ arlo.hart@gamil.com`,
  //     authenticate: false,
  //   },
];

export function getNavItems() {
  return navBarData;
}

export async function getNavItemByMainTitle(mainTitleValue, to) {
  const item = navBarData.filter((item) => item.mainTitle === mainTitleValue);
  const subItem = item[0].links.filter((i) => i.to === to);
  return subItem[0];
}
