import React, { useState, useRef, useEffect, useContext } from "react";
import { UserContext } from "../userContext";
import TodoList from "./todoList";
import {
  getTodos,
  saveTodo,
  updateTodo,
  updateClearTodos,
  deleteTodo,
} from "../../services/todoService";
import Hero from "../hero";
import HelmetNavItems from "./../common/helmet/helmetNavItems";

const TodoApp = () => {
  const { user, setUser } = useContext(UserContext);
  const [todoList, setTodoList] = useState([]);
  const [newItemText, setNewItemText] = useState("");
  const [anyItemsChecked, setAnyItemsChecked] = useState(false);
  const todoNameRef = useRef();

  const loadTodos = async () => {
    const todos = await getTodos(user._id);
    todos.length && setTodoList(todos);
  };

  useEffect(() => {
    setAnyItemsChecked(
      todoList.some((item) => item.completed && item.cleared == false)
    );
  }, [todoList]);

  useEffect(() => {
    loadTodos();
  }, []);

  const toggleTodoCheckBox = async (e) => {
    const toBuild = {
      _id: e._id,
      description: e.description,
      completed: !e.completed,
      cleared: e.cleared,
      userId: e.userId,
    };

    const todoListCopy = [...todoList];
    const index = todoListCopy.findIndex((item) => item._id === toBuild._id);
    todoListCopy[index] = toBuild;

    setTodoList(todoListCopy);
    const updatedResult = await updateTodo(toBuild);
    updatedResult?._id && loadTodos();
  };

  const handleAddTodo = async (e) => {
    e.preventDefault();

    if (!newItemText) return null;

    const todoItem = {
      description: e.target["item"].value,
      completed: false,
      cleared: false,
      user: user._id,
    };

    todoNameRef.current.value = "";

    setTodoList([...todoList, todoItem]);
    const newItem = await saveTodo(todoItem);
    newItem?._id && loadTodos();
    setNewItemText("");
  };

  const handleCompletedTodoDelete = async (e) => {
    e.preventDefault();
    const todoListCopy = [...todoList];
    const checkedItems = todoListCopy.filter((item) => item.completed);
    const ids = [];
    checkedItems.forEach((item) => {
      ids.push(item._id);
    });

    const todoListCopy2 = [...todoList];
    todoListCopy2.forEach((item) => {
      item.cleared = item.completed;
    });

    setTodoList(todoListCopy2);
    const updateReport = await updateClearTodos(ids);

    updateReport.modifiedCount && loadTodos();
  };

  const doDeleteTodoItem = async (itemToDelete) => {
    const todoListCopy = [...todoList];
    const index = todoListCopy.findIndex(
      (item) => item._id === itemToDelete._id
    );
    todoListCopy.splice(index, 1);

    setTodoList(todoListCopy);
    const deleteReport = await deleteTodo(itemToDelete._id);
    deleteReport.deletedCount > 0 && loadTodos();
  };

  return (
    <React.Fragment>
      <Hero
        title={`ToDo List`}
        bgClass1="layer2"
        bgClass2="layer3"
        textClass="text-dark"
        description={`A todo app to help keep your ideas together.`}
        icon={` text-secondary fa fa-list-alt`}
      >
        <div>
          <div className="d-grid gap-2 d-sm-flex justify-content-sm-center mb-5"></div>
        </div>
      </Hero>

      <form onSubmit={(e) => handleAddTodo(e)}>
        <div className="container">
          <div className="row">
            <div className="col">
              <TodoList
                todoList={todoList}
                toggleTodoCheckBox={(e) => toggleTodoCheckBox(e)}
                doDeleteTodoItem={(e) => doDeleteTodoItem(e)}
                anyItemsChecked={anyItemsChecked}
                handleCompletedTodoDelete={handleCompletedTodoDelete}
              />
            </div>
          </div>

          <div className="row mt-5">
            <div className="col">
              <input
                ref={todoNameRef}
                name="item"
                id="item"
                className="form-control"
                onChange={(e) => setNewItemText(e.target.value)}
              />
            </div>
          </div>

          <div className="row mt-3">
            <div className="col">
              <div className="d-grid gap-2">
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={!newItemText}
                >
                  Add item
                </button>
              </div>
              <span className="m-3">
                <h5>
                  <span className="badge bg-secondary">
                    Unchecked items{" "}
                    <span className="badge text-bg-primary">
                      {todoList.filter((t) => t.completed === false).length}
                    </span>
                  </span>
                </h5>
              </span>
            </div>
          </div>
        </div>
      </form>
      <HelmetNavItems mainTitle="Tools" to={`/todo-app`} />
    </React.Fragment>
  );
};

export default TodoApp;
