import React, { useContext, useState, useEffect, useRef } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";

import { UserContext } from "../userContext";
import { ScreenContext } from "../screenContext";
import TBSModal from "../common/modalLogin";
import { getNavItems } from "./navBarJson";

import _ from "lodash";

import * as bootstrap from "bootstrap";
import ProfileImage from "../dashboard/profileImage";
import {
  DisplayLogInButton,
  DisplayLogOutButton,
  DisplayFacebookButton,
  DisplayGoogleButton,
} from "./../common/buttons";
window.bootstrap = bootstrap;

const NavBar = () => {
  const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;

  // ScreenContext
  // value={{ pageYOffset, screenWidth, mediaQueryMode, hamburgerMode }}

  // const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  // const [mediaQueryMode, setMediaQueryMode] = useState("");
  // const [hamburgerMode, setHamburgerMode] = useState(null);

  const { user, setUser } = useContext(UserContext);
  const { hamburgerMode } = useContext(ScreenContext);
  const { username, avatar, _id: id } = user;
  const [navBgColor, setNavBgColor] = useState("bg-dark");
  const [navTextColor, setNavTextColor] = useState("navbar-dark");
  const [navDropdownMenuColor, setNavDropdownMenuColor] =
    useState("dropdown-menu-dark");
  const [navLogInButtonClass, setnNavLogInButtonClass] =
    useState("btn-outline-light");

  const [navItems, setNavItems] = useState([]);

  const [show, setShow] = useState([]);

  const dropdownBg = useRef();

  const isShown = () => {
    for (let i = 0; i < show.length; i++) {
      if (show[i] === true) {
        return i;
      }
    }
    return false;
  };

  const navigate = useNavigate();

  useEffect(() => {
    setNavItems(getNavItems());
  }, []);

  // useEffect(() => {
  //   console.log("hamburgerMode", hamburgerMode);
  // }, [hamburgerMode]);

  // useEffect(() => handleNavChangeOnScroll(), [pageYOffset]);
  // useEffect(() => console.log("screenWidth", screenWidth), [screenWidth]);
  // useEffect(() => console.log("pageYOffset0", pageYOffset), [pageYOffset]);

  useEffect(() => {
    setNavBgColor();
    handleNavChangeOnScroll();
  }, [user]);

  const handleNavChangeOnScroll = () => {
    // if (user?._id) {
    //   //USER IS LOGGED IN
    //   if (pageYOffset > 100) {
    //     setNavBgColor("bg-light");
    //     setNavTextColor("navbar-light");
    //     setNavDropdownMenuColor("bg-light");
    //   } else {
    //     setNavBgColor("bg-dark");
    //     setNavTextColor("navbar-dark");
    //     setNavDropdownMenuColor("dropdown-menu-dark");
    //   }
    // } else {
    //   //USER IS NOT LOGGED IN
    //   if (pageYOffset > window.innerHeight) {
    //     setNavBgColor("bg-light");
    //     setNavTextColor("navbar-light");
    //     setnNavLogInButtonClass("btn-outline-dark");
    //     setNavDropdownMenuColor("bg-light");
    //   } else {
    //     setNavBgColor("bg-dark");
    //     setNavTextColor("navbar-dark");
    //     setnNavLogInButtonClass("btn-outline-light");
    //     setNavDropdownMenuColor("dropdown-menu-dark");
    //   }
    // }

    setNavBgColor("bg-dark");
    setNavTextColor("navbar-dark");
    setNavDropdownMenuColor("dropdown-menu-dark");
  };

  const handleDropdownMouseOver = (index) => {
    const copyShow = [...show];
    copyShow.forEach((item, index, arr) => {
      arr[index] = false;
    });

    copyShow[index] = true;
    setShow(copyShow);
    // console.log("hamburgerMode", hamburgerMode);
    !hamburgerMode && resizeDropdownBg(index);
  };

  const handleDropdownMouseOut = (index) => {
    const copyShow = [...show];
    copyShow.forEach((item, index, arr) => {
      arr[index] = false;
    });
    setShow(copyShow);
  };

  const NavDropdown = (items) => {
    return (
      <li className="nav-item dropdown" key={items.index} style={{}}>
        {!hamburgerMode ? (
          <a
            // className="nav-link dropdown-toggle"
            className="nav-link fw-light"
            href="#"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            onMouseEnter={() => handleDropdownMouseOver(items.index)}
            // onMouseLeave={() => handleDropdownMouseOut(items.index)}
            // onMouseMove={(e) => handleMouseMove(e)}
            onClick={(e) =>
              items.links.length === 1 && navigate(items.links[0].to)
            }
            style={{}}
          >
            <span>
              <i
                // className="fa fa-list-alt"
                className={items.mainIcon}
                aria-hidden="true"
                style={{
                  fontSize: ".7rem",
                  color:
                    navBgColor === "bg-dark"
                      ? show[items.index]
                        ? "#a9333d"
                        : "#62BEC1"
                      : show[items.index]
                      ? "#a9333d"
                      : "#999999",
                }}
              ></i>
              {"  "}
            </span>
            <span
              style={{
                color:
                  navBgColor === "bg-dark"
                    ? show[items.index]
                      ? "#f2af29"
                      : "#EEEEEE"
                    : show[items.index]
                    ? "#f2af29"
                    : "#999999",

                fontSize: ".7rem",
                letterSpacing: "3px",
              }}
            >
              {items.mainTitle}
            </span>
          </a>
        ) : (
          <div style={{ marginBottom: "15px" }}></div>
        )}
        <ul
          onMouseEnter={() => handleDropdownMouseOver(items.index)}
          onMouseLeave={() => handleDropdownMouseOut(items.index)}
          className={`dropdown-menu ${navDropdownMenuColor} ${
            show[items.index] ? "dropdown-menu-show" : "dropdown-menu-hide"
          }`}
          style={{}}
          id={`id-${items.index}`}
        >
          <div className="row" style={{ border: "0px red solid" }}>
            <div className="col-xs-1 col-sm-3 text-xs-start text-md-end pt-2 dropdown-header  ">
              <span>
                <i
                  className={`${items.mainIcon}`}
                  aria-hidden="true"
                  style={{ fontSize: "1.5rem", color: "#62BEC1" }}
                ></i>{" "}
              </span>
              <span
                className="m-2 fw-light text-uppercase"
                style={{ color: "#f2af29", fontSize: "1.5rem" }}
              >
                {items.mainTitle}
              </span>
            </div>
            <div className="col-xs-11 col-sm-6">
              {items.links.map((item, i) => (
                <div key={i} className="row">
                  <div className="col">
                    <li>
                      <span className="dropdown-item dropdown-seperator">
                        {/* SHOW LINKS FOR MOBILE - d-lg-none */}
                        <NavLink
                          className="nav-link d-lg-none"
                          to={item.to}
                          onClick={closeNav}
                          style={{ color: "white" }}
                        >
                          <i className="fa fa-cube" aria-hidden="true"></i>{" "}
                          <span className="fw-light">{item.title}</span>
                          <span
                            style={{
                              marginRight: "10px",
                              marginLeft: "10px",
                            }}
                          >
                            |
                          </span>
                          <span
                            className="text-end fw-light"
                            style={{
                              fontSize: ".7rem",
                              width: "50%",
                              border: "0px solid red",
                              letterSpacing: "1px",
                            }}
                          >
                            {item.linkDescription}
                          </span>
                        </NavLink>

                        {/* SHOW LINKS FOR NON-MOBILE - d-none d-lg-block */}
                        <NavLink
                          className="nav-link d-none d-lg-block"
                          to={item.to}
                          style={{ color: "white" }}
                        >
                          <i className="fa fa-cube" aria-hidden="true"></i>{" "}
                          <span className="fw-light">{item.title}</span>
                          <span
                            style={{
                              marginRight: "10px",
                              marginLeft: "10px",
                            }}
                          >
                            |
                          </span>
                          <span
                            className="text-end fw-light"
                            style={{
                              fontSize: ".7rem",
                              width: "50%",
                              border: "0px solid red",
                              letterSpacing: "1px",
                            }}
                          >
                            {item.linkDescription}
                          </span>
                        </NavLink>
                      </span>
                    </li>
                  </div>
                </div>
              ))}
            </div>
            <div className="col-xs-12 col-sm-3 fw-light text-white-50">
              <div className="dropdown-additional-info">
                {items.additionalInfo}
              </div>
            </div>
          </div>
        </ul>
      </li>
    );
  };

  const dummyUser1 = () => {
    const userProps = {};
    userProps.initialState = false;
    userProps._id = "999999999999";
    userProps.username = "Dummy Username";
    userProps.platformName = "Dummy Facebook Name";
    userProps.platform = "Facebook";
    userProps.socialId = "99998888";
    userProps.avatar =
      "https://lh3.googleusercontent.com/a/ALm5wu3hdWfS3us-woBo6gRUbca2EFYk-hyYTuXDH7NR=s96-c";
    userProps.createdAt = "2022-11-10T15:43:48.228+00:00";
    userProps.roles = ["admin"];
    userProps.permissions = ["analyze"];
    userProps.memberSince = "2022-11-10T15:43:48.228+00:00";

    setUser(userProps);
  };

  const resizeDropdownBg = (index) => {
    const h =
      document.getElementsByClassName("dropdown-menu")[`id-${index}`]
        .offsetHeight;
    dropdownBg.current.style.height = `${h}px`;
  };

  const closeNav = (e) => {
    new bootstrap.Collapse(document.getElementById("navbarText")).toggle();
  };

  // const ProfileImage = () => {
  //   const profileImagePath = user.profileImage
  //     ? user.profileImage
  //     : user.avatar;

  //   return (
  //     <img
  //       src={`${profileImagePath}`}
  //       alt=""
  //       style={{ borderRadius: "50%", width: "35px" }}
  //     />
  //   );
  // };

  return (
    <React.Fragment>
      <TBSModal title="Social Media Login">
        <div>
          <DisplayGoogleButton />
          <DisplayFacebookButton />
        </div>
      </TBSModal>

      {!_.isEmpty(user) ? (
        // {user.initialState === false ? (
        <nav
          data-bs-scroll="false"
          className={`navbar navbar-expand-lg ${navTextColor} ${navBgColor} sticky-top site-navbar`}
        >
          {/* <nav className="navbar navbar-expand-lg   sticky-top"> */}
          <div className="container-fluid">
            <Link className="navbar-brand" to="/">
              <img
                src={process.env.PUBLIC_URL + "/images/logo_concept1_png.png"}
                style={{ width: "130px" }}
                className="navLogo"
              />
            </Link>
            <span className="d-lg-none">
              <DisplayLogInButton classNames={"btn-sm btn-outline-light"} />
            </span>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarText"
              aria-controls="navbarText"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarText"
              style={{
                border: "0px solid red",
                padding: "0",
                margin: "0",
              }}
            >
              <div className="me-auto"></div>

              <ul
                className="navbar-nav navbar-nav-scroll me-auto mb-2 mb-lg-0"
                style={{
                  border: "0px solid red",
                  // position: "fixed",
                  // display: "block",
                }}
              >
                <div
                  className={`dropdown-bg ${
                    isShown() !== false
                      ? "dropdown-menu-show2"
                      : "dropdown-menu-hide2"
                  }`}
                  id="dropdown-bg"
                  ref={dropdownBg}
                ></div>

                {navItems.map(
                  (item) =>
                    ((item.authenticate && id) || !item.authenticate) &&
                    ((item.admin && user.roles?.includes("admin")) ||
                      !item.admin) &&
                    NavDropdown(item)
                )}
              </ul>
              <span className="navbar-text">
                {!id ? (
                  <div className="d-flex">
                    <div className="p-2 flex-grow-1">
                      <DisplayLogInButton
                        width="100%"
                        classNames={"btn-sm btn-outline-light"}
                      />
                    </div>
                    <div className="p-2 d-lg-none">
                      <button
                        className={`btn btn-dark`}
                        onClick={closeNav}
                        // className="navbar-toggler"
                        type="button"
                      >
                        <i
                          className="fa fa-times"
                          aria-hidden="true"
                          style={{ fontSize: "1.5rem" }}
                        ></i>
                      </button>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="btn-group d-none d-lg-block ">
                      <a
                        className="nav-link dropdown-toggle"
                        href="#"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <ProfileImage user={user} width="35px" />
                      </a>
                      <ul
                        className={`dropdown-menu dropdown-menu-lg-end ${navDropdownMenuColor}`}
                      >
                        <li>
                          <h6 className="dropdown-header">{username}</h6>
                        </li>

                        <li>
                          <span className="dropdown-item">
                            <Link className="nav-link" to="/dashboard">
                              Dashboard
                            </Link>
                          </span>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <span className="dropdown-item">
                            <DisplayLogOutButton
                              classNames={`btn-sm btn-warning`}
                            />
                          </span>
                        </li>
                      </ul>
                    </div>

                    <div className="container d-lg-none">
                      <div className="row mb-3">
                        <div className="col-2">
                          <img
                            src={avatar}
                            alt=""
                            style={{ borderRadius: "50%", width: "35px" }}
                          />
                        </div>
                        <div className="col-10 text-start ">
                          <span className="dropdown-item">
                            <Link className="nav-link" to="/dashboard">
                              {username}
                            </Link>
                          </span>
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col">
                          <div className="d-flex">
                            <div className="p-2 flex-grow-1">
                              <DisplayLogOutButton
                                width="100%"
                                classNames={`btn-sm btn-warning`}
                              />
                            </div>
                            <div className="p-2">
                              <button
                                className={`btn btn-dark`}
                                onClick={closeNav}
                                // className="navbar-toggler"
                                type="button"
                              >
                                <i
                                  className="fa fa-times"
                                  aria-hidden="true"
                                  style={{ fontSize: "1.5rem" }}
                                ></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </span>
            </div>
          </div>
        </nav>
      ) : (
        "Loading..."
      )}
    </React.Fragment>
  );
};

export default NavBar;
